import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLService } from 'src/app/URL.service';
 
 
import { AuthService } from '../../../services/auth/auth.service';
 

@Injectable({
  providedIn: 'root'
})

export class HttpRepoService {

  constructor(private URL_API:API_URLService ,private http: HttpClient,private auth:AuthService) { }
 data:any[] = [];
Add(data:any,url:string): Observable<any> {
    return this.http.post(this.URL_API.getUrl() + url, {
    
     data

    },this.auth.getHttpOption());
  }
  update(data:any,url:string): Observable<any> {
    return this.http.post(this.URL_API.getUrl() + url, {data
    },this.auth.getHttpOption());
  }
  getById(id: any,url:string): Observable<any> {

    return this.http.get(this.URL_API.getUrl() + url+`${id}`, this.auth.getHttpOption());
  }
  getAll(url:string): Observable<any> {

   return this.http.get(this.URL_API.getUrl() + url, this.auth.getHttpOption());
  }
  get(url:string) {
    const promise = this.http.get<[]>(this.URL_API.getUrl() + url, this.auth.getHttpOption()).toPromise();

    return promise.then(
      (response: any) => {
        // Success callback
        this.data = response;
      },
      (error: any) => {
        // Error callback
        console.log(error);
      }
    );
  }
}
