<div class="container">
  <button (click)="generatePdf(contentToConvert)" class="btn btn-primary" *ngIf="isPDF">PDF</button>
  <div class="form-row">



    <div class="col-md-2 col-sm-12">
      <label class="control-label">Ano Avaliação *</label>
      <ng-select [(ngModel)]="ano_avaliacoes_id" (change)=getCursos()>
        <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos.id" >{{anos.name}}</ng-option>
      </ng-select>




  </div>
  <div class="col-md-2 col-sm-12">
    <label class="control-label">Curso *</label>
    <ng-select [(ngModel)]="curso_id" (change)=getTurmas()>
      <ng-option *ngFor="let curso of cursos" [value]="curso.id" >{{curso.name}}</ng-option>
    </ng-select>

</div>

<div class="col-md-2 col-sm-12">
  <label class="control-label">Turma *</label>
  <ng-select [(ngModel)]="turma_id" (change)="getJSONEstudantes()">
    <ng-option *ngFor="let turma of turmas" [value]="turma.id" >{{turma.name}}</ng-option>
  </ng-select>

</div>
<div class="container-fluid">

  <hr>
<button (click)="getJSON()" class="btn btn-primary" title="Carregar">Carregar</button>

<hr> <input [(ngModel)]="namePDF" name="namePDF" type="text" class="form-control" placeholder="Informe o nome para o pdf">
</div>

</div>


</div>
<fieldset  #contentToConvert >
<div class="container body-year">
  <div class="PDF">
    <div class="header-pdf-year">
      <img src="./../../../../assets/images/ISPPM.jpg" alt="">
    </div>
    <div class="textheader">
      <h1 class="text-blue">Instituto Superior Politécnico Privado de Menongue</h1>
<h4 class="text-blue-white">Criado Pelo Decreto Presidencial nº 227/17 de 27 de Setembro</h4>
<hr>
<h2>SECRETARIA GERAL</h2>
<h4>RELATÓRIO ANUAL DE PAGAMENTOS DE PROPINAS - TURMA {{turma}}/ {{curso}}</h4>
    </div>
  </div>

  <table class="table table" style="font-size: 7;">
    <thead>
      <tr>
        <th width="10%">ID</th>
        <th width="10%">Nome</th>



        <th width="5%" *ngFor="let mes of getMeses">{{mes.name}}</th>
        <th width="10%">Total Pago</th>
        <th width="10%">Diferença</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pag of dataEstudantes index as i">
        <td >{{i+1}}</td>
        <td >{{pag.nome}}</td>



          <td *ngFor="let m of getMeses"> {{getPreco(m.name,pag.edu_estudante_id)}}</td>
          <td>{{getTotal(pag.edu_estudante_id)}}</td>
          <td>{{getDiferenca(pag.edu_estudante_id)}}</td>
      </tr>

    </tbody>
  </table>



  <p>TOTAL ESTUDANTES:{{quantidadeGeral/10}} PREVISÃO TOTAL DOS MESES A PAGAR:{{quantidadeGeral}}</p>
  <p><strong>FÓRMULA :</strong> TOTAL_ESTUDANTES <strong>X</strong> 10 MESES={{quantidadeGeral}} MESES</p>
    <h2> PREVISÃO DE PAGAMENTOS:{{formatNumber(totalPrevisto)}} AO</h2>

<table class="table table-bordered table-striped">
  <thead width="100%">
    <tr>
      <th width="50%">Curso</th>
    <th width="25%">Quantidade</th>
    <th width="25%">Total</th>
    <th width="25%">Diferença Quantidade</th>
    <th width="25%">Diferença Total</th>
    </tr>
  </thead>
  <tr  *ngFor="let res of resumoPorCurso">
    <td>{{res?.curso}}</td>
    <td>{{res?.quantidade}}</td>
    <td class="alert-primary">{{formatNumber(res?.total)}} AO</td>
    <td class="alert-danger">{{formatNumber(res?.quantidade -quantidadeGeral)}}</td>
    <td class="alert-danger">{{formatNumber(res?.total -totalPrevisto)}} AO</td>
  </tr>
</table>

<p>MENONGUE, __________ DE _____________ DE _______________________</p>
<p>PROCESSADO POR COMPUTADOR {{dataHoje|date}}</p>
  <strong>A SECRETARIA</strong><br>

_________________________________

</div>

</fieldset>
