
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">



                            <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                              <label class="control-label">Ano Avaliação *</label>


                                  <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                                  {{anos.name}}


                          </div>
                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>
<div class="container">
  <div class="rows">
    <div class="col-md-3">
     <i class="ti-id-badge "></i> <span> MISTC: </span>  <input type="checkbox" name="isChecked" [(ngModel)]="isChecked" (change)="isTable(isChecked?'A':'B')">
    </div>

  </div>
</div>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->








    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="5%">Ordem</th>
          <th width="5%">Cód. Cand.</th>
          <th width="10%">Fatura</th>



          <th width="5%">Doc. Ident.</th>
          <th width="30%">Nome</th>
          <th width="5%">Gênero</th>
          <th width="30%">Curso</th>
          <th width="10%">Sala</th>
          <th width="10%">Período</th>
          <th width="10%">Nível</th>
          <th width="10%">Data</th>
          <th width="10%">Acao</th>

        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let pag of dataCandidaturas index as i">
          <td >{{i+1}}</td>
          <td >{{pag?.codigo_acesso}}  </td>



          <td ><i class="fa fa-eye"></i></td>

          <td style="text-transform: uppercase;" >{{pag?.n_documento_identificacao}}</td>


          <td style="text-transform: uppercase;"> <a routerLink="details/{{pag.id}}">{{pag?.name}}</a></td>
          <td >{{pag?.genero}}</td>
          <td >{{pag?.curso}}</td>
          <td >{{pag?.sala}}</td>
          <td >{{pag?.periodo}}</td>
          <td >{{pag?._level}}</td>
          <td >{{pag?.created_at | date}}</td>
          <td></td>
          </tr>

      </tbody>
    </table>






    <div class="container">
      <hr>
      <div style="text-align: center;">
          <h2>RESUMO</h2>
          <p><strong>TOTAL:</strong>{{sum()}}</p>
      </div>
      <table class="table table-bordered table-striped">
        <thead width="100%">
          <tr>
            <th width="50%">Curso</th>
          <th width="25%">Ano</th>
          <th width="25%">Total</th>
          </tr>
        </thead>
        <tr  *ngFor="let res of resumoPorCurso">
          <td>{{res?.curso}}</td>
          <td>{{res?.ano}}</td>
          <td>{{res?.total }} {{gettotalElem(res?.total)}} </td>
        </tr>
      </table>
    </div>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
