

<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset>
                    <div class="container">
                      <ul>
                        <li>Preencha todos os campos,de seguida clica no botão consultar;</li>
                        <li>- Depois atualize clicando no botão consultar</li>
                      </ul>
                    </div>
                    <hr>
                    <div class="form-row">



                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Ano Avaliação *</label>
                          <ng-select [(ngModel)]="ano_avaliacao_id" (change)="getDistribucaoTurmas()">
                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                          </ng-select>
                      </div>
                      <div class="col-md-2 col-sm-12">
                        <label class="control-label">Nível académico *</label>
                        <ng-select [(ngModel)]="ano_nivel_id" (change)="getDistribucaoTurmas()">
                          <ng-option *ngFor="let anos of niveis" [value]="anos?.id" >{{anos?.name}}</ng-option>
                        </ng-select>
                    </div>

                      <div class="col-md-2 col-sm-12">
                        <label class="control-label">Cursos*</label>
                        <ng-select [(ngModel)]="edu_curso_id" (change)="getTurmas()">
                          <ng-option *ngFor="let c of edu_cursos" [value]="c?.id" >{{c?.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <label class="control-label">Turmas*</label>
                      <ng-select [(ngModel)]="edu_turma_id" (change)="getDisciplinasPorturma()">
                        <ng-option *ngFor="let t of distribuicaoturmas" [value]="t?.id" >{{t?.name}}</ng-option>
                      </ng-select>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <label class="control-label">Disciplina*</label>
                    <ng-select [(ngModel)]="edu_disciplina_id" (change)="notasPorTurm($event)">
                      <ng-option *ngFor="let t of disciplinas" [value]="t?.id" >{{t?.name}}</ng-option>
                    </ng-select>
                </div>


                              <hr>
                     <fieldset class="">
                      <button class="btn btn-primary btn-import mr-3 " title="Carregar" (click)="getNotas()">Consultar</button>
                      <button class="btn btn-primary btn-import mr-3" title="Carregar" (click)="pautaPDF(pauta)">PDF</button>


                     </fieldset>





                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid" #pauta>
  <div class="row">
    <div class="col-12 col-lg-12">

  <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table table-bordered table-striped table-hover table-responsive">
    <thead>
      <th with="5%">ID</th>
      <th with="30%">NOME COMPLETO</th>
      <th with="5%">1ª PP</th>
      <th with="5%">2ª PP</th>
      <th with="5%">1ª MAC</th>
      <th with="5%">MÉDIA GERAL</th>
      <th with="5%">EXAME</th>
     <th with="5%">RECURSO</th>
     <th with="8%">EXAME ESPECIAL</th>
     <th with="8%">RESULTADO FINAL</th>
     <th with="8%">SITUAÇÃO ACADÉMICA</th>

    </thead>
    <tbody>
      <tr *ngFor="let n of lsitaEstudantes">
        <td>{{n?.id}}</td>
      <td>{{n?.nome}}  </td>
      <td><input type="number" (blur)="lancar_nota($event,'pp1',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.pp1}}" *ngIf="n?.isrow=='pp1'+n?.id" >
        <span *ngIf="n?.isrow!='pp1'+n?.id" (click)="edit(n,'pp1')" style="cursor: pointer;">{{getNota(n.id)?.pp1 || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'pp2',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.pp2}}" *ngIf="n?.isrow=='pp2'+n?.id" >
        <span *ngIf="n?.isrow!='pp2'+n?.id" (click)="edit(n,'pp2')" style="cursor: pointer;">{{getNota(n.id)?.pp2 || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'mac',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.mac}}" *ngIf="n?.isrow=='mac'+n?.id" >
        <span *ngIf="n?.isrow!='mac'+n?.id" (click)="edit(n,'mac')" style="cursor: pointer;">{{getNota(n.id)?.mac || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'mg',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{calcularMGA(getNota(n.id))}}" *ngIf="n?.isrow=='mg'+n?.id" >
        <span *ngIf="n?.isrow!='mga'+n?.id" (click)="edit(n,'mg')" style="cursor: pointer;">{{  calcularMGA(getNota(n.id))|| 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'exame',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.exame}}" *ngIf="n?.isrow=='exame'+n?.id" >
        <span *ngIf="n?.isrow!='exame'+n?.id" (click)="edit(n,'exame')" style="cursor: pointer;">{{getNota(n.id)?.exame || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'recurso',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.recurso}}" *ngIf="n?.isrow=='recurso'+n?.id" >
        <span *ngIf="n?.isrow!='recurso'+n?.id" (click)="edit(n,'recurso')" style="cursor: pointer;">{{getNota(n.id)?.recurso || 0}}</span>
      </td>

      <td><input type="number" (blur)="lancar_nota($event,'exame_especial',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.exame_especial}}" *ngIf="n?.isrow=='exame_especial'+n?.id" >
        <span *ngIf="n?.isrow!='exame_especial'+n?.id" (click)="edit(n,'exame_especial')" style="cursor: pointer;">{{getNota(n.id)?.exame_especial || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'resultado_final',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.resultado_final}}" *ngIf="n?.isrow=='resultado_final'+n?.id" >
        <span *ngIf="n?.isrow!='resultado_final'+n?.id" (click)="edit(n,'resultado_final')" style="cursor: pointer;">{{getNota(n.id)?.resultado_final || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'situacao_academica',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.situacao_academica}}" *ngIf="n?.isrow=='situacao_academica'+n?.id" >
        <span *ngIf="n?.isrow!='situacao_academica'+n?.id" (click)="edit(n,'situacao_academica')" style="cursor: pointer;">{{getNota(n.id)?.situacao_academica || 0}}</span>
      </td>
      </tr>

    </tbody>
  </table>
</div>
</div>
</div>
