import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';

@Component({
  selector: 'app-situacaofinanceira',
  templateUrl: './situacaofinanceira.component.html',
  styleUrls: ['./situacaofinanceira.component.css']
})
export class SituacaofinanceiraComponent implements OnInit {
  docItems:any
  formFilter:FormGroup|any
  turma_id=''
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  notasEstudante:any
  ano_avaliacoes:any
  data_ini:Date=new Date()
  data_fim:Date=new Date()
  disciplinas:any
  preDisciplinas:any
  sistemaAvalicao:any
  meses:any
  selectedCar: number=0
  turmas:any
  edu_turma_id:number=0
  cursos:any
  anos:any
  ano_id:number=0
  edu_curso_id:number=0
  edu_turmas: any
  pagamentosBrutos:any
  edu_estudante_id=0
  estudanteNome=''
  estudanteCurso=''
  estudanteTurma=''
  Pre_ano_avaliacoes: any;
  comercialDoc: any;
  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private fb:FormBuilder,
    private config: NgSelectConfig) {
      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      // set the bindValue to global config when you use the same
      // bindValue in most of the place.
      // You can also override bindValue for the specified template
      // by defining `bindValue` as property
      // Eg : <ng-select bindValue="some-new-value"></ng-select>
      this.config.bindValue = 'value';
    }
alertMe(e:any){

  alert(this.selectedCar)
}
  ngOnInit(): void {
    this.meses=this.mesesService.meses
    this.getTurmas()
    this.getSituacaofinanceira()
    this.formFilter=this.fb.group({
      idturma:[null]
    })

   this.getAnoAvalicao()
    this.setTitle()
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
  }

  getNota(edu_sistema_avaliacoes_curso_id:string,edu_disciplina_id:string) {

try{
//console.log(edu_disciplina_id+" " +edu_sistema_avaliacoes_curso_id)
//console.log(this.notasEstudante)
return this.notasEstudante.find((n: { edu_sistema_avaliacoes_curso_id: string; edu_disciplina_id: string; })=>n.edu_sistema_avaliacoes_curso_id==edu_sistema_avaliacoes_curso_id&&n.edu_disciplina_id==edu_disciplina_id).nota

}
catch(ex){

}
  }
   getsistemaAvalicao(){

    this.repo.getAll('/sistemaavaliacao/'+this.edu_curso_id).subscribe((res)=>{

       this.sistemaAvalicao=res.sistemaavaliacao
  //console.log( this.ano_avaliacoes)

    })

   }getAnoAvalicao(){

    this.repo.getAll('/anosavaliacoes').subscribe((res)=>{

      //console.log( '------------')
      this.Pre_ano_avaliacoes=res.ano_avaliacoes


    })

   }
   async getDisciplina(){

    this.repo.getAll('/disciplinas/'+this.edu_curso_id+"/"+this.ano_id).subscribe((res)=>{

       this.preDisciplinas=res.disciplinas
  //console.log( this.preDisciplinas)


    })
   }
   async getTurmas(){

    this.repo.getAll('/turmasestudante').subscribe((res)=>{

       this.turmas=res.turmasestudante
       this.cursos = [...new Map(this.turmas.map((item: { curso: any; }) => [item.curso, item])).values()]
       this.anos = [...new Map(this.turmas.map((item: { ano: any; }) => [item.ano, item])).values()]
  //console.log(  this.turmas)


    })
   }
   dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  setTitle(){
    this.titleService.setTitle("Minha situaçao académica - "+Date())
  }

 getTurmasFilter(){
  try{
   let data= this.turmas.find((v: { edu_curso_id: string;edu_ano_id:string})=>v.edu_curso_id==this.edu_curso_id.toString()&&v.edu_ano_id==this.ano_id.toString())
     //console.log( data)
     this.edu_turmas=data
  }
  catch(ex){

  }
  }



getTotal(id_estudadente:Number,ano_avaliacoes_id:Number):string{
  var preco="0"


  try{
    var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
      return res.mensalidade!=null &&res.edu_estudante_id==id_estudadente&&res.ano==ano_avaliacoes_id;
     });

    preco=pagamento.length
    //console.log(pagamento.length)

  }
  catch(ex){
    //console.log(ex)
  }
     return preco

}
getDiferenca(id_estudadente:Number,ano_avaliacoes_id:Number):Number{

  var preco=0


  try{
    var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
      return res.mensalidade!=null &&res.edu_estudante_id==id_estudadente&&res.ano==ano_avaliacoes_id;
     });
     var [x,t]=[10,parseInt(pagamento.length)];

    preco=(t-x)
    //console.log("DIF"+preco.toString())

  }
  catch(ex){
    //console.log(ex)
  }
     return preco
}

formatNumber(preco:any){
  try{
    return preco.toLocaleString()
  }
  catch(ex){

  }
  }

  getPreco(mes:string,id_estudadente:Number,ano_avaliacoes_id:Number){
    var preco="0"


  try{

    var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
      return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente&&res.ano==ano_avaliacoes_id;
     });
    if(pagamento[0].preco)
    {
    preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
    }
  }
  catch(ex){

  }
     return parseFloat(preco).toLocaleString()
  }


 async getSituacaofinanceira(){
  try{
    this.repo.getAll('/transacoes-estudantes/'+this.edu_turma_id).subscribe((res)=>{

    this.comercialDoc=res.data
      this.pagamentosBrutos=res.situacaofinanceira
      this.edu_estudante_id=parseInt(res.edu_estudante_id)
      this.estudanteNome=res.estudante
      this.estudanteCurso=res.curso
      this.estudanteTurma=res.turma
 this.ano_avaliacoes=this.Pre_ano_avaliacoes.filter((a: { id: number; })=>a.id==res.ano_avaliacao_id)

      //console.log('-----------------------------------')
//console.log('-----------------------------------')
      // const item = [...new Map(this.data.map((item: { Estudante: any; }) => [item.Estudante, item])).values()]
       //this.Pagamentos=item
       ////console.log(this.data)

if(res.situacaofinanceira){
 this.dataTableInit();
}

         //this.dtTrigger.next();

         //this.getresumoPorCurso()
    });
  }
  catch(ex){
    //console.log(ex)
  }
   }

 async getSituacaofinanceiraItens(id:number){
  try{
    this.repo.getAll('/transacoes-estudantes/itens/'+id).subscribe((res)=>{

    this.docItems=res.data





         //this.dtTrigger.next();
         //this.dataTableInit();
         //this.getresumoPorCurso()
    });
  }
  catch(ex){
    //console.log(ex)
  }
   }

}
