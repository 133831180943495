import { Injectable } from '@angular/core';
import jsPDF, * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable, { Row } from 'jspdf-autotable';
@Injectable({
  providedIn: 'root'
})
export class ServicePDFjsPDFService {

  constructor() { }

generatePdf(data:any,namePDF:string) {
  html2canvas(data, { allowTaint: true }).then(canvas => {
   let HTML_Width = canvas.width;
   let HTML_Height = canvas.height;
   let top_left_margin = 15;
   let PDF_Width = HTML_Width + (top_left_margin * 2);
   let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
   let canvas_image_width = HTML_Width;
   let canvas_image_height = HTML_Height;
   let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
   canvas.getContext('2d');
   let imgData = canvas.toDataURL("image/jpeg", 1.0);
   let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
   pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height,'FAST');
   for (let i = 1; i <= totalPDFPages; i++) {
     pdf.addPage([PDF_Width, PDF_Height], 'p');
     pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height,'FAST');
   }
    pdf.save(namePDF+new Date().toISOString()+'.pdf');
 });
}
 gerarPauta(image:any,data:any,disciplina:string,curso:string,ano_letivo:string,turma:string,periodo:string){

const docente=data?.user?.name
const sign=data?.user?.sign
const unidata = (data?.notas[0]?.unidade === 1) ? 'I' : 'II';
console.log("NOTAS:",data?.notas[0])
  const header = ["ORDEM", "NOME COMPLETO", "1ªPP", "2ªPP", "MAC", "MGA", "EXAME","MÉDIA FINAL", "RESULTADO FINAL", "RECURSO","NOTA FINAL", "EXAME ESPECIAL", "SITUAÇÃO ACADEMICA"];
  const result = data?.lista.map((estudante: { edu_estudante_id: any; }) => {
      const nota = data?.notas.find((nota: { edu_estudante_id: any; }) => nota.edu_estudante_id === estudante.edu_estudante_id);

      return {
        ...estudante,
        ...(nota || {})
      };
    });
  // Criar um novo documento PDF
  const doc = new jsPDF();
  const tabel_start=100
  const top=10
  const height=60
  const width=180
  const position=10
  doc.addImage(image, 'PNG',position , top, width, height); // Ajuste as coordenadas e o tamanho conforme necessário

  const top_titulos=80
  const left_titulo=38
  //Ano lectivo
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("ANO ACADÉMICO:"+ano_letivo.toUpperCase(), left_titulo, top_titulos, { align: 'center' });
  //Curso
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("CURSO:"+curso.toUpperCase(), left_titulo-9, top_titulos+10, { align: 'center' });



  //DISCIPLINA
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("TIPO DE CADEIRA:"+data?.dispensa.toUpperCase(), left_titulo+60, top_titulos, { align: 'center' });
  //Curso
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("DISCIPLINA:"+disciplina.toUpperCase() , left_titulo+60, top_titulos+10 , { align: 'center' });



  //DISCIPLINA
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("PERÍODO:"+periodo.toUpperCase()+`/${unidata}º SEMESTRE`, left_titulo+110, top_titulos, { align: 'center' });
  //Curso
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text("TURMA:"+turma.toUpperCase(), left_titulo+120, top_titulos+10 , { align: 'center' });
  // Definir a tabela usando o autotable
  // Definir a tabela usando o autotable
  const DadosFinais = result.map((r: { nome: any; pp1: any; pp2: any; mac: any; exame: any; recurso: any; }, index: number) => {
    return {
      ordem: index + 1,
      nome: r.nome,
      pp1:r.pp1||0,
      pp2:r.pp2||0,
      mac:r.mac||0,
      mg:this.calcularMGA(r),
      exame:r.exame||0,
      mf:this.calcularMF(r),
      resultado_final:this.calcularRFINAL(r),
      recurso:r.recurso ||0,
      nf:this.calcularNF(r),
      exame_especial:'-',
      situacao_academica:this.Obs(r)
    };
  }) //.filter(obj => obj.index === 9);

  let Settings= (data: { settings: { margin: { left: number;top: number;bottom: number; }; }; }) => {

    // Header
    doc.setFontSize(20);
    doc.setTextColor(40);
    //doc.text("Report", data.settings.margin.left, 22);

    // Footer
    var str = "Page " +  (doc as any).internal.getNumberOfPages()+", gerado em:"+new Date().toISOString()+" - pelo computador";

    doc.setFontSize(5);

    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    var pageSize = doc.internal.pageSize;
    var pageHeight = pageSize.height
      ? pageSize.height
      : pageSize.getHeight();

      doc.setFont("Arial", "normal");
      doc.text("      O CHEFE DE DEPARTAMENTO", data.settings.margin.left, pageHeight - 12);
      doc.text("_____________________________________", data.settings.margin.left, pageHeight - 10);
    doc.text("1ª PP, 2ª PP = Prova Parcelar =>Formula de Avaliação:", data.settings.margin.left, pageHeight - 7);
    doc.text("(1ªPP+2ªPP+MAC)/3 Resultado=MGA  - MAC = Média de Avaliação Contínua", data.settings.margin.left+80, pageHeight - 9);

    doc.text("EXAME PARA DISCIPLINA QUE DISPENSA: (MGA*60%+EXAME*40%)= MÉDIA FINAL", data.settings.margin.left, pageHeight - 5);
    doc.text("EXAME PARA DISCIPLINA QUE NÃO DISPENSA:(MGA*40%+EXAME*60%)= MÉDIA FINAL - REURSO=100%", data.settings.margin.left+80, pageHeight - 6);
    doc.text(`Assinatura do Docente(${docente}):`, data.settings.margin.left+80, pageHeight - 2);
    if(sign!=''){
    doc.addImage(sign, 'PNG',150 , 292, 20, 4); // Ajuste as coordenadas e o tamanho conforme necessário
}
    doc.text(str, data.settings.margin.left, pageHeight - 2);

        }
  autoTable(doc,{
    head: [header],
    body: DadosFinais.map((item: { [s: string]: unknown; } | ArrayLike<unknown>) => Object.values(item)),
    startY: tabel_start,
    didDrawCell:Settings,

    styles: {
      fontSize: 7, // Defina o tamanho da fonte desejado

    }
  });

  // Salvar o arquivo PDF
  doc.save(docente+'-pauta'+'.pdf');

  }

  calcularMGA(n:any){
    try{
const mac=(n?.pp1+n?.pp2+n?.mac)/3 || 0


if(Math.round(mac/5)*5 <mac){


  return parseFloat(mac.toFixed(1))
  }
  else{

  return Math.ceil(mac)
  }
    }
    catch(ex:any){
      return 0
    }
  }

  calcularRFINAL(n:any){
    try{
const mf=parseFloat(this.calcularMF(n).toString())
const d=n?.dispensa
 if(d==1 && mf>=14){
  return "DISPENSADO"
 }
 else{
  if( this.calcularMGA(n)<=14 && d!=1 ){
    return "EXAME"
  }
  else{
    return "EXAME"
  }
}







    }
    catch(ex:any){
      return '-'
    }
  }
   calcularMF(n:any){
    try{
      const mga=  this.calcularMGA(n) ||0
      let mf=0
      if(n.dispensa==1){
      if(mga>=14){
        mf= (mga*0.6)+(mga*0.4) || 0

      }
      else{

        mf= (mga*0.6)+(n.exame*0.4) || 0
      }
      }
      else{
      mf=  (mga*0.4)+(n.exame*0.60) || 0
      }

if(Math.round(mf/5)*5 <mf){


    return parseFloat( mf.toFixed(1)) || 0
}
else{
    const f=Math.ceil(mf)
    return f || 0
}

    }
    catch(ex){

      return 0
    }
  }
   calcularNF(n:any) {
    let valor = 0;
    const mf = this.calcularMF(n);
    const mg = this.calcularMGA(n);

    if (mg >= 14 && n.dispensa == 1) {
      valor = mf;
    } else {
      if (mf < 10 && !n.recurso) {
        valor = mf;
      } else if (mf < 10 && n.recurso) {
        valor = n.recurso;
      } else if (mf < 10 && n.recurso < 10 && n.exame_especial) {
        valor = n.exame_especial;
      } else {
        valor = mf;
      }

      // Adicione uma verificação adicional para usar o exame_especial quando disponível
      if (n.exame_especial && n.exame_especial >= 10) {
        valor = n.exame_especial;
      }
    }

    return valor;
  }

   calcularNF1(n:any) {
    let valor = 0;
    const mf = this.calcularMF(n);
    const mg = this.calcularMGA(n);

    if (mg >= 14 && n.dispensa == 1) {
      valor = mf;
    } else {
      if (mf < 10 && !n.recurso) {
        valor = mf;
      } else if (mf < 10 && n.recurso) {
        valor = n.recurso;
      } else if (mf < 10 && n.recurso < 10 && n.exame_especial) {
        valor = n.exame_especial;
      } else {
        valor = mf;
      }

      // Se o estudante reprovou em todos os exames e ainda não tem valor, use o exame_especial
      if (mf < 10 && n.recurso < 10 && !valor && n.exame_especial) {
        valor = n.exame_especial;
      }
    }

    return valor;
  }

Obs(n:any){
  try{
    let valor='--'
    if(!n.pp1  && !n.pp2 && !n.mac){
      valor='REPROVADO/A'
  }
 else if(n.pp1 ==0 && n.pp2==0 && n.mac==0){

    valor='REPROVADO/A'
}
  else{


    const nf= this.calcularNF(n)

    if(nf>=10){
        valor='APROVADO/A'
    }
   else if(n.exame==0 || !n.exame){
      valor='RECURSO'
  }
    else{

      if(nf<10 &&!n.recurso &&!n.exame){
        valor='---'
      }
      else{
        if(nf<10 &&!n.recurso &&n.exame){
          valor='RECURSO'
        }
        else{
          valor='REPROVADO/A'
        }
      }
    }
    //end
  }
    return valor
  }
  catch{
return 'REPROVADO/A'
  }

}

}
