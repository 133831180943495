import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docentes-ver-turmas',
  templateUrl: './docentes-ver-turmas.component.html',
  styleUrls: ['./docentes-ver-turmas.component.css']
})
export class DocentesVerTurmasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
