import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';

@Component({
  selector: 'app-mescobranca',
  templateUrl: './mescobranca.component.html',
  styleUrls: ['./mescobranca.component.css']
})
export class MescobrancaComponent implements OnInit {

  modalComponent:any
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  iconLink=''


  dataMes:any
  constructor( private repo:HttpRepoService,private toastr: ToastrService) { }
async loadMes(){
  this.repo.getAll('/meses').subscribe((res)=>{
    this.dataMes=res.meses;
    console.log(  this.dataMes)
    this.dataTableInit()
  })


}
  ngOnInit(): void {

this.loadMes()
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 10, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  update(data:any){


    try{


      this.repo.Add(data,'/meseupdate').subscribe((res)=>{
        if(res.result==1){
        this.toastr.success("Atualizado!","SUCCESS")
        window.location.reload()
      }
        else
        this.toastr.error("Não atualizado!","ERROR")

        console.log(res)


     })

    }
    catch(ex){
      console.log(ex)
    }
  }
  getValue(ev:any,arg:Number=0,id:Number=0){
    let data
   try{

        if(parseInt(ev.target.innerText)==0||parseInt(ev.target.innerText)==1)
        this.update({status:parseInt(ev.target.innerText),uid:id})
        else
        this.toastr.error("São permitidos apenas valores de 0 ou 1!","ERROR")

   }
   catch(ex){
    this.toastr.error("Erro","ERROR")
   }
console.log(ev.target.innerText)
  }

}
