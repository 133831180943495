<div *ngIf="!close">
<p><strong>Olá! Dispense um tempinho para assinar a pauta! Escreva na caixa abaixo.</strong></p>
  <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">

  </signature-pad>

  </div>

  <div>

  <button class="btn clear" (click)="clearSignature()">Clear</button>
  <button class="btn save"  (click)="save()">Save</button>

  </div>

   <img src="{{urlImage}}" alt="">
