import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeclaracaoService } from 'src/app/services/base/doc/declaracao.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';
import * as printJS from 'print-js';
export default printJS;
@Component({
  selector: 'app-estudantes',
  templateUrl: './estudantes.component.html',
  styleUrls: ['./estudantes.component.css']
})
export class EstudantesComponent implements OnInit {
  estudante:any
  resultadoCurso: any
  resultadoCursoTemp: any;
  pay: any;
  history_sales: any;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private pdfDec:DeclaracaoService) {

    }
    instituicao:any
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  resumoPorCurso:any
  ano_avaliacoes:any
  ano_avaliacoes_id:Number=0
  studentsResumoTurmas:any
  totalturmas=0
  totalEstudantes=0
  totalCursos=0

  //dtElement: DataTableDirective;

  meses:any
  anoSelecionado=0
    getMeses:any
 Pagamentos:any
 data:any
 dataCandidaturas:any
 PredadosdataCandidaturas:any
pagamentosBrutos:any
Av={
  id:null,
  name:null
}
    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
     this. getAnos()
     this.getInstituicao()
    //Loading child components
    this.loadingChildComponents();
    this.loadingData();
   this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10000,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("List de Estudantes - "+this.anoSelecionado)
    }
    loadingChildComponents(){
      //this.modalComponent=UserAddComponent
    }
getPreco(mes:string,id_estudadente:string){
  var preco="0"


try{
  var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
    return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente;
   });
  if(pagamento[0].preco)
  {
  preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
  }
}
catch(ex){

}
   return preco
}


 async getAnos(){

  this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
     this.data=res;

     this.ano_avaliacoes=this.data.ano_avaliacoes
//console.log( this.ano_avaliacoes)


  })
 }
 async getExtractHistory(edu_estudante_id:Number){

  this.repo.getAll('/comercial/sales/'+edu_estudante_id).subscribe((res)=>{
     this.history_sales=res.data;

     this.ano_avaliacoes=this.data.ano_avaliacoes
//console.log( this.ano_avaliacoes)


  })
 }
 getData(data:any){
  this.estudante=data
 }
 async getInstituicao(){

  this.repo.getAll('/instituicao').subscribe((res)=>{


     this.instituicao=res.infInstituicao




  })
 }

queeyPag(edu_matricula_id:Number){
  try{
    let result ='Não'
  const p=this.dataCandidaturas.find((p: { edu_matricula_id: Number; })=>p.edu_matricula_id==edu_matricula_id)
  if(p?.edu_matricula_id>0){
    result='Sim'
  }
  return result
  }catch{
  return 'Não'
  }
  }
  queeyPagData(edu_matricula_id:Number){
    try{

      let result ='F'

    const p=this.pay.find((p: { edu_matricula_id: Number; })=>p.edu_matricula_id==edu_matricula_id)

    if(p?.edu_matricula_id>0){
      result=p?.created_at

    }
    return result
    }catch(ex:any){

    return 'F'
    }
    }
 async getJSONEstudantes(e:any,anos:number){
  this.anoSelecionado=anos
  this.ngOnDestroy()
//console.log(e.target.value)
this.ano_avaliacoes_id=parseInt(e.target.value)
  this.repo.getAll('/estudantesgeral/'+this.ano_avaliacoes_id).subscribe((res)=>{
    this.dataCandidaturas=res.estudantes;
    //console.log("gear:", this.dataCandidaturas)

     this.pay=res.pay;
     this.resumoPorCurso=res.studentsResumo
     this.studentsResumoTurmas=res.studentsResumoTurmas
     this.totalturmas= this.studentsResumoTurmas.length
     this.totalEstudantes= this.dataCandidaturas.length
     this.totalCursos= this.resumoPorCurso.length

     const data: Registro[] =this.dataCandidaturas
     const totalPorCurso: { [curso: string]: number } = {};

data.forEach(({ curso }) => {
  if (totalPorCurso[curso]) {
    totalPorCurso[curso]++;
  } else {
    totalPorCurso[curso] = 1;
  }
});

const resultado = Object.entries(totalPorCurso).map(([curso, total]) => ({
  curso,
  total,
}));

//console.log(resultado);
this.resultadoCurso=resultado





     this.dataTableInit()

  })
  this.setTitle()
 }
 loadingData(){
  this.getMeses=this.meses

  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }

 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}

baixarFichaMatricula(matricula:any) {

  let blobdata = this.pdfDec.criarDeclaracaoFrequencia(matricula, []);

  this.pdfDec.savePdfFile(blobdata, "ficha_matricula" + "_"+matricula?.EduEstudante?.BasePessoa?.name);
}

//this.blobdata = this._fichaMatriculaService.criarFichaMatricula(matricula, this.instituicao);
imprimirDocumentoWeb() {
  let blobdata = this.pdfDec.criarDeclaracaoFrequencia([],this.instituicao);
  const url = window.URL.createObjectURL(blobdata);
  printJS({ printable: url, type: 'pdf', })
}

}

export interface Registro {
  meses: { [mes: string]: number; };
  edu_estudante_id: number;
  edu_turma_id: number;
  base_pessoa_id: number;
  nome: string;
  turma: string;
  id_turma: number;
  periodo: string;
  anoavaliacao: string;
  curso: string;
  nivel:string;
  created: any;
}
