
  <section *ngIf="isLoggin">


  <app-header></app-header>
	<!--Main container start -->
	<main class="ttr-wrapper">
		<div class="container-fluid">
			<div class="db-breadcrumb">
				<h4 class="breadcrumb-title">Dashboard</h4>
				<ul class="db-breadcrumb-list">
					<li><a href="#"><i class="fa fa-home"></i>Home</a></li>
					<li>{{sectionTitle}}</li>
				</ul>
			</div>

      <router-outlet></router-outlet>







    </div>
	</main>

      <div class="ttr-overlay"></div>


      <app-float-buttom></app-float-buttom>

  </section>
  <router-outlet></router-outlet>
