<div class="account-form">

  <div class="account-form-inner">
    <div class="account-container">
      <div class="error-page">
        <h3>Ooopps :(</h3>
        <h2 class="error-title">404</h2>
        <h5>Um erro sucedeu no processamento do seu pedido.</h5>
        <p>Esta página não está acessível, talvez seja por questóes de permissões.</p>
        <div class="">
          <a href="index.html" class="btn m-r5">Preview</a>
          <a href="index.html" class="btn outline black">Back To Home</a>
        </div>
      </div>
    </div>
  </div>
</div>

