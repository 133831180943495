import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

@Component({
  selector: 'app-aprovacao-pautas',
  templateUrl: './aprovacao-pautas.component.html',
  styleUrls: ['./aprovacao-pautas.component.css']
})
export class AprovacaoPautasComponent implements OnInit {

   ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
importPauta=false
isPut=5
aprovado=2
pedido=''
sistemaAvalicao=[
  {id:1,code:'PP1',name:"1ªPP"},
{id:2,code:'PP2',name:"2ªPP"},
{id:9,code:'MAC',name:"MAC"},
{id:8,code:'MGA',name:"MGA"},
{id:3,code:'EXAME',name:"EXAME"},
{id:4,code:'MEDIAFINAL',name:"MEDIA FINAL"},

{id:7,code:'RESULTADOFINAL',name:"RESULTADO FINAL"},
{id:5,code:'RECURSO',name:"RECURSO"},
{id:6,code:'EXAMEESPECIAL',name:"EXAME ESPECIAL"},
{id:10,code:'SITUACAOACADEMICA',name:"SITUACAO ACADEMICA"}
]
sac=[
  {"id":"2","edu_sistema_avaliacao_id":"2","edu_curso_id":"4","sigla":"2ªPP"},
  {"id":"3","edu_sistema_avaliacao_id":"3","edu_curso_id":"4","sigla":"EXAME"},
  {"id":"4","edu_sistema_avaliacao_id":"4","edu_curso_id":"4","sigla":"MÉDIAFINAL"},
  {"id":"5","edu_sistema_avaliacao_id":"5","edu_curso_id":"4","sigla":"RECURSO"},
  {"id":"6","edu_sistema_avaliacao_id":"6","edu_curso_id":"4","sigla":"EE"},
  {"id":"7","edu_sistema_avaliacao_id":"7","edu_curso_id":"4","sigla":"CF"},
  {"id":"8","edu_sistema_avaliacao_id":"1","edu_curso_id":"4","sigla":"1ªPP"},
  {"id":"9","edu_sistema_avaliacao_id":"1","edu_curso_id":"2","sigla":"1ªPP"},
  {"id":"10","edu_sistema_avaliacao_id":"1","edu_curso_id":"3","sigla":"1ªPP"},
  {"id":"11","edu_sistema_avaliacao_id":"1","edu_curso_id":"5","sigla":"1ªPP"},
  {"id":"12","edu_sistema_avaliacao_id":"2","edu_curso_id":"1","sigla":"2ªPP"},
  {"id":"13","edu_sistema_avaliacao_id":"2","edu_curso_id":"2","sigla":"2ªPP"},
  {"id":"14","edu_sistema_avaliacao_id":"2","edu_curso_id":"5","sigla":"2ªPP"},
  {"id":"15","edu_sistema_avaliacao_id":"2","edu_curso_id":"3","sigla":"2ªPP"},
  {"id":"16","edu_sistema_avaliacao_id":"3","edu_curso_id":"1","sigla":"EXAME"},
  {"id":"17","edu_sistema_avaliacao_id":"3","edu_curso_id":"2","sigla":"EXAME"},
  {"id":"18","edu_sistema_avaliacao_id":"3","edu_curso_id":"3","sigla":"EXAME"},
  {"id":"19","edu_sistema_avaliacao_id":"3","edu_curso_id":"5","sigla":"EXAME"},
  {"id":"20","edu_sistema_avaliacao_id":"5","edu_curso_id":"1","sigla":"RECURSO"},
  {"id":"21","edu_sistema_avaliacao_id":"5","edu_curso_id":"2","sigla":"RECURSO"},
  {"id":"22","edu_sistema_avaliacao_id":"5","edu_curso_id":"5","sigla":"RECURSO"},
  {"id":"23","edu_sistema_avaliacao_id":"5","edu_curso_id":"3","sigla":"RECURSO"},
  {"id":"24","edu_sistema_avaliacao_id":"4","edu_curso_id":"1","sigla":"MÉDIAFINAL"},
  {"id":"25","edu_sistema_avaliacao_id":"4","edu_curso_id":"2","sigla":"MÉDIAFINAL"},
  {"id":"26","edu_sistema_avaliacao_id":"4","edu_curso_id":"3","sigla":"MÉDIAFINAL"},
  {"id":"27","edu_sistema_avaliacao_id":"4","edu_curso_id":"5","sigla":"MÉDIAFINAL"},
  {"id":"28","edu_sistema_avaliacao_id":"6","edu_curso_id":"1","sigla":"EP"},
  {"id":"29","edu_sistema_avaliacao_id":"6","edu_curso_id":"3","sigla":"EP"},
  {"id":"30","edu_sistema_avaliacao_id":"6","edu_curso_id":"5","sigla":"EP"},
  {"id":"31","edu_sistema_avaliacao_id":"6","edu_curso_id":"2","sigla":"EP"},
  {"id":"32","edu_sistema_avaliacao_id":"7","edu_curso_id":"1","sigla":"CF"},
  {"id":"33","edu_sistema_avaliacao_id":"7","edu_curso_id":"2","sigla":"CF"},
  {"id":"34","edu_sistema_avaliacao_id":"7","edu_curso_id":"3","sigla":"CF"},
  {"id":"37","edu_sistema_avaliacao_id":"7","edu_curso_id":"5","sigla":"CF"},
  {"id":"38","edu_sistema_avaliacao_id":"1","edu_curso_id":"1","sigla":"1ªPP"},
  {"id":"39","edu_sistema_avaliacao_id":"9","edu_curso_id":"1","sigla":"MCA"},
  {"id":"40","edu_sistema_avaliacao_id":"9","edu_curso_id":"2","sigla":"MCA"},
  {"id":"41","edu_sistema_avaliacao_id":"9","edu_curso_id":"3","sigla":"MCA"},
  {"id":"42","edu_sistema_avaliacao_id":"9","edu_curso_id":"4","sigla":"MAC"},
  {"id":"43","edu_sistema_avaliacao_id":"9","edu_curso_id":"5","sigla":"MAC"},
  {"id":"44","edu_sistema_avaliacao_id":"8","edu_curso_id":"1","sigla":"MGA"},
  {"id":"45","edu_sistema_avaliacao_id":"8","edu_curso_id":"3","sigla":"MGA"},
  {"id":"46","edu_sistema_avaliacao_id":"8","edu_curso_id":"2","sigla":"MGA"},
  {"id":"47","edu_sistema_avaliacao_id":"8","edu_curso_id":"4","sigla":"MGA"},
  {"id":"48","edu_sistema_avaliacao_id":"8","edu_curso_id":"5","sigla":"MGA"}
  ]
  edu_curso_id=0
edu_turma_id=0
ano_avaliacao_id=0
edu_matriz_curriculares_disciplina_id=0
edu_sistema_avaliacoes_curso_id=0
edu_disciplina_id=0
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
dataUpdate={
  aprovado:0,
  id:0,
  pedido:null
}
  constructor(private router:Router,private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) { }

  ngOnInit(): void {
  this.user=this.authTorage.getUser()
  this.user_id=parseInt(this.user.user_assoc)
  this.getEstudantes()


    this.initPaginate()
    //this.getSac()
    this.toastr.warning("Preencha devidamente os campos necessários! Pois, aqui o docente irá importar as notas usando um ficheiro modelo fornecido pelo DAAC.", '!');
  }



getNota(sa:any,edu_matricula_id:any):number{
  var n=0
try{
  //console.log(sa+"  "+edu_matricula_id)

console.log(this.distribuicaoturmas)
console.log(this.data.find((v: { edu_sistema_avaliacoes_id: string; edu_matricula_id: string; })=>v.edu_sistema_avaliacoes_id==sa&&v.edu_matricula_id==edu_matricula_id))
 }
 catch(ex){
    console.log(ex)
 }
 return n
}

 initPaginate(){
  this.dtOptions = {
    pagingType: 'full_numbers'
    , pageLength: 10,
    lengthMenu: [ 25,50, 100, 1000],
     processing: true,
    dom: 'Bfrtip',
   // retrieve:true,


  };

}

async getEstudantes(){

  try{
    let p=this.router.url.split('/')
    
    this.repo.getAll(`/notas-aprovacao/${p[2]}/${p[3]}/${p[4]}`).subscribe((res)=>{
      console.log("res mini",res)
/*
const item = [...new Map(res.estudantes.map((item: { nome: any; }) => [item.nome, item])).values()]
        this.PredataEstudantes=item
        this.estudantesCadeirantes=res?.estudantesCadeirantes


        console.log("res",res)*/
      this.PredataEstudantes=res
      this.notaPautas= this.PredataEstudantes.notas
      this.dataEstudantes=this.PredataEstudantes.estudantes
    this.edu_disciplina_id=  this.notaPautas[0].edu_disciplina_id
        this.dataTableInit()
      this.notaPautas= this.PredataEstudantes.notas
 console.log( this.notaPautas)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}
getNotaEstu(edu_matricula_id:Number,edu_sistema_avaliacao_id:Number):number{
let result=0
try{


 console.log(this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id))
let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)
 if(edu_sistema_avaliacao_id==3&& parseFloat(Datanota.nota)>=10)
 result= Datanota.nota
else
result= Datanota.nota

}
catch(ex){
  result= 0
}
return result
}
getNotaId(edu_matricula_id:Number,edu_sistema_avaliacao_id:Number):number{
  let result=0
  try{



  let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)


  result= Datanota.id

  }
  catch(ex){
    result= 0
  }
  return result
  }



verifyFileds(){
  if(this.ano_avaliacao_id==0)
    throw new Error("Preencha todos os campos primeiro!")

  if( this.edu_curso_id==0)
  throw new Error("Preencha todos os campos primeiro!")

  if( this.edu_turma_id==0)
  throw new Error("Preencha todos os campos primeiro!")

  if(  this.edu_disciplina_id==0)
  throw new Error("Preencha todos os campos primeiro!")
}
notaEstado(aprovado:Number){
  let data=''
  console.log(`APROVADO :${aprovado}`)
  switch(aprovado){
    case 1:
      break

      case 2:
      data=  ' (Em aprovação)'
        break

      case 3:
        data= ' (Refutado)'
        break


      default:
      data=''
      break

  }
  return data
}
notaEdit(edu_matricula_id:Number,edu_sistema_avaliacao_id:Number){
 let  result=0
try{



 let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)
  if( parseInt(Datanota.aprovado)==1)
 result=1
 else  if( parseInt(Datanota.aprovado)==2)
 result=2
 else  if( parseInt(Datanota.aprovado)==3)
 result=3
 }
 catch(ex){
 return false
 }

  return result

}
notaUpdate(event:any){
 // alert(event.target.value)
console.log("NOTA UPDATE:"+event)
}
aproved(stutus:number,id:number){
  this.dataUpdate.aprovado=stutus
this.dataUpdate.id=id

this.repo.Add(this.dataUpdate,'/setaprovado').subscribe((res)=>{
  if(res.updateAprovado)
  this.toastr.success("A nota foi  aprovada.", '!');
  else
  this.toastr.warning("Infelizmente não foi possível aprovar a nota.", '!');




})
}
Refuted(stutus:number,id:number){
this.dataUpdate.aprovado=stutus
this.dataUpdate.id=id

this.repo.Add(this.dataUpdate,'/setaprovado').subscribe((res)=>{
   if(res.updateAprovado)
   this.toastr.success("A nota foi  refutada.", '!');
   else
   this.toastr.warning("Infelizmente não foi possível refutar a nota.", '!');




})
}

}
