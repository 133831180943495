import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { PagamentosService } from './services/pagamentos.service';
import { TemplatesService } from './services/templates.service';
import { UserAddComponent } from './components/base/users/user-add/user-add.component';
import { CookieService } from './services/base/cookie.service';
import { TokenStorageService } from './services/auth/token-storage.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'reporter';
  sectionTitle=''
isLoggin=false


  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private cookie:CookieService,
    private authStorege:TokenStorageService,
    private route :Router,
    private spinner: NgxSpinnerService)  {

    }


    ngOnInit(): void {
      this.spinner.show();

      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 5000);

    //
  /*  this.cookie.setCookie({
      name:"test",
      value:"Conta bloqueada",
      session:true
    })*/
this.sectionTitle=window.document.location.hash.replace('#/','').replace('-',' ')
.toLocaleUpperCase()

 if(this.authStorege.checkToken()==true)

 this.isLoggin=true
 else

//this.route.navigate(["/login"])
console.log("GUEST")
     }

}
