/* guards.guard.ts */
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { TokenStorageService } from 'src/app/repository/local/token-storage/token-storage.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardsGuard implements CanActivate, CanActivateChild {

  constructor(
    private authStorege:TokenStorageService ,
    private auth: AuthenticationService,
    private router: Router,
    private auth2:AuthService,
  ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /* redirect the navigation and return false */
    const myUrl = next.data.myUrl;

    if(!this.auth.isLoggedIn){
      this.router.navigate(['login'])
    }
    else{
      //
      if(this.authStorege.getGroup()!='ROLE_ADMIN'){
        this.auth2.checkGroup('/'+myUrl);

 }
   //
    }
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      /* redirect the navigation and return false */
      if(!this.auth.isLoggedIn){
        this.router.navigate(['login'])
      }
    return true;
  }

}
