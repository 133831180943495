

<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset> <legend class="fildset" style="text-align: center; ">Selecione o ano lectivo para visualizar as respectivas disciplinas.</legend>
                    <hr>
                    <div class="form-row">



                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Ano Avaliação *</label>
                          <ng-select [(ngModel)]="ano_avaliacao_id" (change)="getDistribucaoTurmas()">
                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                          </ng-select>
                      </div>




                              <hr>
                     <fieldset>


                     </fieldset>





                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid">

  <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" style="width: 100%;">
    <thead>
      <tr>
        <th with="25%">ID</th>
      <th with="25%">CURSO</th>
      <th with="25%">DISCIPLINA</th>
      <th with="25%">TURMA</th>
      </tr>


    </thead>
    <tbody>
      <tr *ngFor="let n of distribuicaoturmas index as i">
        <td >{{i+1}}</td>
        <td>{{n.curso}}</td>
        <td>{{n.turma}}</td>
          <td>{{n.disciplina}}</td>

      </tr>

    </tbody>
  </table>
</div>

