/* authentication.service.ts */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/repository/local/token-storage/token-storage.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(  private authStorege:TokenStorageService , private auth2:AuthService,  private router: Router) { }

  get isAdmin(){
    let result=true
     /* using the get keyword allows us to access the function as a property */
     let url=this.router.getCurrentNavigation()?.finalUrl

     if(this.authStorege.getGroup()!='ROLE_ADMIN'){
       this.auth2.checkGroup(url);

}
     else result=true



      return result;
  }

  get isLoggedIn(){
    if(this.authStorege.checkToken()==true)

    return true;
 else


     return false;
  }
}
