import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

import { CookieService } from 'src/app/services/base/cookie.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
auth2_form=0
  form: any = {
    username: null,
    password: null,
    roles:null,
    password_verify:null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  lockLogin=false
  attempts=0
  tempAttempts=0
  saudacao=''
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,
    private cookie:CookieService,private route:Router,
    private toastr: ToastrService
    ) {
      const horaAtual = new Date().getHours();

      if (horaAtual >= 5 && horaAtual < 12) {
        this.saudacao = 'Olá, bom dia!';
      } else if (horaAtual >= 12 && horaAtual < 18) {
        this.saudacao = 'Olá, boa tarde!';
      } else {
        this.saudacao = 'Olá, boa noite!';
      }
this.tempAttempts=parseInt( this.cookie.getCookie("lastValueAttpts"))

this.authService.attempts=this.tempAttempts
this.attempts=this.tempAttempts
if(this.tempAttempts>=3)
this.lockLogin=true

    }

  ngOnInit(): void {
//this.verificarVersoa()
    if (this.tokenStorage.checkToken()==true) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.route.navigate(["/"])
    }


  }
  sele(v:string){
    console.log(v)
  }
  login(){
if(this.verificarVersoa()===true){
    if(this.form.username==''|| this.form.username==null)
    this.toastr.error("Preencha os campos!")
   else if(this.form.password==''|| this.form.password==null)
    this.toastr.error("Preencha os campos!")
    else

    this. onSubmit()
  }
  else{

  }
  }
  onSubmit(): void {


console.log(this.form)

    const { username, password } = this.form;

    this.authService.login(username, password)

    .subscribe(
      data => {
        console.log(data)
        if(data.verify){
              this.auth2_form=1


                  if(data.guest){
                    this.form.password_verify=''
                    this.auth2()
                  }else{
                    this.toastr.success('Autirização de dois factores', 'SUCCESS');
                  }
       // this.route.navigate(['/'])
      }
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        console.log(err.error.message)
        this.toastr.error(err.error.message, 'ERROR');
        this.checkMe()
      /* if(err.status===0)

       console.log("SERVIDOR DESLIGADO!")lockLogin
       else
*/
      }
    );

  }

  reloadPage(): void {
    window.location.reload();
  }
checkMe(){
  if(this.attempts <4){
  this.authService.loginAttempts()

  this.attempts=this.authService.attempts
}
if(this.attempts >4||this.attempts ===4){
this.lockLogin=true
}}

verificarVersoa():boolean {

  let estado = false
  let data = new Date()
  let dataFim="2023-08-20"
  let data_string = data.toISOString();
  data_string = data_string.substr(0, 10);
  if (data_string <= dataFim){
    const licenceseSMS=`THIS VERSION IS VALID AT ${dataFim}, GREAT WORK!`
    this.toastr.success(licenceseSMS, 'LICENCE', {
      timeOut: 3000,
    });

  return true}
  else {


  this.toastr.error("THE LICENSE OF THIS APPLICATION WAS BEEN EXPIRED AT "+ dataFim +" CALL US FOR ASSISTENCE ","LICENSE")
return true
  }
}
auth2(): void {


  console.log(this.form)

      const { username, password_verify } = this.form;

      this.authService.auth2(username, password_verify)

      .subscribe(
        data => {
          console.log(data)
          if(data.token){
          this.tokenStorage.saveToken(data.token);
          this.tokenStorage.saveRefreshToken(data.refreshToken);
          this.tokenStorage.saveUser(data);
          this.tokenStorage.saveRoles(data.roles);
          this.tokenStorage.saveGroupId(data.groupid);
            console.log(data.roles)
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          this.toastr.success('Bem vindo', 'SUCCESS');
         this.reloadPage();
         // this.route.navigate(['/'])
        }
        },
        err => {
          this.errorMessage = err.error.message;
          if(err.error.message=='Invalid Password!'){
            setTimeout(() => {
              window.location.reload()
            }, 3000);
          }


          this.toastr.error(err.error.message, 'ERROR');
          this.checkMe()
         if(err.status===0){

         console.log("TENTE MAIS TARDE!")
        }
         else{}

        }
      );

    }
    verifyAuth2(){
      this.auth2()
    }
}
