<div class="full-screen-container">
  <div class="login-container">

    <div class="text-center">

      <h3 class="login-title" style="font-size: 40px;">Alterar a senha</h3>
      <input  placeholder="Senha antiga"  class="form-control mb-2" [(ngModel)]="form.password_old"  name="password_old" type="password"  required="">
      <input type="password" placeholder="Senha nova"   class="form-control" name="password_new" [(ngModel)]="form.password_new" appIntegerOnly>

    </div>

      <div class="text-center mt-2">
        <button class="btn btn-primary" (click)="change()">Alterar</button>
      </div>
    <hr>








  </div>
</div>
