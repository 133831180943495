
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">

                          <!--

                            <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                              <label class="control-label">Ano Avaliação *</label>


                                  <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                                  {{anos.name}}


                          </div>-->
                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="10%">Nº</th>
          <th width="10%">Pedido</th>
          <th width="10%">Docente</th>
          <th width="10%">Curso</th>
          <th width="10%">Disciplina</th>
          <th width="10%">Turma</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pedido of data index as i">
          <td >{{i+1}}</td>
          <td><a routerLink="/aprovacaodepautas/{{pedido.pedido}}/{{pedido.edu_turma_id}}/{{pedido.edu_disciplina_id}}">{{pedido.pedido}}</a></td>
          <td>{{pedido.name}}</td>

          <td>{{pedido.curso}}</td>
          <td>{{pedido.disciplina}}</td>
          <td>{{pedido.turma}}</td>
        </tr>
      </tbody>
      <tbody>


      </tbody>
    </table>

    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
