import { OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { UserAddComponent } from '../../base/users/user-add/user-add.component';
import { DataTableDirective } from 'angular-datatables';
import { MesesService } from 'src/app/services/meses.service';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TitleService } from 'src/app/services/base/title.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import jsPDF, * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';
@Component({
  selector: 'app-global-reporter',
  templateUrl: './global-reporter.component.html',
  styleUrls: ['./global-reporter.component.css']
})
export class GlobalReporterComponent implements OnInit {
  namePDF='mesalidade'
  dataPDF:any
  isPDF=false
  totalPrevisto=0
  quantidadeGeral=0
  TotalGlobal=0
  title = 'reporter';
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"

  ano_avaliacoes:any
  turmas:any
  turma_id:Number=0

  cursos:any
  curso_id:Number=0
  ano_avaliacoes_id:Number=0
  resumoPorCurso:any
  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private PDFService:ServicePDFjsPDFService) {

    }
  meses:any
  anoSelecionado:Number=0
    getMeses:any
 Pagamentos:any
 data:any
 dataEstudantes:any
 PredadosdataEstudantes:any
pagamentosBrutos:any
Av={
  id:null,
  name:null
}
    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
     this. getAnos()

    //Loading child components
    this.loadingChildComponents();
    this.loadingData();

   this.initPaginate()
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("Relatório de Mensalidades Anuais - "+this.anoSelecionado)
    }
    loadingChildComponents(){
      this.modalComponent=UserAddComponent
    }
    initPaginate(){
      this.dtOptions = {
        pagingType: 'full_numbers'
        , pageLength: 80,
        lengthMenu: [ 25,50, 100, 1000],
         processing: true,
        dom: 'Bfrtip',
       // retrieve:true,


      };
    }
getPreco(mes:string,id_estudadente:string){
  var preco="0"


try{
  var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
    return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente;
   });
  if(pagamento[0].preco)
  {
   // this.TotalGlobal =  this.TotalGlobal+parseInt(pagamento[0].preco)
  preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
  }
}
catch(ex){

}
   return parseFloat(preco).toLocaleString()
}
formatNumber(preco:any){
try{
  return preco.toLocaleString()
}
catch(ex){

}
}
 async getJSON(){
try{
  this.repo.getAll('/mesalidadesporano/'+this.turma_id).subscribe((res)=>{
     this.data=res;
     this.data=this.data.pagamentos
     this.resumoPorCurso=res.resumo
     this.totalPrevisto= this.data[0].preco*this.quantidadeGeral


    // const item = [...new Map(this.data.map((item: { Estudante: any; }) => [item.Estudante, item])).values()]
     //this.Pagamentos=item
     //console.log(this.data)

       this.dataEstudantes=this.PredadosdataEstudantes
       this.pagamentosBrutos=this.data
       //this.dtTrigger.next();
       this.dataTableInit();
       this.isPDF=true
       //this.getresumoPorCurso()
  });
}
catch(ex){
  console.log(ex)
}
 }
 async getTurmas(){

  try{
    this.repo.getAll('/turmas'+"/"+this.ano_avaliacoes_id+"/"+this.curso_id).subscribe((res)=>{


      this.turmas=res.turmas
 console.log( this.turmas)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 async getAnos(){

  try{
    this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
      this.data=res;

      this.ano_avaliacoes=this.data.ano_avaliacoes
 console.log( this.ano_avaliacoes)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 async getCursos(){

  try{
    this.repo.getAll('/cursos').subscribe((res)=>{


      this.cursos=res.cursos
 console.log( this.cursos)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 async getresumoPorCurso(){

  try{
    this.repo.getAll('/pagamentoresumoporcurso/'+this.turma_id).subscribe((res)=>{


      this.resumoPorCurso=res.resumo

 console.log( this.resumoPorCurso)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 async getJSONEstudantes(){
 try{

  this.ngOnDestroy()


  this.repo.getAll('/estudantes/'+this.ano_avaliacoes_id+'/?edu_turma_id='+this.turma_id).subscribe((res)=>{
     this.PredadosdataEstudantes=res;
     this.PredadosdataEstudantes=this.PredadosdataEstudantes.estudantes
     console.log(res)
     console.log(this.dataEstudantes)
     this.quantidadeGeral=this.PredadosdataEstudantes.length*10
  })
  this.setTitle()
 }
 catch(ex){
  console.log(ex)
 }
 }

 loadingData(){
  this.getMeses=this.meses

  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }
 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}

getTotal(id_estudadente:string):string{
  var preco="0"


  try{
    var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
      return res.mensalidade!=null &&res.edu_estudante_id==id_estudadente;
     });

    preco=pagamento.length
    console.log(pagamento.length)

  }
  catch(ex){
    console.log(ex)
  }
     return preco

}
getDiferenca(id_estudadente:string):Number{

  var preco=0


  try{
    var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
      return res.mensalidade!=null &&res.edu_estudante_id==id_estudadente;
     });
     var [x,t]=[10,parseInt(pagamento.length)];

    preco=(t-x)
    console.log("DIF"+preco.toString())

  }
  catch(ex){
    console.log(ex)
  }
     return preco
}
exportAsPDF(divId:string)
{
   /* this. dataPDF = document.getElementById(divId)
    html2canvas(this. dataPDF).then(canvas => {
    const contentDataURL = canvas.toDataURL('image/png')  // 'image/jpeg' for lower quality output.
   // let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
     let pdf = new jspdf('p', 'cm', 'a4');// Generates PDF in portrait mode
    pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
    pdf.save('Filename.pdf');
  });*/
}
generatePdf(data:any) {

  this.PDFService.generatePdf(data,this.namePDF+new Date().toISOString()+'.pdf')


}
}
