import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],

})
export class ChangePasswordComponent implements OnInit {
  form={
    password_new:'',
    password_old:'',
  }
  constructor(private authService: AuthService, private toastr: ToastrService) {

  }

  ngOnInit(): void {
  }

  onSubmit(): void {

        const { password_new, password_old } = this.form;

        this.authService.change_pw(password_old, password_new)

        .subscribe(
          data => {

            if(data.change){
                  this.toastr.success("Senha alterada!")
           // this.route.navigate(['/'])
          }
          else{
            this.toastr.error("Senha não alterada!")
          }
          },
          err => {

            this.toastr.error(err.error.message, 'ERROR');

          /* if(err.status===0)

           console.log("SERVIDOR DESLIGADO!")lockLogin
           else
    */
          }
        );

      }
      change(){
        if(this.form.password_new.toString().length >=8 && this.form.password_old!=''){


            if(this.isWeakPassword(this.form.password_new.toString())){
              this.toastr.error("Senha fraca! Tem de ter 8 caracteres - números e letrasmenúsculas e maúsculas e caracteresespeciais.")
            }
            else{

              this.onSubmit()
            }
        }
        else{
          this.toastr.error("Preencha os capos, por favor! Senha antiga e nova" ,'ERROR');
        }
      }


      isWeakPassword(password:string) {
        // Check length
        if (password.length < 8) {
          this.toastr.error("Senha fraca! Tem de ter 8 caracteres - números e letrasmenúsculas e maúsculas")
          return true;
        }
        // Check for at least one letter and one number
        if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
          return true;
        }
        return false;


      }

      // Example usage:





    }
