import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import * as XLSX from 'xlsx'
@Component({
  selector: 'app-docentes',
  templateUrl: './docentes.component.html',
  styleUrls: ['./docentes.component.css']
})
export class DocentesComponent implements OnInit {
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
importPauta=false
isPut=5
aprovado=2
pedido=''
provaTipo=''
sistemaAvalicao=[
  {id:1,code:'PP1',name:"1ªPP"},
{id:2,code:'PP2',name:"2ªPP"},
{id:9,code:'MAC',name:"MAC"},
{id:8,code:'MGA',name:"MGA"},
{id:3,code:'EXAME',name:"EXAME"},
{id:4,code:'MEDIAFINAL',name:"MEDIA FINAL"},

{id:7,code:'RESULTADOFINAL',name:"RESULTADO FINAL"},
{id:5,code:'RECURSO',name:"RECURSO"},
{id:6,code:'EXAMEESPECIAL',name:"EXAME ESPECIAL"},
{id:10,code:'SITUACAOACADEMICA',name:"SITUACAO ACADEMICA"}
]
sac=[
  {"id":"2","edu_sistema_avaliacao_id":"2","edu_curso_id":"4","sigla":"2ªPP"},
  {"id":"3","edu_sistema_avaliacao_id":"3","edu_curso_id":"4","sigla":"EXAME"},
  {"id":"4","edu_sistema_avaliacao_id":"4","edu_curso_id":"4","sigla":"MÉDIAFINAL"},
  {"id":"5","edu_sistema_avaliacao_id":"5","edu_curso_id":"4","sigla":"RECURSO"},
  {"id":"6","edu_sistema_avaliacao_id":"6","edu_curso_id":"4","sigla":"EE"},
  {"id":"7","edu_sistema_avaliacao_id":"7","edu_curso_id":"4","sigla":"CF"},
  {"id":"8","edu_sistema_avaliacao_id":"1","edu_curso_id":"4","sigla":"1ªPP"},
  {"id":"9","edu_sistema_avaliacao_id":"1","edu_curso_id":"2","sigla":"1ªPP"},
  {"id":"10","edu_sistema_avaliacao_id":"1","edu_curso_id":"3","sigla":"1ªPP"},
  {"id":"11","edu_sistema_avaliacao_id":"1","edu_curso_id":"5","sigla":"1ªPP"},
  {"id":"12","edu_sistema_avaliacao_id":"2","edu_curso_id":"1","sigla":"2ªPP"},
  {"id":"13","edu_sistema_avaliacao_id":"2","edu_curso_id":"2","sigla":"2ªPP"},
  {"id":"14","edu_sistema_avaliacao_id":"2","edu_curso_id":"5","sigla":"2ªPP"},
  {"id":"15","edu_sistema_avaliacao_id":"2","edu_curso_id":"3","sigla":"2ªPP"},
  {"id":"16","edu_sistema_avaliacao_id":"3","edu_curso_id":"1","sigla":"EXAME"},
  {"id":"17","edu_sistema_avaliacao_id":"3","edu_curso_id":"2","sigla":"EXAME"},
  {"id":"18","edu_sistema_avaliacao_id":"3","edu_curso_id":"3","sigla":"EXAME"},
  {"id":"19","edu_sistema_avaliacao_id":"3","edu_curso_id":"5","sigla":"EXAME"},
  {"id":"20","edu_sistema_avaliacao_id":"5","edu_curso_id":"1","sigla":"RECURSO"},
  {"id":"21","edu_sistema_avaliacao_id":"5","edu_curso_id":"2","sigla":"RECURSO"},
  {"id":"22","edu_sistema_avaliacao_id":"5","edu_curso_id":"5","sigla":"RECURSO"},
  {"id":"23","edu_sistema_avaliacao_id":"5","edu_curso_id":"3","sigla":"RECURSO"},
  {"id":"24","edu_sistema_avaliacao_id":"4","edu_curso_id":"1","sigla":"MÉDIAFINAL"},
  {"id":"25","edu_sistema_avaliacao_id":"4","edu_curso_id":"2","sigla":"MÉDIAFINAL"},
  {"id":"26","edu_sistema_avaliacao_id":"4","edu_curso_id":"3","sigla":"MÉDIAFINAL"},
  {"id":"27","edu_sistema_avaliacao_id":"4","edu_curso_id":"5","sigla":"MÉDIAFINAL"},
  {"id":"28","edu_sistema_avaliacao_id":"6","edu_curso_id":"1","sigla":"EP"},
  {"id":"29","edu_sistema_avaliacao_id":"6","edu_curso_id":"3","sigla":"EP"},
  {"id":"30","edu_sistema_avaliacao_id":"6","edu_curso_id":"5","sigla":"EP"},
  {"id":"31","edu_sistema_avaliacao_id":"6","edu_curso_id":"2","sigla":"EP"},
  {"id":"32","edu_sistema_avaliacao_id":"7","edu_curso_id":"1","sigla":"CF"},
  {"id":"33","edu_sistema_avaliacao_id":"7","edu_curso_id":"2","sigla":"CF"},
  {"id":"34","edu_sistema_avaliacao_id":"7","edu_curso_id":"3","sigla":"CF"},
  {"id":"37","edu_sistema_avaliacao_id":"7","edu_curso_id":"5","sigla":"CF"},
  {"id":"38","edu_sistema_avaliacao_id":"1","edu_curso_id":"1","sigla":"1ªPP"},
  {"id":"39","edu_sistema_avaliacao_id":"9","edu_curso_id":"1","sigla":"MCA"},
  {"id":"40","edu_sistema_avaliacao_id":"9","edu_curso_id":"2","sigla":"MCA"},
  {"id":"41","edu_sistema_avaliacao_id":"9","edu_curso_id":"3","sigla":"MCA"},
  {"id":"42","edu_sistema_avaliacao_id":"9","edu_curso_id":"4","sigla":"MAC"},
  {"id":"43","edu_sistema_avaliacao_id":"9","edu_curso_id":"5","sigla":"MAC"},
  {"id":"44","edu_sistema_avaliacao_id":"8","edu_curso_id":"1","sigla":"MGA"},
  {"id":"45","edu_sistema_avaliacao_id":"8","edu_curso_id":"3","sigla":"MGA"},
  {"id":"46","edu_sistema_avaliacao_id":"8","edu_curso_id":"2","sigla":"MGA"},
  {"id":"47","edu_sistema_avaliacao_id":"8","edu_curso_id":"4","sigla":"MGA"},
  {"id":"48","edu_sistema_avaliacao_id":"8","edu_curso_id":"5","sigla":"MGA"}
  ]
  edu_curso_id=0
edu_turma_id=0
ano_avaliacao_id=0
edu_matriz_curriculares_disciplina_id=0
edu_sistema_avaliacoes_curso_id=0
edu_disciplina_id=0
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
putData:any
  constructor(private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) { }

  ngOnInit(): void {
  this.user=this.authTorage.getUser()
  this.user_id=parseInt(this.user.user_assoc)
    this.getAnos()
    this.getIsPut()
    this.initPaginate()
    //this.getSac()
    this.toastr.warning("Preencha devidamente os campos necessários! Pois, aqui o docente irá importar as notas usando um ficheiro modelo fornecido pelo DAAC.", '!');
  }


  loadP1(event:any):void{
    this.provaTipo='PP1'
    try{
   this.verifyFileds()
  this.importPauta=true
  this.setPedido(this.edu_disciplina_id,this.edu_sistema_avaliacao_id,this.edu_turma_id,this.user_id,this.edu_curso_id)
    const selectedFile=event.target.files[0];
    const fileReader=new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload=(event:any)=>{
      console.log(event)

      let binaryData=event.target.result;
      let workbook=XLSX.read(binaryData,{type:'binary'})
      workbook.SheetNames.forEach(sheet=>{
        const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

     this.data=data
     for (let index = 0; index < this.sistemaAvalicao.length; index++) {
              switch (this.sistemaAvalicao[index].id) {

                case 1:
                  this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                  this.pp1(data)
                  break;


                default:
                  break;
              }

     }

      })

    }
    this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
    //Dados temporarios
}
catch(ex:any){
  this.toastr.error(ex.message,"ERROR")
}
      }


      loadP2(event:any):void{
        this.provaTipo='PP2'
        try{
       this.verifyFileds()
      this.importPauta=true
      this.setPedido(this.edu_disciplina_id,this.edu_sistema_avaliacao_id,this.edu_turma_id,this.user_id,this.edu_curso_id)
        const selectedFile=event.target.files[0];
        const fileReader=new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload=(event:any)=>{
          console.log(event)

          let binaryData=event.target.result;
          let workbook=XLSX.read(binaryData,{type:'binary'})
          workbook.SheetNames.forEach(sheet=>{
            const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

         this.data=data
         for (let index = 0; index < this.sistemaAvalicao.length; index++) {
                  switch (this.sistemaAvalicao[index].id) {


                      case 2:
                        this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                        this.pp2(data)
                        break;
                        case 9:
                        this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                        this.mac(data)
                        break;
                        case 8:
                        this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                        this.media(data)
                        break;

                    default:
                      break;
                  }

         }

          })

        }
        this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
        //Dados temporarios
    }
    catch(ex:any){
      this.toastr.error(ex.message,"ERROR")
    }
          }


  loadP1P2MACMG(event:any):void{
    this.provaTipo='PP1PP2MACMG'
    try{
   this.verifyFileds()
  this.importPauta=true
  this.setPedido(this.edu_disciplina_id,this.edu_sistema_avaliacao_id,this.edu_turma_id,this.user_id,this.edu_curso_id)
    const selectedFile=event.target.files[0];
    const fileReader=new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload=(event:any)=>{
      console.log(event)

      let binaryData=event.target.result;
      let workbook=XLSX.read(binaryData,{type:'binary'})
      workbook.SheetNames.forEach(sheet=>{
        const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

     this.data=data
     for (let index = 0; index < this.sistemaAvalicao.length; index++) {
              switch (this.sistemaAvalicao[index].id) {

                case 1:
                  this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                  this.pp1(data)
                  break;
                  case 2:
                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                    this.pp2(data)
                    break;
                    case 9:
                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                    this.mac(data)
                    break;
                    case 8:
                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                    this.media(data)
                    break;

                default:
                  break;
              }

     }

      })

    }
    this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
    //Dados temporarios
}
catch(ex:any){
  this.toastr.error(ex.message,"ERROR")
}
      }

  loadExame(event:any):void{
    this.provaTipo='EXAME'
    try{
      this.verifyFileds()

    this.importPauta=true
      const selectedFile=event.target.files[0];
      const fileReader=new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload=(event:any)=>{
        console.log(event)

        let binaryData=event.target.result;
        let workbook=XLSX.read(binaryData,{type:'binary'})
        workbook.SheetNames.forEach(sheet=>{
          const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

       this.data=data
       for (let index = 0; index < this.sistemaAvalicao.length; index++) {
                switch (this.sistemaAvalicao[index].id) {

                      case 3:
                      this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                      this.exame(data)
                      break;
                      case 4:
                      this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                      this.mediafinal(data)
                      break;
                  default:
                    break;
                }

       }

        })

      }
      this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
      //Dados temporarios
    }
    catch(ex:any){
      this.toastr.error(ex.message,"ERROR")
    }
        }
        loadExameRecurso(event:any):void{
          this.provaTipo='RECURSO'
          try{
            this.verifyFileds()

          this.importPauta=true
            const selectedFile=event.target.files[0];
            const fileReader=new FileReader();
            fileReader.readAsBinaryString(selectedFile);
            fileReader.onload=(event:any)=>{
              console.log(event)

              let binaryData=event.target.result;
              let workbook=XLSX.read(binaryData,{type:'binary'})
              workbook.SheetNames.forEach(sheet=>{
                const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

             this.data=data
             for (let index = 0; index < this.sistemaAvalicao.length; index++) {
                      switch (this.sistemaAvalicao[index].id) {

                            case 5:
                            this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                            this.exameRecurso(data)
                            break;

                        default:
                          break;
                      }

             }

              })

            }
            this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
            //Dados temporarios
          }
          catch(ex:any){
            this.toastr.error(ex.message,"ERROR")
          }
              }
              loadExameEspecial(event:any):void{
                this.provaTipo='EXAMEESPECIAL'
                try{
                  this.verifyFileds()

                this.importPauta=true
                  const selectedFile=event.target.files[0];
                  const fileReader=new FileReader();
                  fileReader.readAsBinaryString(selectedFile);
                  fileReader.onload=(event:any)=>{
                    console.log(event)

                    let binaryData=event.target.result;
                    let workbook=XLSX.read(binaryData,{type:'binary'})
                    workbook.SheetNames.forEach(sheet=>{
                      const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

                   this.data=data
                   for (let index = 0; index < this.sistemaAvalicao.length; index++) {
                            switch (this.sistemaAvalicao[index].id) {

                                  case 6:
                                  this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                                  this.exameEspecial(data)
                                  break;

                              default:
                                break;
                            }

                   }

                    })

                  }
                  this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
                  //Dados temporarios
                }
                catch(ex:any){
                  this.toastr.error(ex.message,"ERROR")
                }
                    }


  loadAll(event:any):void{
    this.provaTipo='TODAS'
    try{
      this.verifyFileds()

    this.importPauta=true
      const selectedFile=event.target.files[0];
      const fileReader=new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload=(event:any)=>{
        console.log(event)

        let binaryData=event.target.result;
        let workbook=XLSX.read(binaryData,{type:'binary'})
        workbook.SheetNames.forEach(sheet=>{
          const data =XLSX.utils.sheet_to_json(workbook.Sheets[sheet])

       this.data=data
       for (let index = 0; index < this.sistemaAvalicao.length; index++) {
                switch (this.sistemaAvalicao[index].id) {

                  case 1:
                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                    this.pp1(data)
                    break;
                    case 2:
                      this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                      this.pp2(data)
                      break;
                      case 9:
                      this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                      this.mac(data)
                      break;
                      case 8:
                      this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                      this.media(data)
                      break;
                      case 5:
                            this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                            this.exameRecurso(data)
                            break;

                      case 6:
                  this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                                  this.exameEspecial(data)
                                  break;
                                  case 3:
                                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                                    this.exame(data)
                                    break;
                                    case 4:
                                    this.edu_sistema_avaliacao_id=this.sistemaAvalicao[index].id
                                    this.mediafinal(data)
                                    break;

                  default:
                    break;
                }

       }

        })

      }
      this.toastr.success("Dados prontos para serem enviados, clica em 'CARREGAR' para mandar no servidor!", 'SUCESS');
      //Dados temporarios
    }
    catch(ex:any){
      this.toastr.error(ex.message,"ERROR")
    }
        }


      pp1(data:any){



        for(let i=0;i<data.length;i++){
       // console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].PP1,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
      pp2(data:any){



        for(let i=0;i<data.length;i++){
      //  console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].PP2,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }


      mac(data:any){



        for(let i=0;i<data.length;i++){
      //  console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].MAC,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
      //  console.log(this.pauta)
      }


      exame(data:any){



        for(let i=0;i<data.length;i++){
       // console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].EXAME,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
      media(data:any){



        for(let i=0;i<data.length;i++){
        //console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].MGA,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
      mediafinal(data:any){



        for(let i=0;i<data.length;i++){
        //console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].MEDIAFINAL,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
      exameRecurso(data:any){



        for(let i=0;i<data.length;i++){
        //console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].RECURSO,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
      exameEspecial(data:any){



        for(let i=0;i<data.length;i++){
        //console.log(" Nome:"+this.data[i].Nome)
        this.addNota(1,data[i].MATRICULA,
         this.edu_turma_id,
          this.ano_avaliacao_id,
         this.edu_matriz_curriculares_disciplina_id,
              0,
          this.edu_sistema_avaliacoes_curso_id,
         data[i].EXAMEEXPECIAL,
          data[i].ESTUDANTE,
          this.edu_disciplina_id,)
        }
       // console.log(this.pauta)
      }
addNota(id:number,edu_matricula_id:string,edu_turma_id:number,
  ano_avaliacao_id:number,
  edu_matriz_curriculares_disciplina_id:number,
  user_id:number,
  edu_sistema_avaliacoes_curso_id:number,
  nota:string,
  edu_estudante_id:number,
  edu_disciplina_id:number,
  ):void{
  this.pauta.push(
    {
     // id:id,

        edu_turma_id: edu_turma_id,
      ano_avaliacao_id:ano_avaliacao_id,
      edu_matriz_curriculares_disciplina_id: this.getemcc(this.edu_disciplina_id,this.edu_curso_id),
      user_id: this.user_id,
      edu_sistema_avaliacoes_curso_id:  this.getSac(this.edu_sistema_avaliacao_id,this.edu_curso_id),
      edu_sistema_avaliacao_id: this.edu_sistema_avaliacao_id,
      nota:nota,
      edu_matricula_id: edu_matricula_id,
      edu_estudante_id: edu_estudante_id,
      edu_disciplina_id : this.edu_disciplina_id,
      pedido:this.pedido,
      aprovado:this.aprovado



  })
}
getSac(sa:number,idcurso:number):number{
  var i=0
try{
  console.log("sa:"+sa+" idcurso"+idcurso)
  const data= this.sac.filter(n=>n.edu_curso_id==idcurso.toString() &&n.edu_sistema_avaliacao_id==sa.toString())
 console.log(data[0].id)
 i=parseInt(data[0].id)
 }
 catch(ex){

 }
 return i
}
getemcc(disciplinaid:number,idcurso:number):number{
  var i=0
try{
  console.log("disc:"+disciplinaid+" idcurso"+idcurso)
  const data= this.distribuicaoturmas.filter((n: { edu_curso_id: string; edu_disciplina_id: string; })=>n.edu_curso_id==idcurso.toString() &&n.edu_disciplina_id==disciplinaid.toString())
 console.log(data[0].id)
 i=parseInt(data[0].id)
 }
 catch(ex){

 }
 return i
}
ative(){
  this.data=this.pauta
}
getNota(sa:any,edu_matricula_id:any):number{
  var n=0
try{
  //console.log(sa+"  "+edu_matricula_id)

console.log(this.distribuicaoturmas)
console.log(this.data.find((v: { edu_sistema_avaliacoes_id: string; edu_matricula_id: string; })=>v.edu_sistema_avaliacoes_id==sa&&v.edu_matricula_id==edu_matricula_id))
 }
 catch(ex){
    console.log(ex)
 }
 return n
}
send(){

  this.repo.Add(this.pauta,'/importpauta').subscribe((res)=>{


console.log( res)
if(res.import==true){

this.toastr.success("Os dados foram importados com éxitos! A página vai atualizar automáticamente!", 'SUCESS');
setTimeout(() => {
  window.location.reload()
}, 3000);

}
 })


}

async getDistribucaoTurmas(){

  try{
    this.repo.getAll('/distribuicaoturmas/'+this.ano_avaliacao_id).subscribe((res)=>{


      this.distribuicaoturmas=res.distribuicaoturmas
   this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]
 console.log( this.edu_cursos)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 async getDisciplinasPorturma(){

  try{
    this.repo.getAll('/disciplinasdocente/'+this.ano_avaliacao_id+'/'+this.edu_turma_id).subscribe((res)=>{


      this.disciplinas=res.disciplinas

 console.log( this.disciplinas)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 getTurmas(){
try{
  this.edu_turmas= this.distribuicaoturmas.find((v: { edu_curso_id: string;})=>v.edu_curso_id==this.edu_curso_id.toString())
  console.log(this.edu_turmas)
}
catch(ex){

}
}
 async getAnos(){

  try{
    this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
      this.data=res;

      this.ano_avaliacoes=this.data.ano_avaliacoes
 console.log( this.ano_avaliacoes)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 initPaginate(){
  this.dtOptions = {
    pagingType: 'full_numbers'
    , pageLength: 10,
    lengthMenu: [ 25,50, 100, 1000],
     processing: true,
    dom: 'Bfrtip',
   // retrieve:true,


  };

}

async getEstudantes(){

  try{
    this.repo.getAll('/estudantesdocentes/'+this.edu_turma_id).subscribe((res)=>{


      this.PredataEstudantes=res.estudantes
//this.dataTableInit()
 console.log( this.edu_cursos)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
 dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}
getPauta(){

  try{
    this.repo.getAll('/notas/'+this.edu_turma_id+"/"+this.edu_disciplina_id).subscribe((res)=>{


      this.notaPautas=res.notas
      this.dataEstudantes=this.PredataEstudantes
this.dataTableInit()



   })
  }
  catch(ex){
    console.log(ex)
  }
}
getNotaEstu(edu_matricula_id:Number,edu_sistema_avaliacao_id:Number):number{
let result=0
try{



let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)
 if(edu_sistema_avaliacao_id==3&& parseFloat(Datanota.nota)>=10)
 result= Datanota.nota
else
result= Datanota.nota

}
catch(ex){
  result= 0
}
return result
}

temp(){
  this.repo.Add(this.pauta,'/importpautatemp').subscribe((res)=>{

this.notaPautas=res.notas
this.dataEstudantes=this.PredataEstudantes
this.dataTableInit()
console.log( res.notas)


this.toastr.success("Dados carregados de forma temporária, atualize o navegador caso deseje limpar!", 'SUCESS');


 })


}


async getIsPut(){

  try{
    this.repo.getAll('/is-put/').subscribe((res)=>{


      this.isPut=res?.put
//this.dataTableInit()
this.putData=res?.result
 console.log( res.result)


   })
  }
  catch(ex){
    console.log(ex)
  }
 }
setPedido(idDisciplina:Number,sisteAvaliacao:Number,idTurma:Number,idUser:Number,edu_curso_id:Number){
 this.pedido= `${idDisciplina}-${sisteAvaliacao}-${idTurma}-${idUser}-${edu_curso_id}-${this.ano_avaliacao_id}-${this.provaTipo}`

}

verifyFileds(){
  if(this.ano_avaliacao_id==0)
    throw new Error("Preencha todos os campos primeiro!")

  if( this.edu_curso_id==0)
  throw new Error("Preencha todos os campos primeiro!")

  if( this.edu_turma_id==0)
  throw new Error("Preencha todos os campos primeiro!")

  if(  this.edu_disciplina_id==0)
  throw new Error("Preencha todos os campos primeiro!")
}
notaEstado(aprovado:Number){
  let data=''
  console.log(`APROVADO :${aprovado}`)
  switch(aprovado){
    case 1:
      break

      case 2:
      data=  ' (Em aprovação)'
        break

      case 3:
        data= ' (Refutado)'
        break


      default:
      data=''
      break

  }
  return data
}
notaEdit(edu_matricula_id:Number,edu_sistema_avaliacao_id:Number){
 let  result=0
try{



 let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)
  if( parseInt(Datanota.aprovado)==1)
 result=1
 else  if( parseInt(Datanota.aprovado)==2)
 result=2
 else  if( parseInt(Datanota.aprovado)==3)
 result=3
 }
 catch(ex){
 return false
 }

  return result

}
notaUpdate(event:any,edu_matricula_id:Number,edu_sistema_avaliacao_id:Number){
 // alert(event.target.value)
 let id=0

try{




   let Datanota =this.notaPautas.find((data: { edu_disciplina_id: number; edu_sistema_avaliacao_id: number; edu_matricula_id: number; })=>data.edu_disciplina_id==this.edu_disciplina_id&&data.edu_sistema_avaliacao_id==edu_sistema_avaliacao_id&&data.edu_matricula_id==edu_matricula_id)
     if(Datanota.id){

   id =Datanota.id


  this.repo.Add({id:id,nota:parseFloat(event.target.value)},'/update-nota').subscribe((res)=>{

 this.toastr.success("Atualizado! Aguarde a aprovação do DAAC", 'SUCESS');

 })

}
}
catch(ex){
  console.log(ex)
}
}
}
