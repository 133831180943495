import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';

@Component({
  selector: 'app-candindaturas',
  templateUrl: './candindaturas.component.html',
  styleUrls: ['./candindaturas.component.css']
})
export class CandindaturasComponent implements OnInit {
  separator=[
    {id:1,color:"alert alert-danger"},
    {id:2,color:"alert alert-success"},
  ]
  isChecked='A'
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  total=0
  totalElem:any
  ano_avaliacoes:any
  ano_avaliacoes_id:Number=0
  resumoPorCurso:any
  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService) {

    }
  meses:any
  anoSelecionado=0
    getMeses:any
 Pagamentos:any
 data:any
 dataCandidaturas:any
 PredadosdataCandidaturas:any
pagamentosBrutos:any
Av={
  id:null,
  name:null
}
    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
     this. getAnos()
    //Loading child components
    this.loadingChildComponents();
    this.loadingData();

   this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("Relatório de Candidaturas - "+this.anoSelecionado+new Date().toISOString())
    }
    loadingChildComponents(){
      //this.modalComponent=UserAddComponent
    }
getPreco(mes:string,id_estudadente:string){
  var preco="0"


try{
  var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
    return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente;
   });
  if(pagamento[0].preco)
  {
  preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
  }
}
catch(ex){

}
   return preco
}


 async getAnos(){

  this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
     this.data=res;

     this.ano_avaliacoes=this.data.ano_avaliacoes
console.log( this.ano_avaliacoes)


  })
 }

 async getJSONEstudantes(e:any,anos:number){
  this.anoSelecionado=anos
  this.ngOnDestroy()
console.log(e.target.value)
this.ano_avaliacoes_id=parseInt(e.target.value)
  this.repo.getAll('/acessos/'+this.ano_avaliacoes_id).subscribe((res)=>{
     this.dataCandidaturas=res.data;
    // this.resumoPorCurso=res.resumocandidaturas
     this.dataTableInit()

     console.log(res)
     console.log(this.dataCandidaturas)
  })
  this.setTitle()
 }
 loadingData(){
  this.getMeses=this.meses

  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }

 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}


sum():number{
try{
   for(let i=0;i<this.totalElem.length;i++){
    this.total+=parseInt(this.totalElem[i])
    console.log("ELEM"+this.totalElem[i])
   }


}
catch(ex){

}
return this.total
}
gettotalElem(t:string):any{
  try{
    this.totalElem.push(parseInt(t))

  }
  catch(ex){

  }
  }

  getIdade(anoNascimento:string){
    let idade=0
    let anoCorrente=0
    try{
  anoCorrente=new Date().getFullYear()
    let anoAnte=anoNascimento.split('-')
     idade=anoCorrente-parseInt(anoAnte[0])
}
catch(ex:any){

}
return  idade
  }
  isTable(event:any){
    console.log(event)
    this.isChecked=event

  }
}
