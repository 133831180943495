

<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset>
                    <div class="form-row">



                        <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                          <label class="control-label">Ano Avaliação *</label>


                              <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                              {{anos.name}}


                      </div>
                      <div class="container-fluid">

                            <hr>
                            <button (click)=" generatePdf(PDFstudents)" class="btn btn-primary" *ngIf="isPDF">PDF</button>

                      </div>



                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<hr>
<fieldset  #PDFstudents  id="PDFstudents">
<div class="container body-year">
  <div class="PDF">
    <div class="header-pdf-year">
      <img src="./../../../../assets/images/ISPPM.jpg" alt="">
    </div>
    <div class="textheader">
      <h1 class="text-blue">Instituto Superior Politécnico Privado de Menongue</h1>
<h4 class="text-blue-white">Criado Pelo Decreto Presidencial nº 227/17 de 27 de Setembro</h4>
<hr>
<h2>SECRETARIA GERAL</h2>
<h4>RELATÓRIO ANUAL DE MATRÍCULAS</h4>
    </div>
  </div>

  <table    id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" hidden >
    <thead>
      <tr>
        <th width="5%">ID</th>
        <th width="5%">Ano lectivo</th>
        <th width="30%">Nome</th>
        <th width="30%">Curso</th>
        <th width="10%">Turma</th>
        <th width="10%">Período</th>
        <th width="10%">Data</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pag of dataCandidaturas index as i">
        <td >{{i+1}}</td>
        <td >{{pag?.anoavaliacao}}</td>
        <td >{{pag?.nome}}</td>
        <td >{{pag?.curso}}</td>
        <td >{{pag?.turma}}</td>
        <td >{{pag?.periodo}}</td>
        <td >{{pag?.created | date}}</td>
      </tr>

    </tbody>
  </table>
  <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>





  <div class="container">
    <hr>
    <div style="text-align: center;">
        <h2>{{totalEstudantes}} ESTUDANTES DISTRIBUÍDOS POR  - {{totalturmas}} TURMAS</h2>

    </div>
    <table class="table table-bordered table-striped">
      <thead width="100%">
        <tr>
          <th width="25%">Período</th>
          <th width="50%">Turma</th>

        <th width="25%">Total</th>
        </tr>
      </thead>
      <tr  *ngFor="let resT of studentsResumoTurmas">
        <td>{{resT?.periodo}}</td>
        <td>{{resT?.turma}}</td>

        <td>{{resT?.total }} </td>
      </tr>
    </table>
    <div style="text-align: center;">
      <h2>{{totalEstudantes}} ESTUDANTES DISTRIBUÍDOS POR  - {{totalCursos}} CURSOS</h2>

  </div>
    <table class="table table-bordered table-striped">
      <thead width="100%">
        <tr>
          <th width="50%">Curso</th>

        <th width="25%">Total</th>
        </tr>
      </thead>
      <tr  *ngFor="let res of resumoPorCurso">
        <td>{{res?.curso}}</td>

        <td>{{res?.total }} </td>
      </tr>
    </table>
  </div>

<p>MENONGUE, __________ DE _____________ DE _______________________</p>
<p>PROCESSADO POR COMPUTADOR {{dataHoje|date}}</p>
<div style="text-align: center;">
  <strong>A SECRETARIA</strong><br>

_________________________________

</div>
</div>
</fieldset>
