import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-docs',
  templateUrl: './header-docs.component.html',
  styleUrls: ['./header-docs.component.css']
})
export class HeaderDocsComponent implements OnInit {

  constructor() { }
@Input() data_header_mini_pauta:any
  ngOnInit(): void {
  }

}
