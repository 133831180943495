import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  roles=[
    {"id":"1","name":"user"},
    {"id":"2","name":"moderator"},
    {"id":"3","name":"admin"},
    {"id":"4","name":"student"},
    {"id":"5","name":"steacher"},
    {"id":"6","name":"secretary"},
    {"id":"7","name":"director"},
    {"id":"8","name":"daac"}
    ]
  n=''
  ano_avaliacao_id=0
  ano_avaliacoes:any
  edu_estudante_id=0
  dataEstudantes:any
 user_id=0
 userid=0
  dataUsers:any
  data:any
formUser={
  id:null,
  user_assoc:null,
  edu_estudante_id:null,
  username:null,
  email:null,
  password:null,
  roles:null
}

  constructor(private repo:HttpRepoService,private toastr: ToastrService,) { }


  ngOnInit(): void {
    this.getAnos()
   // this.getestuAssoc()
    this.getUsers()
  }
  async getUsers(){

    try{
      this.repo.getAll('/users-assoc').subscribe((res)=>{


        this.dataUsers=res.usersassoc



     })
    }
    catch(ex){

    }
   }

  async getestuAssoc(){

    try{
      this.repo.getAll('/estudante-assoc/'+this.ano_avaliacao_id).subscribe((res)=>{


        this.dataEstudantes=res.estudanteassoc



     })
    }
    catch(ex){
      this.toastr.error("Ocorreu um erro!","ERROR")

    }
   }

  async getSetAssocEdu(){

    try{
      this.toastr.success("Usuário adicionado com sucesso!","SUCCESS")

      if(this.formUser.user_assoc!=null)
      this.repo.Add({userid:this.userid,eduid:this.formUser.edu_estudante_id},'/set-assoc-edu').subscribe((res)=>{






     })
     else
     setTimeout(() => {
      window.location.reload()
    }, 3000);
    }
    catch(ex){

    }
   }

  async saveUser(){

    try{
      this.repo.Add(this.formUser,'/auth/signup').subscribe((res)=>{


        this.userid=parseInt(res?.id)
        if(res?.id!=0){
       this.toastr.success("Usuário adicionado com sucesso!","SUCCESS")
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      }
      else
      {this.toastr.error("Ocorreu um erro!","ERROR")}


     })
    }
    catch(ex){
      this.toastr.error("Ocorreu um erro!","ERROR")

    }
   }
   async getAnos(){

    try{
      this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
        this.data=res;

        this.ano_avaliacoes=this.data.ano_avaliacoes



     })
    }
    catch(ex){

    }
   }
add(){

}
}
