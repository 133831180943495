import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { UserAddComponent } from '../user-add/user-add.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  modalComponent:any
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  iconLink=''
  formUser={
    user_id:0,
    username:'',
    password:''
  }
  users:any
  constructor( private repo:HttpRepoService,private toastr: ToastrService,private auth:AuthService) { }
async loadUsers(){
  this.repo.getAll('/users').subscribe((res)=>{
    this.users=res;

    this.dataTableInit()
  })


}
  ngOnInit(): void {
    this.modalComponent=UserAddComponent
this.loadUsers()
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 3,
      lengthMenu: [ 3, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  assocEstudnate(userid:Number,edu_estudante_id:Number){

    try{
      this.toastr.success("Usuário adicionado com sucesso!","SUCCESS")

      this.repo.Add({userid:userid,eduid:edu_estudante_id},'/set-assoc-edu').subscribe((res)=>{






     })

    }
    catch(ex){

    }
  }

  async saveUser(){

    try{
      this.auth.change_pw_user(this.formUser.user_id,this.formUser.username,this.formUser.password).subscribe((res)=>{



        if(res.data){
       this.toastr.success("Usuário alterado com sucesso!","SUCCESS")
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      }
      else
      {this.toastr.error("Ocorreu um erro!","ERROR")}


     },((error: any) => {
       if(error.status==403){
        this.toastr.error("Permissão rejeitada!","NEGADO")
       }
     }))
    }
    catch(ex){
      this.toastr.error("Ocorreu um erro!","ERROR")

    }
   }
   edit(user:any){
    this.formUser.user_id=user.id
    this.formUser.username=user.username
   }
   async saveProlongar(){

    try{
      const data=new Date()
      data.setDate(data.getDate()+3)
      this.auth.p2FUser(data).subscribe((res)=>{



        if(res.data){
       this.toastr.success("  Efectuado com sucesso!","SUCCESS")
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      }
      else
      {this.toastr.error("Ocorreu um erro!","ERROR")}


     },((error: any) => {
       if(error.status==403){
        this.toastr.error("Permissão rejeitada!","NEGADO")
       }
     }))
    }
    catch(ex){
      this.toastr.error("Ocorreu um erro!","ERROR")

    }
   }
   async LimparBloqueios(){

    try{


      this.auth.clearUser(1).subscribe((res)=>{



        if(res.data){
       this.toastr.success("  Efectuado com sucesso!","SUCCESS")
      setTimeout(() => {
        window.location.reload()
      }, 3000);
      }
      else
      {this.toastr.error("Ocorreu um erro!","ERROR")}


     },((error: any) => {
       if(error.status==403){
        this.toastr.error("Permissão rejeitada!","NEGADO")
       }
     }))
    }
    catch(ex){
      this.toastr.error("Ocorreu um erro!","ERROR")

    }
   }
}
