
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">







                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>


          <th>Status</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dp of dataMes index as i">
          <td >{{i+1}}</td>
          <td >{{dp.name}}</td>
         <td contenteditable="true" (blur)="getValue($event,3,dp.id)">{{dp.status}}</td>


        </tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
