import { Injectable } from '@angular/core';

import {environment} from'./../../src/environments/environment'
@Injectable({
  providedIn: 'root'
})

export class API_URLService {

  constructor() { }
  getUrl(){
   var  sufix='api/v1'    //api_v1
    var http="https" //https
    var ip=""
var __ip=''
    var port=0
   if(environment.production==true){
    ip=environment.ip_prod
     port=environment.port_prod
     __ip=`${http}://${ip}/${sufix}`;
   }else{
    ip=environment.ip

     port=environment.port
     __ip=`${http}://${ip}:${port}/${sufix}`;
   }

console.log(__ip)
    return __ip
  }
  getHOST(){
    var  sufix='api/v1'    //api_v1
     var http="https" //https
     var ip=""

     var port=0
    if(environment.production==true){
     ip=environment.ip_prod
      port=environment.port_prod
    }else{
     ip=environment.ip

      port=environment.port
    }


     return `${http}://${ip}:${port}`;
   }
}
