import { Component, Input,OnInit } from '@angular/core';

@Component({
  selector: 'app-float-buttom',
  templateUrl: './float-buttom.component.html',
  styleUrls: ['./float-buttom.component.css']
})
export class FloatButtomComponent implements OnInit {

  constructor() { }
  @Input() ModalLink:any
  ngOnInit(): void {
    console.log(this.ModalLink)
  }

}
