import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MiniPautaService } from 'src/app/services/base/excel/mini-pauta.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';

@Component({
  selector: 'app-mini-pauta',
  templateUrl: './mini-pauta.component.html',
  styleUrls: ['./mini-pauta.component.css']
})
export class MiniPautaComponent implements OnInit {
  headrImage: any;
  sign='';
  minhasAssinaturas: any;
  user_id_docente= 0;
 
userSign={
  user_id:0,
  edu_disciplina_id:0,
 edu_sistema_avaliacao_id:0
}
pode_assinar=false
imageSign:any
  data_header_mini_pauta={
    ano_academico:'',
    curso:"",
    turma:"",
    periodo:"",
   disciplina:''
  }
  notas: any;
  notastmp: any;
  notastmpPorEstudante: any;
  edu_matriz_curriculares_disciplina_id=0
  edu_disciplina_id=0
  ano_avaliacao_id: any;
  pedido: any;
  edu_turma_id: any;

  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
lsitaEstudantes:any
edu_curso_id=0
isPut=5
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
putData:any


provaTipo=''
podeLancarNota:any
dispensaList=[
  {id:1,name:"Dispensa"},
  {id:2,name:"Não dispensa"}
]
  habilitarEdicao: boolean=false;
  dispensa: number=0


  constructor(private route: ActivatedRoute,private excel:MiniPautaService,private PDFService:ServicePDFjsPDFService,private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) {

   }

  ngOnInit(): void {


    this.route.params.subscribe(params => {
      const rota = params['rota'];
      const user_id = params['user_id'];
      const edu_turma_id = params['id_turma'];
      const id_disciplina = params['id_disciplina'];
      this.user_id_docente=user_id
      this.edu_turma_id=edu_turma_id
      this.edu_disciplina_id=id_disciplina




   this.dtOptions = {
    pagingType: 'full_numbers'
    , pageLength: 10000,
    lengthMenu: [ 25,50, 100, 1000],
     processing: true,
    dom: 'Bfrtip',
   // retrieve:true,
  }
  
this.  getBuscarNotas()

      // ... lógica adicional com os parâmetros ...
    });


    this.getAssinatura()
    //this.getSac()

  }
  getBuscarNotas(){
    this.getEstudantes()
  this.getNotas()
      // Use os parâmetros como necessário

  }

  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  notasPorTurm(ev:any,d:any){
    try{

      this.edu_matriz_curriculares_disciplina_id=d.find((di: { edu_disciplina_id: number; })=>di.edu_disciplina_id==ev)?.edu_matriz_curriculares_disciplinas_id

      this.habilitarEdicao=false
      this.notastmpPorEstudante=[]
      this.notas=[]
      this.notas=this.notastmp
     this.notastmpPorEstudante= this.notastmp.filter((n: { edu_disciplina_id: any; })=>n.edu_disciplina_id==this.edu_disciplina_id)


    }
    catch(ex:any){

    }
  }
  getNota(edu_matricula_id:Number){
    try{


      const data=this.notas.find((n: { edu_disciplina_id:number;edu_matricula_id:number; })=>n.edu_disciplina_id==this.edu_disciplina_id&&n.edu_matricula_id==edu_matricula_id)
////// console.log(data)
     return  data

     }
     catch(ex:any){

return {
  pp1:'--',
  pp2:'--',
  mac:'--',
  mga:'--',
  exame:'--',
  recurso:'--',
  exame_especial:'--',
  edit:false
}
     }
  }
  async getEstudantes(){

    try{
      this.repo.getAll('/estudantesdocentes/'+this.edu_turma_id).subscribe((res)=>{

        console.log("res",res)
        this.data_header_mini_pauta.curso=res?.Turmas?.curso
this.data_header_mini_pauta.turma=res?.Turmas?.turma
this.data_header_mini_pauta.periodo=res?.Turmas?.periodo


        const item = [...new Map(res.estudantes.map((item: { nome: any; }) => [item.nome, item])).values()]
        this.PredataEstudantes=item
     //   this.estudantesCadeirantes=res?.estudantesCadeirantes


     
        this.PredataEstudantes=item
        this.headrImage=res.image
       // console.log("ESTUDANTES:", this.PredataEstudantes)


     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getNotas(){

    try{
      this.repo.getAll('/lista-notas-daac/'+this.edu_turma_id+'/'+this.edu_disciplina_id+'/'+this.user_id_docente).subscribe((res)=>{
this.data_header_mini_pauta.ano_academico=this.PredataEstudantes[0]?.anoavaliacao
console.log("aqui.........",res)
this.data_header_mini_pauta.disciplina=res?.notas[0]?.disciplina
  this.notas=res.notas
  this.notastmp=this.notas
  this.lsitaEstudantes=this.PredataEstudantes
  this.dataTableInit()
  this.habilitarEdicao=true
this.data_header_mini_pauta.disciplina=this.disciplinas.find((d: { edu_disciplina_id: number; })=>d.edu_disciplina_id==this.edu_disciplina_id)?.disciplina ||''
    // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]



     })
    }
    catch(ex){
  console.log("aqui.........",ex)
    }
   }

      setAno(ano:string){
        alert(ano)
        this.data_header_mini_pauta.ano_academico=ano

      }
      async parecerDAAC(aprovado:number){

        try{
         // this.podeAssinar(this.ano_avaliacao_id,'sign')
          this.repo.Add({aprovado,user_id:this.user_id_docente,edu_turma_id:this.edu_turma_id,edu_disciplina_id:this.edu_disciplina_id},'/parecer-daac').subscribe((res)=>{


            this.getNotas()

         })
        }
        catch(ex){
         ////// console.log(ex)
        }
       }
       async dispensaDAAC(dispensa:number){

        try{
         // this.podeAssinar(this.ano_avaliacao_id,'sign')
          this.repo.Add({dispensa,user_id:this.user_id_docente,edu_turma_id:this.edu_turma_id,edu_disciplina_id:this.edu_disciplina_id},'/dispensa-daac').subscribe((res)=>{


            this.getNotas()

         })
        }
        catch(ex){
         ////// console.log(ex)
        }
       }
  async getDistribucaoTurmas(){

    try{
     // this.podeAssinar(this.ano_avaliacao_id,'sign')
      this.repo.getAll('/distribuicaoturmas/'+this.ano_avaliacao_id).subscribe((res)=>{


        this.distribuicaoturmas=res.distribuicaoturmas
     this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]
  ////// console.log( this.edu_cursos)


     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getAssinatura(){

    try{
     // this.podeAssinar(this.ano_avaliacao_id,'sign')
      this.repo.getAll('/minhas-assinaturas').subscribe((res)=>{

            if(res?.assinaturas?.length>0){
        this.minhasAssinaturas=res?.assinaturas
        this.sign=this.minhasAssinaturas[0]?.signature
      }
      else{
        this.pode_assinar=true
      }

     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getDisciplinasPorturma(){

    try{
      this.lsitaEstudantes=[]
      this.repo.getAll('/disciplinasdocente/'+this.ano_avaliacao_id+'/'+this.edu_turma_id).subscribe((res)=>{


        this.disciplinas=res.disciplinas

       this.getEstudantes()




     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   getTurmas(){
  try{
    this.edu_turmas= this.distribuicaoturmas.find((v: { edu_curso_id: string;})=>v.edu_curso_id==this.edu_curso_id.toString())

  }
  catch(ex){

  }
  }
   async getAnos(){

    try{
      this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
        this.data=res;

        this.ano_avaliacoes=this.data.ano_avaliacoes
  ////// console.log( this.ano_avaliacoes)


     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   initPaginate(){
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  async getIsPut(){

    try{
      this.repo.getAll('/sistemas').subscribe((res)=>{


        this.podeLancarNota=res.sistemas


     })
    }
    catch(ex){
     //////// console.log(ex)
    }
  }
  podeAssinar(ano_avaliacao_id:number,tipo:string){
    try{
      const tipoAutorizado=this.podeLancarNota.find((pL: { name: string;ano_avaliacao_id:number; })=>pL.name==tipo&&pL.ano_avaliacao_id==ano_avaliacao_id)?.id
      if(tipoAutorizado>0){
        this.pode_assinar=true
      }

    }
    catch(ex:any){

    }



    }
  edit(n:any,tipo:string){
  try{
    const tipoAutorizado=this.podeLancarNota.find((pL: { name: string; })=>pL.name==tipo)?.name+n?.id
    if(tipoAutorizado!=tipo+n?.id){
      this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
    }
  else{
    //verifcar edição ii
    if(! this.habilitarEdicao){
      this.toastr.error("Primeiro clique em consultar, pois, a disciplina foi alterada!")
    }
    else{
      n.isrow=tipo+n?.id
    }

  }
  }
  catch(ex:any){
   ////// console.log("ERRO SA:",ex.message)

    this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
  }



  }
  pautaPDF(){
    console.log("gg",this.data_header_mini_pauta)
    if(!this.notas){
      this.toastr.error("Consulte primeiro (preencha os campos:Ano Avaliação,Curso,Turma,Disciplina e Dispensa a cadeira?, depois clique no botão 'consultar notas'), por favor!")

  }
    else{

      let dispensa=''
      const isNota=this.notas.filter((d: { dispensa: number; })=>d.dispensa==1)

          if(isNota[0]?.dispensa==1){
            dispensa="dispensa"

          }
          else{

            dispensa="Não dispensa"
          }

    const data={
      lista:this.lsitaEstudantes,
      notas:this.notas,
      dispensa:dispensa,
      user:{name:this.notas[0]?.name,sign:this.sign}
    }

    this.PDFService.gerarPauta(this.headrImage,data,this.data_header_mini_pauta.disciplina,this.data_header_mini_pauta.curso,this.data_header_mini_pauta.ano_academico,this.data_header_mini_pauta.turma,this.data_header_mini_pauta.periodo)
  }
  }
  getNotaFinal(n:any){
    try{
      const mac=this.PDFService.calcularMF(n)
      return mac
          }
          catch(ex:any){
            return '--'
          }
  }
  calcularMGA(n:any){
    try{

return this.PDFService.calcularMGA(n)
    }
    catch(ex:any){
      return 0
    }
  }

  calcularRFINAL(n:any){
    try{
 return this.PDFService.calcularRFINAL(n)
    }
    catch(ex:any){
      return '-'
    }
  }

  calcularNF(n:any){
    try{
 return this.PDFService.calcularNF(n)
    }
    catch(ex:any){
      return '-'
    }
  }
  Obs(n:any){
    try{
 return this.PDFService.Obs(n)
    }
    catch(ex:any){
      return '-'
    }
  }
  simplesReprocao(n:any,sistema:string,estado:number){
    try{
      // this.podeAssinar(this.ano_avaliacao_id,'sign')
       this.repo.Add({id:n?.id,aprovado:estado,sistema:sistema},'/parecer-daac-simples').subscribe((res)=>{


         this.getNotas()

      })
     }
     catch(ex){
      ////// console.log(ex)
     }
  }
}
