import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { ToastrService } from 'ngx-toastr';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { ImageConversionService } from 'src/app/services/base/image-conversion.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad|any;

  signaturePadOptions = {

  minWidth: 2, // used for pen width after writing

  canvasWidth: 400, // for canvas width

  canvasHeight: 100, // for canvas height

  }
  image:any
  urlImage=''
  @Input() currentSign:any
  close: boolean=false;
  ngOnInit(): void {

  }
  constructor(private imageConversionService: ImageConversionService,private repo:HttpRepoService,private toastr: ToastrService) { }

  drawComplete(): void {

  }

  clearSignature(): void {

  this.signaturePad.clear();

  }

  drawStart(): void {

  // will be notified of szimek/signature_pad’s onBegin event

  }
  save(): void {

    const base64 = this.signaturePad.toDataURL();
    this.add(base64)

    this.imageConversionService.convertToImage(base64).subscribe(image => {

    const img = image;
this.image
 ;

    });

    }
add(base64:string){
 const userSign={
  id:this.currentSign?.id||0,
    user_id:0,
    edu_disciplina_id:this.currentSign?.edu_disciplina_id,
   edu_sistema_avaliacao_id:this.currentSign?.edu_sistema_avaliacao_id,
   signature:base64
  }
  this.salvar(userSign)
}
salvar(data:any){

  try{
    this.repo.Add(data,'/salvar-assinatura').subscribe((res)=>{

if(res.data){
this.toastr.success("Assinatura salva!")
this.close=true
window.location.reload()
}else{
this.toastr.error(res.message)
}


  // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]



   })
  }
  catch(ex){
   ////// console.log(ex)
    this.toastr.error("Umerro sucedeu!")
  }
 }

}
