import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ImageConversionService {

  constructor() { }


  convertToImage(base64:any): Observable<File> {

    return Observable.create((observer: { next: (arg0: File) => void; }) => {

    const img = this.createImage(base64);

    setTimeout(() => {

    const elem = document.createElement('canvas');

    const ctx = elem.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(img, 0, 0, 400, 100);

    ctx.canvas.toBlob(

    blob => {

    observer.next(

    new File([blob as BlobPart], 'Digital Signature.png', {

    type: 'image/png',

    lastModified: Date.now(),

    }),

    );

    },

    'image/png',

    );

    });

    });

    }
  createImage(ev:any) {

    const imageContent = ev;

    const img = new Image();

    img.src = imageContent;

    return img;

    }
}
