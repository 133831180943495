import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nao-devedores',
  templateUrl: './nao-devedores.component.html',
  styleUrls: ['./nao-devedores.component.css']
})
export class NaoDevedoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
