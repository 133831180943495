

<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset> <legend class="fildset" style="text-align: center; ">Selecione o ano lectivo para visualizar as respectivas disciplinas.</legend>
                    <hr>
                    <div class="form-row">



                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Ano Avaliação *</label>
                          <ng-select [(ngModel)]="ano_avaliacao_id" (change)="getDistribucaoTurmas()">
                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                          </ng-select>
                      </div>




                              <hr>
                     <fieldset>


                     </fieldset>





                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid">

  <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" style="width: 100%;">
    <thead>
      <tr>
        <th with="10%">ID</th>
        <th with="25%">DOCENTE</th>
      <th with="25%">CURSO</th>
      <th with="25%">DISCIPLINA</th>
      <th with="25%">TURMA</th>
      </tr>


    </thead>
    <tbody>

      <tr *ngFor="let n of docentes">
        <td >{{n.id}}</td>
        <td style="cursor: pointer;" (click)="verDisciplinas(n.edu_docente_id)" data-toggle="modal" data-target="#ModalShow"> {{n.nome}}</td>
        <td>{{n.curso}}</td>
        <td>{{n.disciplina}}</td>
        <td>{{n.turma}}</td>


      </tr>

    </tbody>
  </table>
</div>












<!-- The Modal -->
<div class="modal" id="ModalShow">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h1 class="modal-title text-white">+</h1>
        <button type="button" class="close" id="btnModalClose" data-dismiss="modal">×</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th with="10%">ID</th>
              <th with="25%">DOCENTE</th>
            <th with="25%">CURSO</th>
            <th with="25%">DISCIPLINA</th>
            <th with="25%">TURMA</th>
            <th with="25%">ACAO </th>

            </tr>


          </thead>
          <tbody>
            <tr *ngFor="let n of disciplinasDocentes">
              <td >{{n.id}}</td>
              <td (click)="verDisciplinas(n.edu_docente_id)"> {{n.nome}}</td>
              <td>{{n?.curso}}</td>
              <td>{{n?.disciplina}}</td>
              <td>{{n?.turma}}</td>

              <td>
                <button type="button" class="close" data-dismiss="modal" (click)="verPauta('aprovacaodepautas', n?.user_id, n?.edu_turma_id, n?.id_disciplina)">
                  <i class="fa fa-eye"></i>
                </button>
              </td>


            </tr>

          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
  </div>












