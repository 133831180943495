
<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset>
                    <div class="form-row">



                        <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                          <label class="control-label">Ano Avaliação *</label>


                              <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                              {{anos.name}}


                      </div>
                      <div class="container-fluid">

                            <hr>
                      <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                      </div>



                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<hr>

<!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" *ngFor="let c of cursos">

  <thead>
    <tr>
      <th>Turma</th>
      <th>Curso</th>
      <th *ngFor="let mes of meses">{{ mes }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let p of previsaoFinalPorCurso1(c.name)?.data">
      <td>{{p?.turma}}</td>
      <td>{{c?.name}}</td>
      <td *ngFor="let mesC of meses">{{previsaoFinalPorCurso(p,c.name)?.outubro | number:'1.2-2'}} </td>
    </tr>
  </tbody>
</table>



<hr>
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive">

  <thead>
    <tr>
      <th>Nível</th>

      <th *ngFor="let mes of meses">{{ mes }}</th>
    </tr>
  </thead>
  <tbody>
   <tr *ngFor="let n of niveis">
      <td>{{n.nivel}}</td>
      <td>{{byLevel(n.nivel)?.outubro| number:'1.2-2'}} - <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.outubro,getPago(n.nivel)?.outubro) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.novembro| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.novembro,getPago(n.nivel)?.novembro) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.dezembro| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.dezembro,getPago(n.nivel)?.dezembro) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.janeiro| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.janeiro,getPago(n.nivel)?.janeiro) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.fevereiro| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.fevereiro,getPago(n.nivel)?.fevereiro) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)['março']| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)['março'],getPago(n.nivel)?.marco) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.abril| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.abril,getPago(n.nivel)?.abril) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.maio| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.maio,getPago(n.nivel)?.maio) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.junho| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.junho,getPago(n.nivel)?.junho) |number:'1.2-2'}}</span></td>
      <td>{{byLevel(n.nivel)?.julho| number:'1.2-2'}} <span style="color: red;"> {{ getPagoDif(byLevel(n.nivel)?.julho,getPago(n.nivel)?.julho) |number:'1.2-2'}}</span></td>

   </tr>
  </tbody>
</table>

