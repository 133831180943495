<div class="row">
  <!-- Your Profile Views Chart -->
  <div class="col-lg-12 m-b30">
    <div class="widget-box">
      <div class="wc-title">
        <h4>Adiconar novo Usuário</h4>
      </div>
      <div class="widget-inner">
        <form class="edit-profile m-b30">
          <div class="row">
            <div class="col-12">
              <div class="ml-auto">
                <h3>1. Informações  Básicas do Usuário</h3>
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-form-label">Usuário</label>
              <div>

                <input   class="form-control" type="text"  placeholder="Nome do Usuário" [(ngModel)]="formUser.username" name="username">
              </div>
            </div>
            <div class="form-group col-6">
              <label class="col-form-label">Email</label>
              <div>
                <input class="form-control" type="text" value="" placeholder="Email do usuário" [(ngModel)]="formUser.email" name="email">
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-form-label">Password</label>
              <div>
                <input class="form-control" type="password"  [(ngModel)]="formUser.password" name="password">
              </div>
            </div>


            <div class="seperator"></div>


            <div class="col-12 m-t20">
              <div class="ml-auto">
                <h3 class="m-form__section">3. Permissões</h3>
              </div>
            </div>
            <div class="col-12">
              <table id="item-add" style="width:100%;">
                <tr class="list-item">
                  <td>
                    <div class="row">
                      <div class="col-md-2">
                        <label class="col-form-label">Grupos</label>
                        <div>
                          <ng-select [(ngModel)]="formUser.roles" name="roles">

                            <ng-option *ngFor="let role of roles" [value]="role?.id" >{{role?.name}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label class="col-form-label">{{ano_avaliacao_id}}Ano de avaliação</label>
                        <div>
                          <ng-select [(ngModel)]="ano_avaliacao_id" name="ano_avaliacao_id" (change)="getestuAssoc()">


                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="col-form-label">Cód.Estudante(Apenas para estudantes)</label>
                        <div>
                          <ng-select [(ngModel)]="formUser.edu_estudante_id" name="edu_estudante_id">

                            <ng-option *ngFor="let estudante of dataEstudantes" [value]="estudante?.edu_estudante_id" >{{estudante?.name}}</ng-option>
                          </ng-select>

                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="col-form-label">Cód. User Docente(Apenas para os docentes)</label>
                        <div>
                          <ng-select [(ngModel)]="formUser.user_assoc" name="user_assoc">
                            <ng-option >Selecione o Usuário</ng-option>
                            <ng-option *ngFor="let user of dataUsers" [value]="user?.id" >{{user?.name}}</ng-option>
                          </ng-select>

                        </div>
                      </div>

                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-12 mt-4">
              <button type="button" class="btn-secondry add-item m-r5" (click)="saveUser()"><i class="fa fa-fw fa-plus-circle"></i>Save changes</button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Your Profile Views Chart END-->
</div>
