import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { error, time } from 'console';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MiniPautaService } from 'src/app/services/base/excel/mini-pauta.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';

@Component({
  selector: 'app-lanacar-notas',
  templateUrl: './lanacar-notas.component.html',
  styleUrls: ['./lanacar-notas.component.css']
})
export class LanacarNotasComponent implements OnInit {
  headrImage: any;
  sign='';
  minhasAssinaturas: any;
  estudantesCadeirantes: any;
changeDispensa($event: any) {
throw new Error('Method not implemented.');
}
userSign={
  user_id:0,
  edu_disciplina_id:0,
 edu_sistema_avaliacao_id:0
}
pode_assinar=false
imageSign:any
  data_header_mini_pauta={
    ano_academico:'',
    curso:"",
    turma:"",
    periodo:"",
   disciplina:''
  }
  unidades=[
    {id:1,name:"Iª Semestre"},
    {id:2,name:"IIª Semestre"}
  ]
  unidade_id=0
  notas: any;
  notastmp: any;
  notastmpPorEstudante: any;
  edu_matriz_curriculares_disciplina_id=0
  edu_disciplina_id=0
  ano_avaliacao_id: any;
  pedido: any;
  edu_turma_id: any;

  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
lsitaEstudantes:any
edu_curso_id=0
isPut=5
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
putData:any


provaTipo=''
podeLancarNota:any
dispensaList=[
  {id:1,name:"Dispensa"},
  {id:2,name:"Não dispensa"}
]
  habilitarEdicao: boolean=false;
  dispensa: number=0


  constructor(private excel:MiniPautaService,private PDFService:ServicePDFjsPDFService,private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) { }

  ngOnInit(): void {
    this.initPaginate()
//this.excel.mini_pauta()
    this.user=this.authTorage.getUser()
  this.user_id=parseInt(this.user.user_assoc)
    this.getAnos()
    //this.getIsPut()

    this.getAssinatura()
    //this.getSac()
    this.toastr.warning("Preencha devidamente os campos necessários! Pois, aqui o docente irá importar as notas usando um ficheiro modelo fornecido pelo DAAC.", '!');

  }

  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  notasPorTurm(ev:any,d:any){
    try{

      this.edu_matriz_curriculares_disciplina_id=d.find((di: { edu_disciplina_id: number; })=>di.edu_disciplina_id==ev)?.edu_matriz_curriculares_disciplinas_id

      this.habilitarEdicao=false
      this.notastmpPorEstudante=[]
      this.notas=[]
      this.notas=this.notastmp
     this.notastmpPorEstudante= this.notastmp.filter((n: { edu_disciplina_id: any; })=>n.edu_disciplina_id==this.edu_disciplina_id)


    }
    catch(ex:any){

    }
  }
  getNota(edu_matricula_id:Number){
    try{


      const data=this.notas.find((n: { edu_disciplina_id:number;edu_matricula_id:number; })=>n.edu_disciplina_id==this.edu_disciplina_id&&n.edu_matricula_id==edu_matricula_id)
////// console.log(data)
     return  data

     }
     catch(ex:any){

return {
  pp1:'--',
  pp2:'--',
  mac:'--',
  mga:'--',
  exame:'--',
  recurso:'--',
  exame_especial:'--',
 
  edit:false
}
     }
  }
  getCadeiras(edu_estudante_id:number){
try{
const c=this.estudantesCadeirantes.filter((c: { edu_estudante_id: number; })=>c.edu_estudante_id==edu_estudante_id)
console.log("DEF:",c)
return c?.length
}catch(ex){
return 0
}
  }
  async getEstudantes(){

    try{
      this.repo.getAll('/estudantesdocentes/'+this.edu_turma_id).subscribe((res)=>{

        const item = [...new Map(res.estudantes.map((item: { nome: any; }) => [item.nome, item])).values()]
        this.PredataEstudantes=item
        this.estudantesCadeirantes=res?.estudantesCadeirantes


        console.log("res",res)
        //this.PredataEstudantes=res.estudantes
        this.headrImage=res.image
        //console.log("ESTUDANTES:", this.PredataEstudantes)




     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getNotas(){

    try{

      this.repo.getAll('/lista-notas/'+this.edu_turma_id+'/'+this.edu_disciplina_id).subscribe((res)=>{
this.data_header_mini_pauta.ano_academico=this.PredataEstudantes[0]?.anoavaliacao
this.data_header_mini_pauta.curso=this.PredataEstudantes[0]?.curso
//this.data_header_mini_pauta.turma=this.PredataEstudantes[0]?.turma
this.data_header_mini_pauta.periodo=this.PredataEstudantes[0]?.periodo
 
  this.notas=res.notas
  this.notastmp=this.notas
  this.lsitaEstudantes=this.PredataEstudantes
  this.habilitarEdicao=true
this.data_header_mini_pauta.disciplina=this.disciplinas.find((d: { edu_disciplina_id: number; })=>d.edu_disciplina_id==this.edu_disciplina_id)?.disciplina ||''
    // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]

this.dataTableInit()

     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   salvar(data:any){

    try{
      this.repo.Add(data,'/salvar-nota').subscribe((res)=>{

if(res.notas){
  this.notas=res.notas
this.toastr.success("Noata salva, Aguarde ao feed back do DAAC!")
}else{
  this.toastr.error(res.message)
}


    // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]



     })
    }
    catch(ex){
     ////// console.log(ex)
      this.toastr.error("Umerro sucedeu!")
    }
   }


  lancar_nota(ev:any,tipo_prova:string,n:any,estudante:any){
try{
let id_nota=0
const nota=parseFloat(ev.target.value)
if(n!=undefined){

  id_nota=n.id
  //Calcualr média geral
 if(n?.pp1){
  const mga=(n?.pp1+n?.pp2+n?.mac)/3
  const mf=(n?.pp1+n?.pp2+n?.mac)/3
  n.mga=mga.toFixed(1)
}

}
//Verificar se a nota é valida
if(nota < 0 || nota>20){
  throw new Error("A nota deve estar entre 0 e 20!")
}
else if((nota < 0 || nota > 10) && (tipo_prova === 'recurso' || tipo_prova === 'exame_especial')){
  throw new Error("A nota do Recurso ou exame especial deve estar entre 0 e 10!"+ ev.target.value)
}
//PASSR VALOR AO SISTEMA DE AVALIAÇÃO

if(n?.pp1){
  n[tipo_prova]=nota
}

 let data=this.setSistema(nota,tipo_prova,id_nota,estudante)
 this.salvar(data)
////// console.log(data)
 estudante.isrow=''


}
catch(ex:any){
console.log("ERRO N:",ex.message)
this.toastr.error(`Messangem:Ocorreu um erro, informe valores corretos!
                  - Siga as instruções;
                  - A nota deve estar entre 0 e 20!;
                  - A nota para o Exame de Recurso/Especial deve estar entre 0 e 10!;

                  Se ainda assim persistir, contacte o administrador.`)
}
  }
  setSistema(nota:any,tipo_prova:string,id_nota:number,estudante:any){
    try{
    /*  if(this.edu_disciplina_id!=n?.edu_disciplina_id){

        n.edu_disciplina_id=this.edu_disciplina_id
      }*/
      let data:any
      data=  {
        id:id_nota,
        edu_matricula_id:estudante?.id,
        edu_matriz_curriculares_disciplina_id:this.edu_matriz_curriculares_disciplina_id,
        edu_turma_id:this.edu_turma_id,
        edu_estudante_id:estudante?.edu_estudante_id,
        edu_disciplina_id:this.edu_disciplina_id,
        ano_avaliacao_id:this.ano_avaliacao_id,
        pedido:this.edu_disciplina_id+ new Date().getTime(),
        nota:nota,
        exame_especial:0,
        user_id:this.user_id,
        resultado_final:'',
        situacao_academica:'',
        sistema:tipo_prova,
        dispensa:this.dispensa,
        unidade:this.unidade_id,
      }
     ////// console.log(data)
    return data

    }
    catch(ex){
    return null
    }
      }
      setAno(ano:string){
        alert(ano)
        this.data_header_mini_pauta.ano_academico=ano

      }
  async getDistribucaoTurmas(){

    try{
     // this.podeAssinar(this.ano_avaliacao_id,'sign')
      this.repo.getAll('/distribuicaoturmas/'+this.ano_avaliacao_id).subscribe((res)=>{


        this.distribuicaoturmas=res.distribuicaoturmas
     this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]
  ////// console.log( this.edu_cursos)


     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getAssinatura(){

    try{
     // this.podeAssinar(this.ano_avaliacao_id,'sign')
      this.repo.getAll('/minhas-assinaturas').subscribe((res)=>{

            if(res?.assinaturas?.length>0){
        this.minhasAssinaturas=res?.assinaturas
        this.sign=this.minhasAssinaturas[0]?.signature
      }
      else{
        this.pode_assinar=true
      }

     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   async getDisciplinasPorturma(edu_turma_id:number){

    try{
      const turma=this.distribuicaoturmas.find((t: { id: number; })=>t.id==edu_turma_id)
      console.log("TRUEMA.........",turma)
      this.data_header_mini_pauta.turma=turma?.name
      this.lsitaEstudantes=[]
      this.repo.getAll('/disciplinasdocente/'+this.ano_avaliacao_id+'/'+edu_turma_id).subscribe((res)=>{


        this.disciplinas=res.disciplinas

     this.getEstudantes()




     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   getTurmas(){
  try{
    this.edu_turmas= this.distribuicaoturmas.find((v: { edu_curso_id: string;})=>v.edu_curso_id==this.edu_curso_id.toString())

  }
  catch(ex){

  }
  }
   async getAnos(){

    try{
      this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
        this.data=res;

        this.ano_avaliacoes=this.data.ano_avaliacoes
  ////// console.log( this.ano_avaliacoes)


     })
    }
    catch(ex){
     ////// console.log(ex)
    }
   }
   initPaginate(){
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  async getIsPut(ano_avaliacao_id:number){

    try{
      if(this.unidade_id <=0){
        this.toastr.error("Por favor, selecione a unidade!")
      }else{


      this.repo.getAll('/sistemas/?ano_avaliacao_id='+ano_avaliacao_id+'&unidade_id='+this.unidade_id).subscribe((res)=>{

        console.log("SISTEMAS:",res.sistemas)
        this.podeLancarNota=res.sistemas


     })

    }
    }
    catch(ex){
     //////// console.log(ex)
    }
    this.getDistribucaoTurmas()
  }
  podeAssinar(ano_avaliacao_id:number,tipo:string){
    try{
      const tipoAutorizado=this.podeLancarNota.find((pL: { name: string;ano_avaliacao_id:number; })=>pL.name==tipo&&pL.ano_avaliacao_id==ano_avaliacao_id)?.id
      if(tipoAutorizado>0){
        this.pode_assinar=true
      }

    }
    catch(ex:any){

    }



    }
  edit(n:any,tipo:string,notaEstudante:any){
  try{
    if(notaEstudante?.aprovado==2){
      n.isrow=tipo+n?.id
    }
    else if(notaEstudante?.aprovado==1){
      this.toastr.error("Esta Mini pauta foi aprovada, Não é possivel alterar!")
    }

    else if(this.notas.find((n: { aprovado: number; })=>n.aprovado==1)?.aprovado==1){
      this.toastr.error("Esta Mini pauta foi aprovada, Não é possivel alterar!")
    }
    else{


    const tipoAutorizado=this.podeLancarNota.find((pL: { name: string; })=>pL.name==tipo)?.name+n?.id
    if(tipoAutorizado!=tipo+n?.id){
      this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
    }

  else{
    //verifcar edição ii
    if(! this.habilitarEdicao){
      this.toastr.error("Primeiro clique em consultar, pois, a disciplina foi alterada!")
    }
    else{
      if(this.Obs(this.getNota(n.id))=='REPROVADO/A' && tipo=='recurso'){
        this.toastr.error("Este estudante está "+this.Obs(this.getNota(n.id))+",portanto, não se pode efetuar o lançamento de seu recurso!")
      }
     else{
      n.isrow=tipo+n?.id
     }
    }

  }
  //end if nota
}
  }
  catch(ex:any){
   ////// console.log("ERRO SA:",ex.message)

    this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
  }



  }
  pautaPDF(){
    if(!this.notas){
      this.toastr.error("Consulte primeiro (preencha os campos:Ano Avaliação,Curso,Turma,Disciplina e Dispensa a cadeira?, depois clique no botão 'consultar notas'), por favor!")

  }
    else{

      let dispensa=''
      const isNota=this.notas.filter((d: { dispensa: number; })=>d.dispensa==1)

          if(isNota[0]?.dispensa==1){
            dispensa="dispensa"

          }
          else{

            dispensa="Não dispensa"
          }

    const data={
      lista:this.lsitaEstudantes,
      notas:this.notas,
      dispensa:dispensa,
      user:{name:this.notas[0]?.name,sign:this.sign}
    }

    this.PDFService.gerarPauta(this.headrImage,data,this.data_header_mini_pauta.disciplina,this.data_header_mini_pauta.curso,this.data_header_mini_pauta.ano_academico,this.data_header_mini_pauta.turma,this.data_header_mini_pauta.periodo)
  }
  }
  getNotaFinal(n:any){
    try{
      const mac=this.PDFService.calcularMF(n)
      return mac
          }
          catch(ex:any){
            return '--'
          }
  }
  calcularMGA(n:any){
    try{

return this.PDFService.calcularMGA(n)
    }
    catch(ex:any){
      return 0
    }
  }

  calcularRFINAL(n:any){
    try{
 return this.PDFService.calcularRFINAL(n)
    }
    catch(ex:any){
      return '-'
    }
  }

  calcularNF(n:any){
    try{
 return this.PDFService.calcularNF(n)
    }
    catch(ex:any){
      return '-'
    }
  }
  Obs(n:any){
    try{
 return this.PDFService.Obs(n)
    }
    catch(ex:any){
      return '-'
    }
  }
}
