import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorialsListComponent } from './components/tutorials-list/tutorials-list.component';
import { TutorialDetailsComponent } from './components/tutorial-details/tutorial-details.component';
import { AddTutorialComponent } from './components/add-tutorial/add-tutorial.component';
import { GlobalReporterComponent } from './components/finance/global-reporter/global-reporter.component';
import { LoginComponent } from './components/auth/login/login.component';
import { UsersComponent } from './components/base/users/users/users.component';
import { DashboardComponent } from './components/base/dashboard/dashboard.component';
import { CandindaturasComponent } from './components/edu/candindaturas/candindaturas.component';
import { EstudantesComponent } from './components/edu/estudantes/estudantes.component';
import { SignatureComponent } from './components/base/signature/signature.component';
import { PagamentosComponent } from './components/finance/pagamentos/pagamentos.component';
import { NotasComponent } from './components/estudantes/notas/notas.component';
import { DocentesComponent } from './components/docentes/docentes.component';
import { SituacaofinanceiraComponent } from './components/estudantes/situacaofinanceira/situacaofinanceira.component';
import { UserAddComponent } from './components/base/users/user-add/user-add.component';
import { NewComponent } from './components/base/users/new/new.component';
import { VetrinaDigitalComponent } from './components/base/vetrina-digital/vetrina-digital.component';
import { HabilitarLanacamentoNotasComponent } from './components/base/habilitar-lanacamento-notas/habilitar-lanacamento-notas.component';
import { GuardsGuard } from './services/auth/authentication/guards.guard';
import { PageNotFoundComponent } from './_shared/page-not-found/page-not-found.component';
import { MescobrancaComponent } from './components/base/mescobranca/mescobranca.component';
import { PedidosPautasComponent } from './components/edu/pedidos-pautas/pedidos-pautas.component';
import { AprovacaoPautasComponent } from './components/edu/aprovacao-pautas/aprovacao-pautas.component';
import { PaymentByYerComponent } from './components/finance/payment-by-yer/payment-by-yer.component';
import { EstudantesPdfComponent } from './components/edu/estudantes/estudantes-pdf/estudantes-pdf.component';
import { LogoffComponent } from './components/auth/logoff/logoff.component';
import { MensalidadesPorTurmaComponent } from './app/components/finance/mensalidades-por-turma/mensalidades-por-turma.component';
import { LanacarNotasComponent } from './app/components/finance/lanacar-notas/lanacar-notas.component';
import { MinhasDisciplinasDocentesComponent } from './app/components/minhas-disciplinas-docentes/minhas-disciplinas-docentes.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { RecupreacaPautaComponent } from './components/daac/pautas/recupreaca-pauta/recupreaca-pauta.component';
import { DevedoresComponent } from './components/finance/devedores/devedores.component';
import { NaoDevedoresComponent } from './components/finance/nao-devedores/nao-devedores.component';
import { DocentesTurmasComponent } from './components/daac/docentes-turmas/docentes-turmas.component';
import { MiniPautaComponent } from './components/daac/mini-pauta/mini-pauta.component';
import { PrevisaoComponent } from './components/finance/previsao/previsao.component';

const routes: Routes = [
  { path: '', redirectTo: 'vetrina-digital', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'change-pw', component: ChangePasswordComponent, canActivate: [GuardsGuard] , data: { myUrl: 'change-pw'}},
  { path: 'sair', component: LogoffComponent },
  { path: 'dasboard', component: DashboardComponent,   canActivate: [GuardsGuard], data: { myUrl: 'dasboard'}},
  { path: 'recuperacao-notas', component: RecupreacaPautaComponent,   canActivate: [GuardsGuard], data: { myUrl: 'recuperacao-notas'}},
  //admin
  { path: 'users', component: UsersComponent,  canActivate: [GuardsGuard], data: { myUrl: 'users'}},
  { path: 'user-add', component: NewComponent,  canActivate: [GuardsGuard], data: { myUrl: 'user-add'} },

  //parte finceira
  { path: 'paymentsmonth', component: GlobalReporterComponent ,  canActivate: [GuardsGuard], data: { myUrl: 'paymentsmonth'}},
  { path: 'payments', component: PagamentosComponent,  canActivate: [GuardsGuard], data: { myUrl: 'payments'} },
  { path: 'paymentsbymonths', component: MensalidadesPorTurmaComponent,  canActivate: [GuardsGuard], data: { myUrl: 'paymentsbymonths'} },
  { path: 'paymentspdf', component: PaymentByYerComponent,  canActivate: [GuardsGuard], data: { myUrl: 'paymentspdf'}
},
{ path: 'devedores', component: DevedoresComponent,  canActivate: [GuardsGuard] , data: { myUrl: 'devedores'}},
{ path: 'projeccao-financeira', component: PrevisaoComponent,  canActivate: [GuardsGuard], data: { myUrl: 'projeccao-financeira'} },

  //edu

  { path: 'candindaturas', component: CandindaturasComponent,   canActivate: [GuardsGuard], data: { myUrl: 'candindaturas'} },
  { path: 'students', component: EstudantesComponent,   canActivate: [GuardsGuard], data: { myUrl: 'students'} },
  { path: 'students-pdf', component: EstudantesPdfComponent,   canActivate: [GuardsGuard], data: { myUrl: 'students-pdf'} },

  //{ path: 'aprovacaodepautas/:pedido/:id_turma/:id_disciplina', component: AprovacaoPautasComponent,   canActivate: [GuardsGuard] },
  { path: 'aprovacaodepautas/:user_id/:id_turma/:id_disciplina', component: MiniPautaComponent,   canActivate: [GuardsGuard], data: { myUrl: 'aprovacaodepautas' } },
  { path: 'pedidos-pautas', component: PedidosPautasComponent,   canActivate: [GuardsGuard], data: { myUrl: 'pedidos-pautas'} },
  { path: 'docente-turmas', component: DocentesTurmasComponent,   canActivate: [GuardsGuard],
  data: { myUrl: 'docente-turmas' } },
  //{ path: 'signature', component: SignatureComponent ,   canActivate: [GuardsGuard]},

 //estudantes
 { path: 'notasestudante', component: NotasComponent ,   canActivate: [GuardsGuard], data: { myUrl: 'notasestudante'}},
 {path:'situacao-financeira',component:SituacaofinanceiraComponent,   canActivate: [GuardsGuard], data: { myUrl: 'situacao-financeira'}},
 //Docentes
 { path: 'notasdocentes', component: LanacarNotasComponent ,   canActivate: [GuardsGuard], data: { myUrl: 'notasdocentes'}},
 { path: 'minhas-disciplinas', component: MinhasDisciplinasDocentesComponent ,   canActivate: [GuardsGuard], data: { myUrl: 'minhas-disciplinas'}},
 //geral
 {path:'vetrina-digital',component:VetrinaDigitalComponent,   canActivate: [GuardsGuard], data: { myUrl: 'vetrina-digital'}},
 {path:'habilitar-lancamento-notas',component:HabilitarLanacamentoNotasComponent,   canActivate: [GuardsGuard], data: { myUrl: 'habilitar-lancamento-notas'}}
 ,
 {path:'mesdecobranca',component:MescobrancaComponent,   canActivate: [GuardsGuard], data: { myUrl: 'mesdecobranca'}}
 ,
{
  path: 'error',
  component: PageNotFoundComponent
},

{
  path: '**',
  component: PageNotFoundComponent
},
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{relativeLinkResolution:'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
