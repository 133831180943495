<div class="fab"  ontouchstart="" >

  <button class="main"  data-toggle="modal" data-target="#ModalAdd">

  </button>
<ul>
  <li >
    <!--
    <label for="opcao1">Opção 1</label>
    <button id="opcao1" >
    ⎈
    </button>
  </li>
  <li>
    <label for="opcao2">Opção 2</label>
    <button id="opcao2">
    ⎗
    </button>
  </li>
  <li>
    <label for="opcao3">Opção 3</label>
    <button id="opcao3">
    ☏
    </button>
  </li>-->
</ul>
</div>

















