import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent implements OnInit {
  formUser={
    id:null,
    user_assoc:null,
    username:null,
    email:null,
    password:null,
    roles:null
  }
  username='hh'
  constructor() { }

  ngOnInit(): void {
  }
add(ev:any){
  alert(ev.target.value)
}
}
