
    <div class="row formavlidation-wrapper  " hidden>
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">


                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

<div class="container">
  <fieldset>
    <div class="form-row">
      <div class="col-md-2 col-sm-12">
        <label class="control-label">Curso*</label>
        <ng-select [(ngModel)]="edu_curso_id" (change)="getsistemaAvalicao()">
          <ng-option *ngFor="let c of cursos" [value]="c?.edu_curso_id">{{c?.curso}}</ng-option>
        </ng-select>
    </div>
    <div class="col-md-2 col-sm-12">
      <label class="control-label">Anos*</label>
      <ng-select [(ngModel)]="ano_id" (change)="getDisciplina()">
        <ng-option *ngFor="let a of anos" [value]="a?.edu_ano_id">{{a?.ano}}</ng-option>
      </ng-select>
  </div>
      <div class="col-md-2 col-sm-12">
        <label class="control-label">Turmas*</label>
        <ng-select [(ngModel)]="edu_turma_id" >
          <ng-option *ngFor="let t of turmas" [value]="t?.edu_turma_id">{{t?.name}}</ng-option>
        </ng-select>
    </div>


    </div>
      <div class="container-fluid">

            <hr>
      <button class="btn btn-primary" (click)="getSituacaofinanceira()"  title="Carregar">Carregar</button>

      </div>





  </fieldset>
<hr>
  <div class="row">
    <div class="col-md-4" *ngFor="let t of comercialDoc">
        <div class="card">
            <div class="card-body">
              <div class="card-body">
                <h5 class="card-title">Transação #{{t?.numero_ordem}}</h5>
                <p class="card-text">Data: <strong>{{t?.data_emissao | date}}</strong></p>
                <p class="card-text">Tipo: </p>
                <p class="card-text">Valor: AKz <strong> {{t?.total | number:'1.2-2'}}</strong></p>
                <p class="card-text">Operção: <strong><span *ngIf="t?.fanalizado==1" class="text-sucess" style="color: chartreuse;">Completa {{t?.fanalizado}}</span><span *ngIf="t?.fanalizado==2" class="text-danger" style="color: red;">Cancelada</span></strong> </p>
                <p class="card-text"><button class="btn btn-primary" data-toggle="modal" (click)=" getSituacaofinanceiraItens(t?.id)" data-target="#ModalShow">Detalhes</button> </p>
            </div>
            </div>
        </div>
    </div>
    <!-- Adicione mais cartões conforme necessário -->
</div>

</div>
<hr>
<div class="container-fluid" hidden>
  <div class="col-md-6 dados-edtudante" >
   <p> <span>Nome: <strong>{{estudanteNome}}</strong></span></p>
   <p> <span>CÓD. EST.: <strong>{{edu_estudante_id}}</strong></span></p>
   <p> <span>Curso: <strong>{{estudanteCurso}}</strong></span></p>
   <p> <span>Turma atual: <strong>{{estudanteTurma}}</strong></span></p>
  </div>
</div>
<hr>
   <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="5%">ID</th>

          <th width="30%">Ano letivo</th>
          <th width="10%" *ngFor="let m of meses">{{m?.name}}</th>
          <th width="5%">Total</th>
          <th width="5%">Diferença</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let aa of ano_avaliacoes index as i">
          <td >{{i+1}}</td>
          <td >{{aa?.name}}</td>

          <td *ngFor="let m of meses"> {{getPreco(m?.name,edu_estudante_id,aa.name)}}</td>
          <td>{{getTotal(edu_estudante_id,aa.name)}}</td>
          <td>{{getDiferenca(edu_estudante_id,aa.name)}}</td>





        </tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>


    <!-- The Modal -->
<div class="modal" id="ModalShow">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h1 class="modal-title text-white">+</h1>
        <button type="button" class="close" id="btnModalClose" data-dismiss="modal">×</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover" style="width: 100%;">
          <thead>
            <tr>
              <th with="10%">ID</th>
              <th with="25%">DESIGNAÇÃO</th>
            <th with="25%">PREÇO</th>
            <th with="25%">QTD.</th>
            <th with="25%">TOTAL</th>


            </tr>


          </thead>
          <tbody>
            <tr *ngFor="let n of docItems">
              <td >{{n?.id}}</td>
              <td > {{n?.name}} {{n?.mes}} {{n?.disciplina}}</td>
              <td>{{n?.preco |number:'1.2-2'}}</td>
              <td>{{n?.quantidade |number:'1.2-2' }}</td>
              <td>{{n?.total | number:'1.2-2'}}</td>




            </tr>

          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
  </div>
