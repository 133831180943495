
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">







                          <div class="container-fluid">


                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

<div class="container">
  <div class="row">
    <div class="col-md-2">
      <input type="number" class="form-control" name="user_id" disabled [(ngModel)]="formUser.user_id">
    </div>
    <div class="col-md-4">
      <input type="text" class="form-control" name="username" [(ngModel)]="formUser.username">
    </div>
    <div class="col-md-4">
      <input type="password" class="form-control" name="password" [(ngModel)]="formUser.password">
    </div>

    <div class="col-md-2">
      <button class="btn btn-primary" (click)="saveUser()">Alterar</button>
    </div>
    <div class="col-md-2">
      <button class="btn btn-primary" (click)="saveProlongar()">Prolongarda para código 2F</button>
    </div>
    <div class="col-md-2">
      <button class="btn btn-primary" (click)="LimparBloqueios()">Limpara bloqueios</button>
    </div>
  </div>
</div>
<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Nível de Acesso</th>
          <th>Associar Estudante</th>
          <th>Acao</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users index as i">
          <td >{{i+1}}</td>
          <td >{{user.username}}</td>
          <td >{{user.email}}</td>
          <td ><ul>
            <li *ngFor="let role of user.roles">{{role.name}}</li>
          </ul></td>
          <td>
            <a routerLink="#" (click)="assocEstudnate(user.id,user.id_estudante)"><i class="ti-link"></i></a>          </td>
                <td> <i class="fa fa-pencil" (click)="edit(user)"></i></td>
          </tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
