import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';


@Component({
  selector: 'app-habilitar-lanacamento-notas',
  templateUrl: './habilitar-lanacamento-notas.component.html',
  styleUrls: ['./habilitar-lanacamento-notas.component.css']
})
export class HabilitarLanacamentoNotasComponent implements OnInit {

   modalComponent:any
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  iconLink=''


  dataPutPauta:any
  constructor( private repo:HttpRepoService,private toastr: ToastrService) { }
async loadpuPauta(){
  this.repo.getAll('/dataputpautas').subscribe((res)=>{
    this.dataPutPauta=res.result;
    console.log(  this.dataPutPauta)
    this.dataTableInit()
  })


}
  ngOnInit(): void {

this.loadpuPauta()
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 10, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  update(data:any){


    try{


      this.repo.Add(data,'/dataputpautasupdate').subscribe((res)=>{
        if(res.result==1)
        this.toastr.success("Atualizado!","SUCCESS")
        else
        this.toastr.error("Não atualizado!","ERROR")

        console.log(res)


     })

    }
    catch(ex){
      console.log(ex)
    }
  }
  getValue(ev:any,arg:Number=0,id:Number=0){
    let data
   try{
    switch(arg){

      case 1:
       data=new Date(ev.target.innerText)
        if(data.toString()=='Invalid Date')
        this.toastr.error("Data inválida","ERROR")
        else
        this.update({date_ini:data,id:id})
      break;
      case 2:
       data=new Date(ev.target.innerText)
       if(data.toString()=='Invalid Date')
       this.toastr.error("Data inválida","ERROR")
       else
        this.update({date_end:data,id:id})
      break;
      case 3:
        if(parseInt(ev.target.innerText)==0||parseInt(ev.target.innerText)==1)
        this.update({status:parseInt(ev.target.innerText),id:id})
        else
        this.toastr.error("São permitidos apenas valores de 0 ou 1!","ERROR")
      break;

    }
   }
   catch(ex){
    this.toastr.error("Erro","ERROR")
   }
console.log(ev.target.innerText)
  }
}
