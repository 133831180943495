<div class="container">
 <div class="header-section">
  <div class="text-center">
    <img class="image-logo" src="assets/images/ISPPM.jpg" alt="LOGO">
  </div>
  <div class="dep text-center">
<h1>INSTITUTO SUPERIOR POLITÉCNICO PRIVADO DE MENONGUE</h1>
<p>Criado Pelo Decreto Presidencial nº 227/17 de 27 de Setembro</p>
 <h1>_____________________________________________________________</h1>
<h1>SUB-DIREÇÃO DOS ASSUNTOS ACADÉMICOS</h1>
  </div>
  <div class="title text-center">
    <br>
    <h3>MINI-PAUTA ANEXO DO Iº SEMESTRE</h3>
    <br>
  </div>
  <div class="container-fluid">
    <div class="row ml-0 p-0">
      <div class="col-md-5 col-5">
        <h3 class="title-general">ANO ACADÉMICO: <span class="text-title">{{data_header_mini_pauta?.ano_academico}}</span></h3>
        <h3 class="title-general">CURSO DE: <span class="text-title">{{data_header_mini_pauta?.curso}}</span></h3>
      </div>

      <div class="col-md-5 col-5">
        <h3 class="title-general">TIPO DE CADEIRA: <span class="text-title">{{data_header_mini_pauta?.tipo_cadeira}}</span></h3>
        <h3 class="title-general">DISCIPLINA: <span class="text-title">{{data_header_mini_pauta?.disciplina}}</span></h3>
      </div>

      <div class="col-md-2 col-2">
        <h3 class="title-general">PERÍODO: <span class="text-title">{{data_header_mini_pauta?.periodo}}</span></h3>
        <h3 class="title-general">TURMA: <span class="text-title">{{data_header_mini_pauta?.turma}}</span></h3>
      </div>
    </div>
  </div>
 </div>
</div>
