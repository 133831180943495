import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

@Component({
  selector: 'app-pedidos-pautas',
  templateUrl: './pedidos-pautas.component.html',
  styleUrls: ['./pedidos-pautas.component.css']
})
export class PedidosPautasComponent implements OnInit {

  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  data:any
  iconLink=''
  constructor(private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) { }
  ngOnInit(): void {
this.gePedidos()
  }


gePedidos(){
let __data
  try{
    this.repo.getAll('/notas-pedidos/7').subscribe((res)=>{


      __data=res.pedidos
    this.data=__data
this.dataTableInit()
 console.log( this.data)


   })
  }
  catch(ex){
    console.log(ex)
  }
}

dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}
}
