
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">



                            <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                              <label class="control-label">Ano Avaliação *</label>


                                  <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                                  {{anos.name}}


                          </div>
                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>
<button (click)="imprimirDocumentoWeb()">pdf</button>
    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="8%">ID</th>
          <th width="5%">Ano lectivo</th>
          <th width="10%">Cód.Estudante</th>
          <th width="30%">Nome</th>
          <th width="30%">Conclui da matrícula?</th>
          <th width="30%">Curso</th>
          <th width="10%">Turma</th>
          <th width="10%">Período</th>
          <th width="10%">Data</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pag of dataCandidaturas index as i">
          <td >{{i+1}}  <i class="fa fa-shopping-cart" data-toggle="modal" data-target="#ModalShow" (click)="getExtractHistory(pag?.edu_estudante_id)"></i> | <i class="fa fa-eye" data-toggle="modal" data-target="#ModalDetail" (click)="getData(pag)"></i></td>
          <td >{{pag?.anoavaliacao}}</td>

          <td >{{pag?. edu_estudante_id}}</td>
          <td >{{pag?.nome}}</td>
          <td > <span *ngIf="queeyPagData(pag?.edu_matricula_id ) !='F' ">Sim</span>
            <span *ngIf="queeyPagData(pag?.edu_matricula_id ) =='F' ">Não</span></td>
          <td >{{pag?.curso}}</td>
          <td >{{pag?.turma}}</td>
          <td >{{pag?.periodo}}</td>
          <td > <span *ngIf="queeyPagData(pag?.edu_matricula_id ) !='F' ">{{queeyPagData(pag?.edu_matricula_id ) | date}}</span></td>
        </tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>





    <div class="container">
      <hr>
      <div style="text-align: center;">
          <h2>{{totalEstudantes}} ESTUDANTES DISTRIBUÍDOS POR  - {{totalturmas}} TURMAS</h2>

      </div>
      <table class="table table-bordered table-striped">
        <thead width="100%">
          <tr>
            <th width="25%">Período</th>
            <th width="50%">Turma</th>

          <th width="25%">Total</th>
          </tr>
        </thead>
        <tr  *ngFor="let resT of studentsResumoTurmas">
          <td>{{resT?.periodo}}</td>
          <td>{{resT?.turma}}</td>

          <td>{{resT?.total }} </td>
        </tr>
      </table>
      <div style="text-align: center;">
        <h2>{{totalEstudantes}} ESTUDANTES DISTRIBUÍDOS POR  - {{totalCursos}} CURSOS</h2>

    </div>
      <table class="table table-bordered table-striped">
        <thead width="100%">
          <tr>
            <th width="50%">Curso</th>

          <th width="25%">Total</th>
          </tr>
        </thead>

        <tr  *ngFor="let res of resultadoCurso">
          <td>{{res?.curso}}</td>

          <td>{{res?.total }} </td>
        </tr>
      </table>
    </div>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>

<!-- The Modal -->
<div class="modal" id="ModalShow">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h1 class="modal-title text-white">+</h1>
        <button type="button" class="close" id="btnModalClose" data-dismiss="modal">×</button>
      </div>
  <!-- Modal body -->
  <div class="modal-body">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="5%">Ordem</th>
          <th width="10%">Acao</th>
          <th width="5%">Cód. Estudante.</th>
          <th width="5%">Fatura</th>
          <th width="5%">Doc. Bancário</th>


          <th width="5%">Total</th>
          <th width="30%">Serviço</th>



          <th width="10%">Data</th>


        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let pag of history_sales index as i">
          <td ></td>
          <td ><i> <a routerLink="/dashboard/view/12/payments/details/{{pag?.comercial_documento_id}}" class="text-primary fa fa-eye"></a></i>


          </td>


          <td >{{pag?.edu_estudante_id}}</td>
          <td >{{pag?.numero_ordem}}</td>
          <td >{{pag?.referencia_pagamento}}</td>


          <td >{{pag?.total | number:'1.2-2'}}</td>



          <td style="text-transform: uppercase;"> {{pag?.name}} <span *ngIf="pag?.disciplina">/{{pag?.disciplina}}</span><span *ngIf="pag?.mensalidade">/{{pag?.mensalidade}} {{pag?.ano_letivo}}</span></td>



          <td >{{pag?.created_at | date}}</td>

        </tr>

      </tbody>
    </table>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div>
</div>
</div>
</div>





<div class="modal" id="ModalDetail">
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h1 class="modal-title text-white">+</h1>
        <button type="button" class="close" id="btnModalClose" data-dismiss="modal">×</button>
      </div>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="col-md-12">

      <p>   </p>


    </div>

    <div class="container">

    <fieldset >
      <div class="row formavlidation-wrapper mt-2">

                        <div class="col-md-12">
                            <div class="card card-statistics">





                              <div class="row">
                                <div class="col-md-6">

                                </div>
                                <div class="col-md-3">


                                </div>
                                <div class="col-md-3">
                                  <img class="m-2" src="{{estudante?.picture}}" alt="" width="120" style="border: solid 5px;">
                                </div>
                              </div>
                              <div class="page-title mr-4 pr-4 " style="margin:5px 0px 0px 20px ">
                                <h6 class="text-danger"> Informações pessoais</h6>

                            </div>
                                <div class="card-body">
                                    <div class="tab nav-border-bottom">


                                        <div class="tab-content">
                                            <div class="tab-pane fade py-3 active show" id="home-02" role="tabpanel" aria-labelledby="home-02-tab">
                                                <div id="advanced-first" class="step">
                                                    <!-- Step Info -->

                                                    <div id="advanced-first" class="step">
                                                        <!-- Step Info -->
                                                            <div class="row">

                                                                <div class="col-md-3">



                                                             </div>
                                                                <div class="col-md-3">



                                                             </div>

                                                            </div>
                                                            <hr>
                                                        <div class="form-row">
                                                          <div class="col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="control-label">Nome completo*</label>
                                                                <p><strong>{{estudante?.nome}}</strong></p>
                                                            </div>
                                                        </div>
                                                            <div class="col-md-3 col-sm-12">
                                                                <div class="form-group">
                                                                    <label class="control-label">Data nascimento *</label>
                                                                    <p><strong>{{estudante?.data_nascimento | date}}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-12">
                                                                <div class="form-group">
                                                                    <label class="control-label">Sexo*</label>
                                                                            <p><strong>{{estudante?.genero}}</strong></p>
                                                                                                         </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-12">
                                                              <div class="form-group">
                                                                  <label class="control-label">Estado civil*</label>
                                                                <p>  <strong>{{estudante?.base_estado_civil}}</strong></p>
                                                              </div>
                                                          </div>
                                                          <div class="col-md-3 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="control-label">BI*</label>
                                                              <p>  <strong>{{estudante?.n_documento_identificacao}}</strong></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-12">
                                                          <div class="form-group">
                                                              <label class="control-label">Pai*</label>

                                                             <p> <strong>{{estudante?.pai }}</strong></p>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-3 col-sm-12">
                                                        <div class="form-group">
                                                            <label class="control-label">Mãe:</label>
                                                           <p> <strong>{{estudante?.mae}}</strong></p>

                                                        </div>
                                                    </div>


                                                            <div class="col-md-4 col-sm-12">
                                                                <div class="form-group">
                                                                    <label class="control-label">País *</label>
                                                                     <p><strong>{{estudante?.pais_origem}}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-sm-12">
                                                              <div class="form-group">
                                                                  <label class="control-label">Naturalidade *</label>
                                                                   <p><strong>{{estudante?.provincia_natural}}/{{estudante?.municipio_natural                                                                                     }}</strong></p>
                                                              </div>
                                                          </div>
                                                          <div class="col-md-4 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="control-label">Endereço *</label>
                                                                 <p><strong>{{estudante?.endereco}}</strong></p>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4 col-sm-12">
                                                          <div class="form-group">
                                                              <label class="control-label">Telefone *</label>
                                                               <p><strong>{{estudante?.telefone}}</strong></p>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-4 col-sm-12">
                                                        <div class="form-group">
                                                            <label class="control-label">Telefone alternativo *</label>
                                                             <p><strong>{{estudante?.telefone}}</strong></p>
                                                        </div>
                                                    </div>

                                                        </div>
                                                        <div class="page-title mr-4 pr-4 " style="margin:5px 0px 0px 20px ">
                                                          <h6 class="text-danger"> Dados de candidaturas</h6>

                                                      </div>
                                                      <div class="form-row">

                                                        <div class="col-md-4 col-sm-12">
                                                          <div class="form-group">
                                                            <label class="control-label">Nº processo *</label>
                                                            <p><strong>{{estudante?.codigo_acesso}}</strong></p>
                                                          </div>
                                                      </div>

                                                      <div class="col-md-4 col-sm-12">
                                                        <div class="form-group">
                                                          <label class="control-label">Ano lectivo/Perído *</label>
                                                          <p><strong>{{estudante?.ano_letivo}}/{{estudante?.periodo}}</strong></p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4 col-sm-12">
                                                      <div class="form-group">
                                                        <label class="control-label">Curso de formação *</label>
                                                        <p><strong>{{estudante?.curso_formacao}}</strong></p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                      <label class="control-label">Curso candidatura/Sala exame
                                                         *</label>
                                                      <p><strong>{{estudante?.curso}}/{{estudante?.sala}}</strong></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12">
                                                  <div class="form-group">
                                                    <label class="control-label">Situação *</label>
                                                    <p><strong>{{estudante?.situacao}}</strong></p>
                                                  </div>
                                              </div>


                                              <div class="col-md-12 col-sm-12">
                                               

                                              <div class="table-responsive">


                                            </div>
                                            </div>


                                                    </div>
                                                    <hr><br><br>








                                                      </div>






                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

    </fieldset>

    <hr>
    <br><br>
    <br><br>
    </div>

  </div>

  <!-- Modal footer -->
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
  </div>
</div>
</div>
</div>



