
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">



                            <div class="col-md-2 col-sm-12">
                              <label class="control-label">Data Inicial *</label>


                                  <input value="" class="form-control" type="date" value="" name="data_ini" [(ngModel)]="data_ini">

                          </div>
                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">Data Final *</label>


                                <input value="" class="form-control" type="date" value="" name="data_fim" [(ngModel)]="data_fim">

                        </div>
                        </div>
                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary"  (click)="getPagamentos(1)" title="Carregar">Relatório Completo</button>

                          </div>





                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover" >
      <thead>
        <tr>
          <th width="5%">ID</th>


          <th width="50%">Serviço</th>

          <th width="25%">Qtd.</th>
          <th width="20%">Total</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pag of Pagamentos index as i">
          <td >{{i+1}}</td>


          <td >{{pag?.servico}}</td>

          <td >{{pag?.quantidade}}</td>
          <td >{{pag?.total |number:'1.2-2'}}</td>


        </tr>
        <tr><td>TOTAL</td><td>-</td><td>-</td><td>{{totalGeral|number:'1.2-2'}}</td></tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>


    <div class="container">
      <hr>
      <div style="text-align: center;">
          <h2>RESUMO</h2>
          <p><strong>TOTAL:{{totalGeral|number:'1.2-2'}}</strong></p>
      </div>

    </div>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
