
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">

                          <!--

                            <div class="col-md-2 col-sm-12" *ngFor="let anos of ano_avaliacoes">
                              <label class="control-label">Ano Avaliação *</label>


                                  <input value={{anos.id}}  type="checkbox" value=""  (change)=getJSONEstudantes($event,anos.name)>
                                  {{anos.name}}


                          </div>-->
                          <div class="container-fluid">

                                <hr>
                          <button class="btn btn-primary" title="Carregar" hidden>Carregar</button>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="10%">Nº</th>
          <th width="10%">Cod. Estu.</th>
          <th width="10%">Cod.Mat</th>
          <th width="10%">Nome</th>
          <th width="10%" *ngFor="let sa of  sistemaAvalicao">{{sa.name}}</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let estudante of dataEstudantes index as i">
          <td >{{i+1}}</td>
          <td>{{estudante.edu_estudante_id}}</td>
          <td>{{estudante.id}}</td>

          <td>{{estudante.nome}}</td>
          <td *ngFor="let sa of  sistemaAvalicao" style="text-align:center;">

            <span *ngIf="notaEdit(estudante.id,sa.id)==3" >
              <input style="background: rgb(238, 124, 124); border:none;" value="{{getNotaEstu(estudante.id,sa.id)}}" type="number" min="0" max="20"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"  (blur)="notaUpdate($event)">
            </span>
            <span *ngIf="notaEdit(estudante.id,sa.id)==2">
              <input style="background: rgb(221, 228, 124); border:none;" value="{{getNotaEstu(estudante.id,sa.id)}}" type="number" min="0" max="20" disabled
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)">
            </span>
            <span *ngIf="notaEdit(estudante.id,sa.id)==1">
              <input style="background: rgb(160, 247, 171); border:none;" value="{{getNotaEstu(estudante.id,sa.id)}}" type="number" min="0" max="20" disabled
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)">
            </span>
            R <input type="checkbox" name="" id="" (click)="Refuted(3,getNotaId(estudante.id,sa.id))">
            A <input type="checkbox" name="" id="" (click)="aproved(1,getNotaId(estudante.id,sa.id))">
          </td>
        </tr>
      </tbody>
      <tbody>


      </tbody>
    </table>

    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
