
<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset>
                    <div class="form-row">

                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Meses de cobrança *</label>
                        <ng-select [(ngModel)]="mese_escolhidos" [multiple]="true">
                          <ng-option *ngFor="let anos of meses" [value]="anos.id" >{{anos.name}}</ng-option>
                        </ng-select>




                    </div>

                        <div class="col-md-6 col-sm-12">
                          <label class="control-label">Ano Avaliação *</label>
                          <ng-select [(ngModel)]="ano_avaliacoes_id" (change)="getDevedores()">
                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos.id" >{{anos.name}}</ng-option>
                          </ng-select>




                      </div>

                      <div class="container-fluid">

                            <hr>

                      <button (click)="getEstudantes()" class="btn btn-primary">Listar</button>
                         <hr> <input [(ngModel)]="namePDF" name="namePDF" type="text" class="form-control" placeholder="Informe o nome para o pdf">
                        </div>



                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<hr>
<fieldset  #contentToConvert>
<!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
<table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
  <thead>
    <tr>
      <th width="10%">ID</th>
      <th width="10%">Nivel</th>
      <th width="10%">Curso</th>

      <th width="10%">Turma</th>

      <th width="20%">Nome completo</th>

      <th width="10%" *ngFor="let s of currentField">{{getMont(s)?.name}}</th>


    </tr>
  </thead>
  <tbody>



    <tr *ngFor="let estudante of dataEstudantes index as i" >
      <td >{{i+1}}</td>


      <td >{{estudante?.nivel}}</td>
      <td >{{estudante?.curso}}</td>
      <td >{{estudante?.turma}}</td>
      <td >{{estudante?.nome}}</td>
      <td *ngFor="let s of currentField">
        <ng-container *ngIf="getEstudante(estudante?.edu_matricula_id) as estudante">
          <span>
            {{ estudante[getMont(s)?.field] ? isMissin(estudante[getMont(s)?.field]) : 'Em falta' }}

          </span>
        </ng-container>
        <ng-container *ngIf="!getEstudante(estudante?.edu_matricula_id)">
          <span>Em falta</span>
        </ng-container>
      </td>




    </tr>

  </tbody>
</table>


</fieldset>
