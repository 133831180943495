<section style="background-color: #eee;">

  <div class="page-title mr-4 pr-4 " style="margin:5px 0px 0px 20px ">
    <h1 class="text-warning">{{saudacao}}</h1>


</div>

<section class="details-card">
  <div class="container pt-0">
      <div class="row">
          <div class="col-md-4">
              <div class="card-content">
                  <div class="card-img">

                      <span><h4>heading</h4></span>
                  </div>
                  <div class="card-desc">
                      <h3>Situação Financeira</h3>
                      <p>Manteinha-te informado sobre o seu estado financeiro</p>
                          <a routerLink="/situacao-financeira" class="btn-card">Read</a>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card-content">
                  <div class="card-img">


                  </div>
                  <div class="card-desc">
                      <h3>Consultar notas</h3>
                      <p>Atualize-se sobre o seu rendimento académico...</p>
                          <a routerLink="/notasestudante" class="btn-card">Read</a>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card-content">
                  <div class="card-img">


                  </div>
                  <div class="card-desc">
                      <h3>Recursos</h3>
                      <p>Acompanhe as disciplinas as quais foi increveu-se para o recurso </p>
                          <a href="#" class="btn-card">Read</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- details card section starts from here -->
  <div class="container py-1">

    <div class="row justify-content-center" *ngFor="let p of publicacao ">
      <div class="col-md-12 col-xl-10">
        <div class="card shadow-0 border rounded-3">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                <div class="bg-image hover-zoom ripple rounded ripple-surface">
                  <img src="./../../../../assets/1821921.webp"
                    class="w-100" />
                  <a href="#!">
                    <div class="hover-overlay">
                      <div class="mask" style="background-color: rgba(253, 253, 253, 0.15);"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-xl-6">
                <h5>{{p?.date_end|date}}</h5>
                <div class="d-flex flex-row">
                  <div class="text-danger mb-1 me-2">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                  </div>
                  <span>145</span>
                </div>
               <!-- <div class="mt-1 mb-0 text-muted small">
                  <span>100% cotton</span>
                  <span class="text-primary"> • </span>
                  <span>Light weight</span>
                  <span class="text-primary"> • </span>
                  <span>Best finish<br /></span>
                </div>
                <div class="mb-2 text-muted small">
                  <span>Unique design</span>
                  <span class="text-primary"> • </span>
                  <span>For women</span>
                  <span class="text-primary"> • </span>
                  <span>Casual<br /></span>
                </div>-->
                <p class="text-truncate mb-4 mb-md-0">
                  {{p?.descricao}}
                </p>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                <div class="d-flex flex-row align-items-center mb-1">
                  <h4 class="mb-1 me-1  {{aberto(p)?.aberto}}">{{aberto(p)?.encerrado}}</h4>

                </div>
                <h6 class="text-success">Docentes</h6>
                <div class="d-flex flex-column mt-4">
                  <button class="btn btn-primary btn-sm" type="button">Lançar</button>
                  <button class="btn btn-outline-primary btn-sm mt-2" type="button">
                    Ver mais
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
