import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as printJS from 'print-js';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DeclaracaoService } from 'src/app/services/base/doc/declaracao.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';
import { Registro } from '../../edu/estudantes/estudantes.component';

@Component({
  selector: 'app-previsao',
  templateUrl: './previsao.component.html',
  styleUrls: ['./previsao.component.css']
})
export class PrevisaoComponent implements OnInit {
  resultadoCurso: any
  resultadoCursoTemp: any;
  dataCandidaturas: any;
  anoSelecionado: number=0;
  data: any;
  previsaoFinal: any;
  previsaoFinalResumo: any;
  resumoInicial:any;
  niveis: any
  totalPorNivel: any;
  niveis1: string[] | undefined;
  final: any
  ResumoPago: any;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private pdfDec:DeclaracaoService) {

    }
     meses = ["outubro", "novembro", "dezembro", "janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho"];

    instituicao:any
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  previsaoMensalidades!: PrevisaoMensalidades;
  ano_avaliacoes:any
  ano_avaliacoes_id:Number=0
  rsumoF:any

 cursos=[
 { name:"Direito"},
  {name:"Psicologia"},
  {name: "Comunicação Social"},
  {name:"Engenharia Informática"},
 { name:"Economia e Gestão"},
 ]
   mensalidades: { [curso: string]: number } = {
    "Direito": 29000,
    "Psicologia": 29000,
    "Comunicação Social": 29000,
    "Engenharia Informática": 30000,
    "Economia e Gestão": 30000,
  };
  //dtElement: DataTableDirective;


Av={
  id:null,
  name:null
}
    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)

     this. getAnos()
     this.getInstituicao()
    //Loading child components
    this.loadingChildComponents();
    this.loadingData();
   this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10000,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("List de Estudantes - "+this.anoSelecionado)
    }
    loadingChildComponents(){
      //this.modalComponent=UserAddComponent
    }


 async getAnos(){

  this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
     this.data=res;

     this.ano_avaliacoes=this.data.ano_avaliacoes
////console.log( this.ano_avaliacoes)


  })
 }
 async getResumoPorNIvel(ano_avaliacoes_id:string){

  this.repo.getAll('/resumo-por-nivel/'+ano_avaliacoes_id).subscribe((res)=>{
    this.ResumoPago=res.data
    //console.log("RESUMO:", this.ResumoPago)


  })
 }
 getPago(nivel:string){
  try{
    const data=this.ResumoPago.find((resumo: { nivel: string; })=>resumo.nivel==nivel)
//console.log(data)
return data
  }
  catch{
return{}
  }

}

getPagoDif(vP:number,vPago:number){
  try{
    const v=vP-vPago
return v
  }
  catch{
return 0
  }
}
 async getInstituicao(){

  this.repo.getAll('/instituicao').subscribe((res)=>{


     this.instituicao=res.infInstituicao
     ////console.log( this.instituicao)



  })
 }
 async getJSONEstudantes(e:any,anos:number){
  this.anoSelecionado=anos
  this.ngOnDestroy()
////console.log(e.target.value)
this.ano_avaliacoes_id=parseInt(e.target.value)
  this.repo.getAll('/estudantesgeral/'+this.ano_avaliacoes_id).subscribe((res)=>{
     this.dataCandidaturas=res.estudantes;
this.getResumoPorNIvel(this.ano_avaliacoes_id.toString())

     const data: Registro[] =this.dataCandidaturas

    const previsaoPagamentos: { [turma: string]: { [curso: string]: { [mes: string]: number } } } = {};

    data.forEach(({ turma, curso }) => {
      const mensalidade = this.mensalidades[curso];
      const numEstudantes = previsaoPagamentos[turma] || {};
      const pagamentosCurso = numEstudantes[curso] || {};

      this.meses.forEach((mes) => {
        pagamentosCurso[mes] = (pagamentosCurso[mes] || 0) + mensalidade;
      });

      numEstudantes[curso] = pagamentosCurso;
      previsaoPagamentos[turma] = numEstudantes;
    });




    const jsonData = JSON.stringify({ dados: Object.entries( previsaoPagamentos).map(([turma, meses]) => ({ turma,meses})) });
    const setJson=JSON.parse(jsonData)
    this.previsaoFinal=setJson?.dados
this.setResumo(this.dataCandidaturas)














     this.dataTableInit()

  })
  this.setTitle()
 }
 setResumo(d:any){
  const data: Registro[] = d;

  const previsaoPagamentos: {
    [turma: string]: {
      nivel: string;
      curso: string;
      meses: { [mes: string]: number };
    };
  } = {};

  data.forEach(({ turma, curso, nivel }) => {
    const mensalidade = this.mensalidades[curso];
    const turmaData = previsaoPagamentos[turma] || { nivel, curso, meses: {} };
    const pagamentosCurso = turmaData.meses;

    this.meses.forEach((mes) => {
      pagamentosCurso[mes] = (pagamentosCurso[mes] || 0) + mensalidade;
    });

    turmaData.meses = pagamentosCurso;
    previsaoPagamentos[turma] = turmaData;
  });

  const jsonData = JSON.stringify(previsaoPagamentos);
  const resumo=Object.assign(JSON.parse(jsonData))
  const jsonArray = Object.entries(resumo).map(([turma, data]) => ({
    turma,
    meses: data
  }));



this.resumoInicial = jsonArray.map(({ meses, ...resto }) => meses);
this.niveis = [...new Map(this.resumoInicial.map((item: { nivel: any; }) => [item.nivel, item])).values()]

this.rsumoF=jsonArray

  const somaPorNivel: { [nivel: string]: { [meses: string]: number } } = {};

  this.resumoInicial .forEach((registro: Registro) => {
    const nivel: string = registro?.nivel;
    const mesesData: { [meses: string]: number } = registro?.meses;

    Object.entries(mesesData).forEach(([meses, valor]) => {
      somaPorNivel[nivel] = somaPorNivel[nivel] || {};
      somaPorNivel[nivel][meses] = (somaPorNivel[nivel][meses] || 0) + valor;
    });
  });


const totalPorNivelArray: { nivel: string; meses: { [mes: string]: number } }[] = [];

Object.entries(somaPorNivel).forEach(([nivel, meses]) => {
  totalPorNivelArray.push({ nivel, meses });
});
this.final=totalPorNivelArray
////console.log(totalPorNivelArray);

 //this.rsumoF=jsonArray
 //this.finalResumo( this.rsumoF)



 }

 finalResumo(data:Resumo[]){
  //const totalPorNivel: { [nivel: string]: { [mes: string]: number } } = {};


  if (data) {
  data.forEach(({ turma, meses }) => {
    const nivel: string = meses.nivel;
    const mesesData: { [mes: string]: number } = meses.meses;

    Object.entries(mesesData).forEach(([mes, valor]) => {
      if (!this.totalPorNivel[nivel]) {
        this.totalPorNivel[nivel] = {};
      }
      this.totalPorNivel[nivel][mes] = (this.totalPorNivel[nivel][mes] || 0) + valor;
    });
  });


  ////console.log(this.totalPorNivel)
  }
 }
 loadingData(){


  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }

 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}

baixarFichaMatricula(matricula:any) {

  let blobdata = this.pdfDec.criarDeclaracaoFrequencia(matricula, []);

  this.pdfDec.savePdfFile(blobdata, "ficha_matricula" + "_"+matricula?.EduEstudante?.BasePessoa?.name);
}

//this.blobdata = this._fichaMatriculaService.criarFichaMatricula(matricula, this.instituicao);
imprimirDocumentoWeb() {
  let blobdata = this.pdfDec.criarDeclaracaoFrequencia([],this.instituicao);
  const url = window.URL.createObjectURL(blobdata);
  printJS({ printable: url, type: 'pdf', })
}
previsaoFinalPorCurso(data:any,curso:string){
 try{
  const f=data?.meses[curso]



  return f
 }
 catch{
  return {}
 }
}

byLevel(nivel: string) {
  try {
    const resultadoFiltrado = this.final.find((dado: { nivel: string }) => dado.nivel === nivel);
    ////console.log("Resultado filtrado:", resultadoFiltrado);
    return resultadoFiltrado?.meses;
  } catch {
    return {};
  }
}

previsaoFinalPorCurso1(curso:string){
  try{

   const resultadoFiltrado = this.previsaoFinal.filter((dado: { meses: { [x: string]: any; }; }) => dado.meses[curso]);



   return {data:resultadoFiltrado}
  }
  catch{
   return {}
  }
 }
}

interface PrevisaoMensalidades {
  [turma: string]: {
    [curso: string]: {
      [mes: string]: number;
    };
  };
}
type TotalPorNivel = {
  [nivel: string]: {
    [mes: string]: number;
  };
};
type Resumo= {
  turma: string;
  meses: {
    nivel: string;
    curso: string;
    meses: {
      [mes: string]: number;
    };
  };
};
