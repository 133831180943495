import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';
import { UserAddComponent } from '../../base/users/user-add/user-add.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-devedores',
  templateUrl: './devedores.component.html',
  styleUrls: ['./devedores.component.css']
})
export class DevedoresComponent implements OnInit {

  namePDF='mesalidade'
  dataPDF:any
  isPDF=false
  totalPrevisto=0
  quantidadeGeral=0
  TotalGlobal=0
  title = 'reporter';
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"

  ano_avaliacoes:any

  ano_avaliacoes_id=0
  paganetoResumo: any;
  paganetoResumoTurmas: any;
  resumoTurmas: any;
  dataEstudantes: any;

  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
  private toast:ToastrService,
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private PDFService:ServicePDFjsPDFService) {

    }
    mese_escolhidos=[]
  meses:any
  anoSelecionado:Number=0
    getMeses:any
currentField=[]

Av={
  id:null,
  name:null
}

    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
      this.getMeses=this.meses
     this. getAnos()

    //Loading child components
    this.loadingChildComponents();


   this.initPaginate()
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("Relatório de Mensalidades Anuais - "+this.anoSelecionado)
    }
    loadingChildComponents(){
      this.modalComponent=UserAddComponent
    }
    initPaginate(){
      this.dtOptions = {
        pagingType: 'full_numbers'
        , pageLength: 80,
        lengthMenu: [ 25,50, 100, 1000],
         processing: true,
        dom: 'Bfrtip',
       // retrieve:true,


      };
    }

    async getDevedores(){

      try{

        if(this.mese_escolhidos.length==0){
          this.toast.error("Selecione os meses!")

        }
          else{
        this.repo.Add({ano_avaliacoes_id:this.ano_avaliacoes_id,mese_escolhidos:this.mese_escolhidos},'/devedores').subscribe((res)=>{

            //this.resumoTurmas=res.resumoTurmas
            //this.paganetoResumo=res.paganetoResumo

//const item= [...new Map(this.paganetoResumo.map((item: { turma: any; }) => [item.turma, item])).values()]
          this.paganetoResumoTurmas=res.devedores
          this.currentField=this.mese_escolhidos
          //console.log("DEV:",this.paganetoResumoTurmas=res.devedores)
      //this.dataTableInit()



       })
      }
      }
      catch(ex){
        //console.log(ex)
      }
     }
     async getEstudantes(){

      try{
        if(this.ano_avaliacoes_id==0){
        this.toast.error("Selecione o ano de avaliação!")
      }
     else if(this.mese_escolhidos.length==0){
        this.toast.error("Selecione os meses!")
      }
        else{
        this.repo.getAll('/estudantesgeral/'+this.ano_avaliacoes_id).subscribe((res)=>{
          this.dataEstudantes=res.estudantes;

      this.dataTableInit()



       })
      }
      }
      catch(ex){
        //console.log(ex)
      }
     }
 async getAnos(){

  try{
    this.repo.getAll('/anosavaliacoes').subscribe((res)=>{


      this.ano_avaliacoes=res.ano_avaliacoes



   })
  }
  catch(ex){
    //console.log(ex)
  }
 }
 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
isMissin(value:number){
  try{
    let r='Em falta'
  if(value>0){
r='Regularizado'
  }
  else{
    r='Em falta'
  }
  return r
  }catch(ex){
    return 'Em falta'
  }
}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}

generatePdf(data:any) {

  this.PDFService.generatePdf(data,this.namePDF+new Date().toISOString()+'.pdf')


}
getMont(id:number){
  try{
return this.getMeses.find((m: { id: number; })=>m.id==id)
  }
  catch(ex){

  }
}
getEstudante(edu_matricula_id:number){
  try{

   const estudante=this.paganetoResumoTurmas.find((e: { edu_matricula_id: number; })=>e.edu_matricula_id==edu_matricula_id)




  return estudante
  }
  catch(ex:any){

  return {}
  }
  }
getDiferenca(edu_turma_id:number,mensalidade:string){
try{
 let  result=0
 const pagamento=this.paganetoResumo.find((p: { edu_turma_id: number; mensalidade: string; })=>p.edu_turma_id==edu_turma_id&&p.mensalidade==mensalidade)
 const estudante= this.resumoTurmas.find((l: { edu_turma_id: number; })=>l.edu_turma_id==edu_turma_id)
 if(pagamento && estudante){
 const total_turma=estudante?.total_linhas||0
 const total_pago=pagamento?.total_linhas||0
 return parseInt(total_pago)-parseInt(total_turma)

}

return result
}
catch(ex:any){

return 0
}
}

}
