import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {DataTablesModule} from 'angular-datatables';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddTutorialComponent } from './components/add-tutorial/add-tutorial.component';
import { TutorialDetailsComponent } from './components/tutorial-details/tutorial-details.component';
import { TutorialsListComponent } from './components/tutorials-list/tutorials-list.component';
import { GlobalReporterComponent } from './components/finance/global-reporter/global-reporter.component';
import { DashboardComponent } from './components/base/dashboard/dashboard.component';


import { UserAddComponent } from './components/base/users/user-add/user-add.component';
import { HeaderComponent } from './components/templates/admin/header/header.component';
import { FloatButtomComponent } from './components/templates/admin/float-buttom/float-buttom.component';
import { ModalComponent } from './components/templates/admin/modal/modal.component';
import { FooterComponent } from './components/templates/admin/footer/footer.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ButtonLoadingComponent } from './components/templates/geral/button-loading/button-loading.component';
import { UsersComponent } from './components/base/users/users/users.component';
import { CandindaturasComponent } from './components/edu/candindaturas/candindaturas.component';
import { EstudantesComponent } from './components/edu/estudantes/estudantes.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureComponent } from './components/base/signature/signature.component';
import { PagamentosComponent } from './components/finance/pagamentos/pagamentos.component';
import { NotasComponent } from './components/estudantes/notas/notas.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { DocentesComponent } from './components/docentes/docentes.component';
import { SituacaofinanceiraComponent } from './components/estudantes/situacaofinanceira/situacaofinanceira.component';
import { NewComponent } from './components/base/users/new/new.component';
import { VetrinaDigitalComponent } from './components/base/vetrina-digital/vetrina-digital.component';
import { HabilitarLanacamentoNotasComponent } from './components/base/habilitar-lanacamento-notas/habilitar-lanacamento-notas.component';
import { AuthenticationService } from './services/auth/authentication/authentication.service';
import { GuardsGuard } from './services/auth/authentication/guards.guard';
import { MescobrancaComponent } from './components/base/mescobranca/mescobranca.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AprovacaoPautasComponent } from './components/edu/aprovacao-pautas/aprovacao-pautas.component';
import { PedidosPautasComponent } from './components/edu/pedidos-pautas/pedidos-pautas.component';
import { PaymentByYerComponent } from './components/finance/payment-by-yer/payment-by-yer.component';
import { EstudantesPdfComponent } from './components/edu/estudantes/estudantes-pdf/estudantes-pdf.component';
import { LogoffComponent } from './components/auth/logoff/logoff.component';
import { MensalidadesPorTurmaComponent } from './app/components/finance/mensalidades-por-turma/mensalidades-por-turma.component';
import { LanacarNotasComponent } from './app/components/finance/lanacar-notas/lanacar-notas.component';
import { MinhasDisciplinasDocentesComponent } from './app/components/minhas-disciplinas-docentes/minhas-disciplinas-docentes.component';
import { HeaderDocsComponent } from './components/shared/tamplete/header-docs/header-docs.component';
import { RecupreacaPautaComponent } from './components/daac/pautas/recupreaca-pauta/recupreaca-pauta.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { DevedoresComponent } from './components/finance/devedores/devedores.component';
import { NaoDevedoresComponent } from './components/finance/nao-devedores/nao-devedores.component';
import { DocentesTurmasComponent } from './components/daac/docentes-turmas/docentes-turmas.component'
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { DocentesVerTurmasComponent } from './components/daac/docentes-ver-turmas/docentes-ver-turmas.component';
import { MiniPautaComponent } from './components/daac/mini-pauta/mini-pauta.component';
import { PrevisaoComponent } from './components/finance/previsao/previsao.component';

@NgModule({
  declarations: [
    AppComponent,
    AddTutorialComponent,
    TutorialDetailsComponent,
    TutorialsListComponent,
    GlobalReporterComponent,
    DashboardComponent,
    FloatButtomComponent,
    ModalComponent,
    UserAddComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ButtonLoadingComponent,
    UsersComponent,
    CandindaturasComponent,
    EstudantesComponent,
    SignatureComponent,
    PagamentosComponent,
    NotasComponent,
    DocentesComponent,
    SituacaofinanceiraComponent,
    NewComponent,
    VetrinaDigitalComponent,
    HabilitarLanacamentoNotasComponent,
    MescobrancaComponent,
    AprovacaoPautasComponent,
    PedidosPautasComponent,
    PaymentByYerComponent,
    EstudantesPdfComponent,
    LogoffComponent,
    MensalidadesPorTurmaComponent,
    LanacarNotasComponent,
    MinhasDisciplinasDocentesComponent,
    HeaderDocsComponent,
    RecupreacaPautaComponent,
    ChangePasswordComponent,
    DevedoresComponent,
    NaoDevedoresComponent,
    DocentesTurmasComponent,
    DocentesVerTurmasComponent,
    MiniPautaComponent,
    PrevisaoComponent,



  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgSelectModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  //providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  providers: [{ provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
   },
    GuardsGuard,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
