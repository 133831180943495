import { Injectable } from '@angular/core';
const ExcelJS = require('exceljs');
const workbook = new ExcelJS.Workbook();


@Injectable({
  providedIn: 'root'
})
export class MiniPautaService {

  constructor() { }

  mini_pauta(){
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Estudantes'
    //, {  pageSetup:{paperSize: 9, orientation:'landscape'}
    );

    const cellC2 = worksheet.getCell('C2');
cellC2.value = { formula: 'SOMA(D:D)', result: 0 };

    const cellA11=worksheet.getCell('B11')
    const cellB13=worksheet.getCell('B13')
    const cellB15=worksheet.getCell('B15')
    cellB13 .value = 'A Directora Geral Adjunta                                                P/ Assuntos Académico e Vida Estudantil';
    cellB15.value="MsC. Lívia Roubles Maria Alfonso"
    cellB15.style.font = { bold: true };
   // const colName = worksheet.getColumn(2);
    //const colCard = worksheet.getColumn(3);
    cellA11.with=3
    // Configura o estilo da célula para negrito
    cellB13.style.font = { bold: true };


    cellB13.style.alignment = {horizontal: 'center', wrapText: true };
    cellB15.style.alignment = {horizontal: 'center', wrapText: true };
    worksheet.getColumn('B').width = 35;


const fileName = 'mini-pauta.xlsx';
workbook.xlsx.writeBuffer().then((data: BlobPart) => {
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
});


  }
}
