import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from '../base/cookie.service';
import { API_URLService } from 'src/app/URL.service';
import { MenuService } from '../base/menu.service';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/repository/local/token-storage/token-storage.service';
const apiURL= new API_URLService
const AUTH_API =apiURL.getUrl()+'/auth/'

const token=window.sessionStorage.getItem('auth-token')
//x-access-token Bearer   Authorization:`Bearer ${token}`,  'x-access-token':`${token}`, },)
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
  //Authorization:`Bearer ${token}`
 // 'Bearer':`${token}`
 'x-access-token':`${token}`
},)
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  attempts:number=1
  lockLogin=false
  lastValueAttpts=0
  constructor(private http: HttpClient,
   private cookie:CookieService,private url:API_URLService,private menu:MenuService,private route:Router, private authStorege:TokenStorageService ) {
    if(parseInt(this.cookie.getCookie('lastValueAttpts'))>0)
    this.cookie.setCookie({
      name:"lastValueAttpts",
      value:this.attempts,
      session:true
    })
  else
  this.cookie.setCookie({
    name:"lastValueAttpts",
    value:this.attempts,
    session:true
  })

   //this.attempts=this.attempts+ this.lastValueAttpts

    }
    getHttpOption(){
      return httpOptions;
    }
  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, httpOptions);
  }
  change_pw(pw_old: string, password_new: string): Observable<any> {
    return this.http.post(AUTH_API + 'change-pw', {
      pw_old,
      password_new
    }, httpOptions);
  }
  change_pw_user(user_id: number,username:string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'change-pw-user', {
      user_id,username,password
    }, httpOptions);
  }
  clearUser(user_id: number): Observable<any> {
    return this.http.post(AUTH_API + 'limparb', {
      user_id }, httpOptions);
  }
  p2FUser(date: Date): Observable<any> {
    return this.http.post(AUTH_API + 'bypass', {
      date }, httpOptions);
  }
  auth2(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'checkpoint', {
      username,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

  refreshToken(token: string) {
    return this.http.post(AUTH_API + 'refreshtoken', {
      refreshToken: token
    }, httpOptions);
  }
  loginAttempts():number{




    this.attempts=this.attempts+1;

      if(this.attempts>=4||this.attempts>=1){
        this.cookie.setCookie({
          name:"lastValueAttpts",
          value:this.attempts,
          session:true
        })
        this.lockLogin=true

      }

      //this.attempts++
      return this.attempts
  }
  checkGroup(url:any){
    if(this.authStorege.getGroup()!='ROLE_ADMIN'){
    var permissao = this.menu.menu/*  this.menu.menu.filter(function(res:any) {
      return res.code=="finance"
     });*/
      if(permissao.length>0){
        console.log('---')
        var checkUrl =  permissao[0].sub.filter(function(res:any) {
          return "/"+res.url==url
         });
         if(checkUrl.length>0)
         console.log('-----')
         else{

         this.route.navigate(['/error'])
        }
      }
      else{
        //console.log('Acess Não Permitido')
        this.route.navigate(['/'])
      }



    }
  }
}
