import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MesesService {
readonly meses:any =[
  {"id":"10","name":"Outubro","field":"outubro","codigo":"10","created_at":null,"updated_at":null},
  {"id":"11","name":"Novembro","field":"novembro","codigo":"11","created_at":null,"updated_at":null},
  {"id":"12","name":"Dezembro","field":"dezembro","codigo":"12","created_at":null,"updated_at":null},
  {"id":"1","name":"Janeiro","field":"janeiro","codigo":"01","created_at":null,"updated_at":null},
  {"id":"2","name":"Fevereiro","field":"fevereiro","codigo":"02","created_at":null,"updated_at":null},
  {"id":"3","name":"Março","field":"marco","codigo":"03","created_at":null,"updated_at":null},
  {"id":"4","name":"Abril","field":"abril","codigo":"04","created_at":null,"updated_at":null},
  {"id":"5","name":"Maio","field":"maio","codigo":"05","created_at":null,"updated_at":null},
  {"id":"6","name":"Junho","field":"junho","codigo":"06","created_at":null,"updated_at":null},
  {"id":"7","name":"Julho","field":"julho","codigo":"07","created_at":null,"updated_at":null},
 /* {"id":"8","name":"Agosto","codigo":"08","created_at":null,"updated_at":null},
  {"id":"9","name":"Setembro","codigo":"09","created_at":null,"updated_at":null},*/

  ]
  constructor() { }
}
