
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">
                     <span>PEDIDO:{{pedido}}</span>   <p><strong>Data Início:</strong> {{putData.date_ini|date}} <strong>Data Fim: {{putData.date_end|date}} | {{putData.descricao}}</strong> </p>
                      <fieldset> <legend class="fildset" style="text-align: center; ">Preencha todos os campos, de seguida carregue o ficheiro da pauta</legend>
                        <hr>
                        <div class="form-row">



                            <div class="col-md-2 col-sm-12">
                              <label class="control-label">Ano Avaliação *</label>
                              <ng-select [(ngModel)]="ano_avaliacao_id" (change)="getDistribucaoTurmas()">
                                <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                              </ng-select>
                          </div>

                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">Cursos*</label>
                            <ng-select [(ngModel)]="edu_curso_id" (change)="getTurmas()">
                              <ng-option *ngFor="let c of edu_cursos" [value]="c?.edu_curso_id" >{{c?.curso}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Turmas*</label>
                          <ng-select [(ngModel)]="edu_turma_id" (change)="getDisciplinasPorturma()">
                            <ng-option *ngFor="let t of distribuicaoturmas" [value]="t?.edu_turma_id" >{{t?.turma}}</ng-option>
                          </ng-select>
                      </div>
                      <div class="col-md-2 col-sm-12">
                        <label class="control-label">Disciplina*</label>
                        <ng-select [(ngModel)]="edu_disciplina_id" (change)="getEstudantes()">
                          <ng-option *ngFor="let t of disciplinas" [value]="t?.edu_disciplina_id" >{{t?.disciplina}}</ng-option>
                        </ng-select>
                    </div>
                          <div class="container-fluid">

                                <hr style="border:solid 1px #069 ;">
                                <div class="row">
                                  <div class="col-md-2 col-sm-12">
                                    <label class="control-label">1PP*</label>
                                  <input type="file" name="" (change)="loadP1($event)" id="" multiple="false" *ngIf="isPut==6; else error">
                                <ng-template #error ><span style="color: red;">Desativado</span></ng-template>
                                </div>
                                <div class="col-md-2 col-sm-12">
                                  <label class="control-label">2PP,MAC&MG*</label>
                                <input type="file" name="" (change)="loadP2($event)" id="" multiple="false" *ngIf="isPut==7; else error">
                              <ng-template #error ><span style="color: red;">Desativado</span></ng-template>
                              </div>
                                <div class="col-md-2 col-sm-12">
                                  <label class="control-label">1PP,2PP,MAC&MG*</label>
                                <input type="file" name="" (change)="loadP1P2MACMG($event)" id="" multiple="false" *ngIf="isPut==1; else error">
                              <ng-template #error ><span style="color: red;">Desativado</span></ng-template>
                              </div>

                              <div class="col-md-2 col-sm-12">
                                <label class="control-label">EXAME,RES.FINAL*</label>
                              <input type="file" name="" (change)="loadExame($event)" id="" multiple="false"*ngIf="isPut==2; else error">
                              <ng-template #error ><span style="color: red;">Desativado</span></ng-template>

                            </div>

                            <div class="col-md-2 col-sm-12">
                              <label class="control-label">EXAME RECURSO*</label>
                            <input type="file" name="" (change)="loadExameRecurso($event)" id="" multiple="false"*ngIf="isPut==3; else error">
                            <ng-template #error >|<span style="color: red;">Desativado</span></ng-template>

                          </div>
                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">EXAME ESPECIAL*</label>
                          <input type="file" name="" (change)="loadExameEspecial($event)" id="" multiple="false"*ngIf="isPut==4; else error">
                          <ng-template #error >|<span style="color: red;">Desativado</span></ng-template>

                        </div>

                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">TODOS EXAMES*</label>
                        <input type="file" name="" (change)="loadAll($event)" id="" multiple="false"*ngIf="isPut==5; else error">
                        <ng-template #error >|<span style="color: red;">Desativado</span></ng-template>

                      </div>
                          </div>
                                  <hr>
                         <fieldset>
                          <button class="btn btn-primary btn-import" title="Carregar" (click)="send()" *ngIf="importPauta">Importar</button>
                          | <button class="btn btn-primary btn-search" title="Carregar Pauta" (click)="getPauta()">Consultar Pauta</button>
                          | <button class="btn btn-primary"  title="Carregar Pauta" (click)="temp()" *ngIf="importPauta">Mostrar notas carregadas</button>

                         </fieldset>

                          </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="10%">Nº</th>
          <th width="10%">Cod. Estu.</th>
          <th width="10%">Cod.Mat</th>
          <th width="10%">Nome</th>
          <th width="10%" *ngFor="let sa of  sistemaAvalicao">{{sa.name}}</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let estudante of dataEstudantes index as i">
          <td >{{i+1}}</td>
          <td>{{estudante.edu_estudante_id}}</td>
          <td>{{estudante.id}}</td>

          <td>{{estudante.nome}}</td>
          <td *ngFor="let sa of  sistemaAvalicao" style="text-align:center;">


            <span *ngIf="notaEdit(estudante.id,sa.id)==3" >


              <input style="background: rgb(238, 124, 124); border:none;" value="{{getNotaEstu(estudante.id,sa.id)}}" type="number" min="0" max="20"
              onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"  (blur)="notaUpdate($event,estudante.id,sa.id)">
            </span>
            <span *ngIf="notaEdit(estudante.id,sa.id)==2" style="background: rgb(221, 228, 124); border:none;">

              {{getNotaEstu(estudante.id,sa.id)}}
            </span>

            <span *ngIf="notaEdit(estudante.id,sa.id)==1" style="background: rgb(160, 247, 171); border:none;">

              {{getNotaEstu(estudante.id,sa.id)}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody>


      </tbody>
    </table>

    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
