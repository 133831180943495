

<div class="row formavlidation-wrapper  ">
  <div class="col-12 col-lg-12">
    <div class="accordion" id="accordionsimplefill">
      <div class="mb-2 acd-group">
        <div class="card-header rounded-0 bg-primary">
          <h5 class="mb-0">
            <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
              data-toggle="collapse">+</a>
          </h5>
        </div>
        <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
          <div class="row">
            <div class="col-12 col-lg-12">

              <div class="card card-statistics">
                <div class="card-body">

                  <fieldset>
                    <div class="container">
                      <ul>
                        <li>Preencha todos os campos,de seguida clica no botão consultar;</li>
                        <li>- Depois atualize clicando no botão consultar</li>
                      </ul>
                    </div>
                    <hr>
                    <div class="form-row">


                      <div class="col-md-2 col-sm-12">
                        <label class="control-label">Unidade/Semestre *</label>
                        <ng-select [(ngModel)]="unidade_id" >
                          <ng-option *ngFor="let anos of unidades" [value]="anos?.id" >{{anos?.name}}</ng-option>
                        </ng-select>
                    </div>
                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Ano Avaliação *</label>
                          <ng-select [(ngModel)]="ano_avaliacao_id" (change)="getIsPut($event)" >
                            <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos?.id" >{{anos?.name}}</ng-option>
                          </ng-select>
                      </div>

                      <div class="col-md-2 col-sm-12">
                        <label class="control-label">Cursos*</label>
                        <ng-select [(ngModel)]="edu_curso_id" (change)="getTurmas()">
                          <ng-option *ngFor="let c of edu_cursos" [value]="c?.edu_curso_id" >{{c?.curso}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <label class="control-label">Turmas*</label>
                      <ng-select [(ngModel)]="edu_turma_id" (change)="getDisciplinasPorturma($event)">
                        <ng-option *ngFor="let t of distribuicaoturmas" [value]="t?.edu_turma_id" >{{t?.turma}}</ng-option>
                      </ng-select>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <label class="control-label">Disciplina* </label>
                    <ng-select [(ngModel)]="edu_disciplina_id" (change)="notasPorTurm($event,disciplinas)">
                      <ng-option *ngFor="let t of disciplinas" [value]="t?.edu_disciplina_id" >{{t?.disciplina}}</ng-option>
                    </ng-select>
                </div>

                <div class="col-md-2 col-sm-12">
                  <label class="control-label">Dispensa a cadeira?*</label>
                 <!-- <ng-select [(ngModel)]="dispensa" name="dispensa">
                    <ng-option *ngFor="let d of  dispensaList" [value]="d?.id" >{{d?.name}}</ng-option>
                  </ng-select>-->
                  <p>Já não é possível escolher esta opção  <strong>FICOU NA INCUMBÊNCIA DO DAAC CONFIGUARÁ-LA NA GRELHA, GRATO PELA ATENÇÃo</strong>>></p>
              </div>



                              <hr>
                     <fieldset class="">
                      <button class="btn btn-primary btn-import m-5 " title="Carregar" (click)="getNotas()">Consultar notas</button>
                      <button class="btn btn-danger mr-3" title="Carregar" (click)="pautaPDF()">Extrair a Pauta em formato PDF</button>


                     </fieldset>





                    </div>

                  </fieldset>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
  <div class="container">
    <div class="col-md-12 bg-danger text-white">
      <h1>LEIA POR FAVOR!!!</h1>
      <p>Já não é possível escolher a opção 'dispensa/não dispensa',  a mesma  <strong>FICOU NA INCUMBÊNCIA DO DAAC CONFIGUARÁ-LA NA GRELHA, GRATO PELA ATENÇÃo</strong>>></p>

    </div>
  </div>
<div class="container-fluid" #pauta>
  <div class="row">
    <div class="col-md-12">
      <app-signature [currentSign]="userSign" *ngIf="pode_assinar"></app-signature>

    </div>
    <div class="col-4 col-lg-4">
     <span> 1ª PP, 2ª PP = Prova Parcelar =>Formula de Avaliação: <strong>(1ªPP+2ªPP+MAC)/3</strong> Resultado=MGA

<p>MAC = Média de Avaliação Contínua
</p>
<p>MGA = Média Geral de Avaliação
</p>
     </span>

    </div>
    <div class="col-4 col-lg-4">
      <span> EXAME PARA DISCIPLINA QUE DISPENSA
        <strong>(MGA*60%+EXAME*40%)= MÉDIA FINAL
        </strong>
        <p><strong>REURSO=100%
        </strong></p>

      </span>

     </div>
     <div class="col-4 col-lg-4">
      <span>EXAME PARA DISCIPLINA QUE NÃO DISPENSA: <strong>(MGA*40%+EXAME*60%)= MÉDIA FINAL
      </strong>
      <p><strong>REURSO=100%
      </strong></p>
      </span>

     </div>
    <hr>
    <div class="col-12 col-lg-12">

  <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table table-bordered table-striped table-hover table-responsive">
    <thead>
      <th with="5%">Ordem</th>
      <th with="5%">ID</th>
      <th with="30%">NOME COMPLETO</th>
      <th with="30%">OBS</th>
      <th with="5%">1ª PP</th>
      <th with="5%">2ª PP</th>
      <th with="5%">MAC</th>
      <th with="5%">MÉDIA GERAL</th>
      <th with="5%">EXAME</th>
      <th with="10%">MÉDIA FINAL</th>
      <th with="8%">RESULTADO FINAL</th>
     <th with="5%">RECURSO</th>
     <th with="8%">NOTA FINAL</th>
     <th with="8%">EXAME ESPECIAL</th>

     <th with="8%">SITUAÇÃO ACADÉMICA</th>
     <th with="8%">DAAC</th>

    </thead>
    <tbody>
      <tr *ngFor="let n of lsitaEstudantes index as i">
        <td>{{i +1}}</td>
        <td>{{n?.id}}</td>
      <td>{{n?.nome}}  </td>
      <td> <span *ngIf="getCadeiras(n?.edu_estudante_id) > 0">Repetente/Cadeirante ({{getCadeiras(n?.edu_estudante_id)}})</span>    </td>
      <td><input type="number" (blur)="lancar_nota($event,'pp1',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.pp1}}" *ngIf="n?.isrow=='pp1'+n?.id" >
        <span *ngIf="n?.isrow!='pp1'+n?.id" (click)="edit(n,'pp1',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.pp1 || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'pp2',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.pp2}}" *ngIf="n?.isrow=='pp2'+n?.id" >
        <span *ngIf="n?.isrow!='pp2'+n?.id" (click)="edit(n,'pp2',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.pp2 || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'mac',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.mac}}" *ngIf="n?.isrow=='mac'+n?.id" >
        <span *ngIf="n?.isrow!='mac'+n?.id" (click)="edit(n,'mac',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.mac || 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'mg',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{calcularMGA(getNota(n.id))}}" *ngIf="n?.isrow=='mg'+n?.id" >
        <span *ngIf="n?.isrow!='mga'+n?.id" (click)="edit(n,'mg',getNota(n.id))" style="cursor: pointer;">{{  calcularMGA(getNota(n.id))|| 0}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'exame',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.exame}}" *ngIf="n?.isrow=='exame'+n?.id" >
        <span *ngIf="n?.isrow!='exame'+n?.id" (click)="edit(n,'exame',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.exame || 0}}</span>
      </td>
      <td><span >{{getNotaFinal(getNota(n.id)) || 0}}</span></td>
      <td><input type="text" (blur)="lancar_nota($event,'resultado_final',getNota(n.id),n)"   value="{{calcularRFINAL(getNota(n.id))}}" *ngIf="n?.isrow=='resultado_final'+n?.id" >
        <span *ngIf="n?.isrow!='resultado_final'+n?.id" (click)="edit(n,'resultado_final',getNota(n.id))" style="cursor: pointer;">{{calcularRFINAL(getNota(n.id))}}</span>
      </td>
      <td><input type="number" (blur)="lancar_nota($event,'recurso',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.recurso}}" *ngIf="n?.isrow=='recurso'+n?.id" >
        <span *ngIf="n?.isrow!='recurso'+n?.id" (click)="edit(n,'recurso',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.recurso || 0}}</span>
      </td>
      <td>{{calcularNF(getNota(n.id))}}</td>

      <td><input type="number" (blur)="lancar_nota($event,'exame_especial',getNota(n.id),n)" min="0" max="20" step="0.5" value="{{getNota(n.id)?.exame_especial}}" *ngIf="n?.isrow=='exame_especial'+n?.id" >
        <span *ngIf="n?.isrow!='exame_especial'+n?.id" (click)="edit(n,'exame_especial',getNota(n.id))" style="cursor: pointer;">{{getNota(n.id)?.exame_especial || 0}}</span>
      </td>
      <td>{{Obs(getNota(n.id))}}</td>
      <td>
        <span *ngIf="getNota(n.id)?.aprovado==1" class="text-primary">OK</span>
        <span *ngIf="getNota(n.id)?.aprovado==2" class="text-danger">PF-CORRIJA!</span>
        <span *ngIf="!getNota(n.id)?.aprovado" class="text-warning">AGUARDE</span>
      </td>

      </tr>

    </tbody>
  </table>
</div>
</div>
</div>
