
<div class="container-fluid" #pauta>
  <div class="row">
    <div class="col-md-12">


    </div>
    <div class="col-4 col-lg-4">
     <span> 1ª PP, 2ª PP = Prova Parcelar =>Formula de Avaliação: <strong>(1ªPP+2ªPP+MAC)/3</strong> Resultado=MGA

<p>MAC = Média de Avaliação Contínua
</p>
<p>MGA = Média Geral de Avaliação
</p>
     </span>

    </div>
    <div class="col-4 col-lg-4">
      <span> EXAME PARA DISCIPLINA QUE DISPENSA
        <strong>(MGA*60%+EXAME*40%)= MÉDIA FINAL
        </strong>
        <p><strong>REURSO=100%
        </strong></p>

      </span>

     </div>
     <div class="col-4 col-lg-4">
      <span>EXAME PARA DISCIPLINA QUE NÃO DISPENSA: <strong>(MGA*40%+EXAME*60%)= MÉDIA FINAL
      </strong>
      <p><strong>REURSO=100%
      </strong></p>
      </span>

     </div>

  <div class="container">
    <div class="p-5">
      <!--
      <button class="btn btn-primary mr-3" title="Carregar" (click)="dispensaDAAC(1)">A DISCIPLINA DISPENSA</button>
      <button class="btn btn-primary mr-3" title="Carregar" (click)="dispensaDAAC(0)">A DISCIPLINA NÃO DISPENSA</button>
     -->
     <button class="btn btn-primary mr-3" title="Carregar" (click)="getBuscarNotas()">ATUALIZAR</button>
      <button class="btn btn-primary mr-3" title="Carregar" (click)="parecerDAAC(1)">APROVAR</button>
      <button class="btn btn-danger mr-3" title="Carregar" (click)="parecerDAAC(2)">REPROVAR</button>
      <button class="btn btn-danger mr-3" title="Carregar" (click)="pautaPDF()">Extrair a Pauta em formato PDF</button>

    </div>
  </div>
  <hr>
    <div class="col-12 col-lg-12">

      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
        <thead>
      <th with="5%">ORDEM</th>
      <th with="5%">ID</th>
      <th with="30%">NOME COMPLETO</th>
      <th with="8%">1ª PP</th>
      <th with="8%">2ª PP</th>
      <th with="8%">MAC</th>
      <th with="5%">MÉDIA GERAL</th>
      <th with="8%">EXAME</th>
      <th with="10%">MÉDIA FINAL</th>
      <th with="8%">RESULTADO FINAL</th>
     <th with="5%">RECURSO</th>
     <th with="8%">NOTA FINAL</th>
     <th with="8%">EXAME ESPECIAL</th>

     <th with="8%">SITUAÇÃO ACADÉMICA</th>
     <th with="8%">DAAC</th>
    </thead>
    <tbody>
      <tr *ngFor="let n of lsitaEstudantes index as i">
        <td>{{i+1}}</td>
        <td>{{n?.id}}</td>
      <td>{{n?.nome}}  </td>
      <td>
        <span>{{getNota(n.id)?.pp1 || 0}}  <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'pp1',2)"></i></span>
      </td>
      <td><span>{{getNota(n.id)?.pp2 || 0}}  <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'pp2',2)"></i></span>
      </td>
      <td><span>{{getNota(n.id)?.mac || 0}}  <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'mac',2)"></i></span></td>

      <td><span>{{  calcularMGA(getNota(n.id))|| 0}} </span>
      </td>
      <td><span>{{getNota(n.id)?.exame || 0}}  <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'exame',2)"></i></span>
      </td>
      <td><span >{{getNotaFinal(getNota(n.id)) || 0}}</span></td>
      <td><span>{{calcularRFINAL(getNota(n.id))}}</span>
      </td>
      <td><span>{{getNota(n.id)?.recurso || 0}} <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'recurso',2)"></i> </span>
      </td>
      <td>{{calcularNF(getNota(n.id))}}</td>

      <td><span>{{getNota(n.id)?.exame_especial || 0}}  <i class="fa fa-trash text-danger" title="Reprovar" (click)="simplesReprocao(getNota(n.id),'exame_especial',2)"></i></span>
      </td>
      <td>{{Obs(getNota(n.id))}}</td>
      <td>
        <span *ngIf="getNota(n.id)?.aprovado==1" class="text-primary">OK</span>
        <span *ngIf="getNota(n.id)?.aprovado==2" class="text-danger">PF-CORRIJA!</span>
        <span *ngIf="!getNota(n.id)?.aprovado" class="text-warning">AGUARDE</span>

      </td>
      </tr>

    </tbody>
  </table>
</div>
</div>
</div>
