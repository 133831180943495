import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.css']
})
export class NotasComponent implements OnInit {
  formFilter:FormGroup|any
  turma_id=''
  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  notasEstudante:any
  ano_avaliacoes:any
  data_ini:Date=new Date()
  data_fim:Date=new Date()
  disciplinas:any
  preDisciplinas:any
  sistemaAvalicao:any

  selectedCar: number=0
  turmas:any
  edu_turma_id:number=0
  cursos:any
  anos:any
  ano_id:number=0
  edu_curso_id:number=0
  edu_turmas: any
  isNotaViewData: any;
  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
  private PDFService:ServicePDFjsPDFService,
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService,
    private fb:FormBuilder,
    private config: NgSelectConfig,
    private toastr: ToastrService) {
      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      // set the bindValue to global config when you use the same
      // bindValue in most of the place.
      // You can also override bindValue for the specified template
      // by defining `bindValue` as property
      // Eg : <ng-select bindValue="some-new-value"></ng-select>
      this.config.bindValue = 'value';
    }
alertMe(e:any){


}
  ngOnInit(): void {
    this.getTurmas()
    this.formFilter=this.fb.group({
      idturma:[null]
    })
this.getIsNotaView()
   // this.getDisciplina()
    this.setTitle()
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
  }

  getNota(edu_sistema_avaliacoes_curso_id:string,edu_disciplina_id:string) {

try{
//console.log(edu_disciplina_id+" " +edu_sistema_avaliacoes_curso_id)
//console.log(this.notasEstudante)
return this.notasEstudante.find((n: { edu_sistema_avaliacoes_curso_id: string; edu_disciplina_id: string; })=>n.edu_sistema_avaliacoes_curso_id==edu_sistema_avaliacoes_curso_id&&n.edu_disciplina_id==edu_disciplina_id).nota

}
catch(ex){

}
  }
   getsistemaAvalicao(){

    this.repo.getAll('/sistemaavaliacao/'+this.edu_curso_id).subscribe((res)=>{

       this.sistemaAvalicao=res.sistemaavaliacao
  //console.log( this.ano_avaliacoes)


    })

   }
   getnotasEstudante(ev:any){
    //console.log(ev)

    this.repo.getAll('/notasestudantes/'+this.edu_turma_id).subscribe((res)=>{
      const data= res.notasestudante
      
      //const items=[... new Map(data.map((item:{edu_matriz_curriculares_disciplina_id:number})=>[item.edu_matriz_curriculares_disciplina_id])).values()]
      const items = this.removerDuplicatasPorPropriedade(data, 'edu_matriz_curriculares_disciplina_id');

      this.notasEstudante=items
       this.disciplinas=this.preDisciplinas
       this.dataTableInit()
  


    },
    err => {
      this.toastr.error(err.error.message)
      //console.log(err.error.message)
    })
 }
   async getDisciplina(){

    this.repo.getAll('/disciplinas/'+this.edu_curso_id+"/"+this.ano_id).subscribe((res)=>{

       this.preDisciplinas=res.disciplinas
  //console.log( this.preDisciplinas)


    })
   }
   async getTurmas(){

    this.repo.getAll('/turmasestudante').subscribe((res)=>{

       this.turmas=res.turmasestudante
       this.cursos = [...new Map(this.turmas.map((item: { curso: any; }) => [item.curso, item])).values()]
       this.anos = [...new Map(this.turmas.map((item: { ano: any; }) => [item.ano, item])).values()]
  //console.log(  this.turmas)


    })
   }
   async getIsNotaView(){

    this.repo.getAll('/isnotas-view').subscribe((res)=>{

       this.isNotaViewData=res.data

  //console.log(  this.turmas)


    })
   }
   dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  setTitle(){
    this.titleService.setTitle("Notas do estudante ")
  }

 getTurmasFilter(){
  try{
   let data= this.turmas.find((v: { edu_curso_id: string;edu_ano_id:string})=>v.edu_curso_id==this.edu_curso_id.toString()&&v.edu_ano_id==this.ano_id.toString())
     //console.log( data)
     this.edu_turmas=data
  }
  catch(ex){

  }
  }


  calcularMGA(n:any){
    try{

return this.PDFService.calcularMGA(n)
    }
    catch(ex:any){
      return 0
    }
  }

  calcularRFINAL(n:any){
    try{
 return this.PDFService.calcularRFINAL(n)
    }
    catch(ex:any){
      return '-'
    }
  }

  calcularNF(n:any){
    try{
 return this.PDFService.calcularNF(n)
    }
    catch(ex:any){
      return '-'
    }
  }
  Obs(n:any){
    try{
 return this.PDFService.Obs(n)
    }
    catch(ex:any){
      return '-'
    }
  }
  getNotaFinal(n:any){
    try{
      const mac=this.PDFService.calcularMF(n)
      return mac
          }
          catch(ex:any){
            return 0
          }
  }
  removerDuplicatasPorPropriedade(arr: SeuTipoDeDados[], propriedade: keyof SeuTipoDeDados): SeuTipoDeDados[] {
    const conjuntoValoresUnicos = new Set();

    return arr.filter(item => {
        const valor = item[propriedade];
        if (!conjuntoValoresUnicos.has(valor)) {
            conjuntoValoresUnicos.add(valor);
            return true;
        }
        return false;
    });
}
  }

  
 
interface SeuTipoDeDados {
    id: number;
    edu_matriz_curriculares_disciplina_id: number;
    // ... outras propriedades
}