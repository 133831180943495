
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">
                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">Curso*</label>
                            <ng-select [(ngModel)]="edu_curso_id">
                              <ng-option *ngFor="let c of cursos" [value]="c?.edu_curso_id">{{c?.curso}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Anos*</label>
                          <ng-select [(ngModel)]="ano_id" (change)="getDisciplina()">
                            <ng-option *ngFor="let a of anos" [value]="a?.edu_ano_id">{{a?.ano}}</ng-option>
                          </ng-select>
                      </div>
                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">Turmas*</label>
                            <ng-select [(ngModel)]="edu_turma_id"  (change)=" getnotasEstudante($event)">
                              <ng-option *ngFor="let t of turmas" [value]="t?.edu_turma_id">{{t?.name}}</ng-option>
                            </ng-select>
                        </div>


                        </div>
                          <div class="container-fluid">

                                <hr>
                        <!--  <button class="btn btn-primary"   title="Carregar">Carregar</button>
                            -->
                          </div>





                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>

    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th with="5%">ID</th>
          <th with="30%">Disciplina</th>
          <th with="5%">1ª PP</th>
          <th with="5%">2ª PP</th>
          <th with="5%">MAC</th>
          <th with="5%">MÉDIA GERAL</th>
          <th with="5%">EXAME</th>
          <th with="10%">MÉDIA FINAL</th>
          <th with="8%">RESULTADO FINAL</th>
         <th with="5%">RECURSO</th>
         <th with="8%">NOTA FINAL</th>
         <th with="8%">EXAME ESPECIAL</th>

         <th with="8%">SITUAÇÃO ACADÉMICA</th>
         <th with="8%">DAAC</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of notasEstudante index as i">
          <td >{{i+1}}</td>
          <td >{{d?.disciplina}}</td>
          <td>
        <span>{{d?.pp1 || 0}}</span>
      </td>
      <td><span>{{d?.pp2 || 0}}</span>
      </td>
      <td><span>{{d?.mac || 0}}</span></td>

      <td><span>{{  calcularMGA(d)|| 0}}</span>
      </td>
      <td><span>{{d?.exame || 0}}</span>
      </td>
      <td><span >{{getNotaFinal(d) || 0}}</span></td>
      <td><span>{{calcularRFINAL(d)}}</span>
      </td>

      <td><span *ngIf="isNotaViewData?.recurso">{{d?.recurso || 0}}</span>
        <span *ngIf="!isNotaViewData?.recurso" class="text-danger">Lamentámos, de momento não pode visualizar o Exame de Recurso. Consulte ao DAAC, para mais exclarecimento.</span>
      </td>
      <td><span *ngIf="isNotaViewData?.recurso"> {{calcularNF(d)}} </span><span *ngIf="!isNotaViewData?.recurso" class="text-danger">Lamentámos, de momento não pode visualizar o Exame de Recurso. Consulte ao DAAC, para mais exclarecimento.</span></td>

      <td><span>{{d?.exame_especial || 0}}</span>
      </td>
      <td><span *ngIf="isNotaViewData?.recurso">{{Obs(d)}} </span><span *ngIf="!isNotaViewData?.recurso" class="text-danger">Lamentámos, de momento não pode visualizar o Exame de Recurso. Consulte ao DAAC, para mais exclarecimento.</span></td>
      <td>
        <span *ngIf="d?.aprovado==1" class="text-primary">OK</span>
        <span *ngIf="d?.aprovado==2" class="text-danger">PF-CORRIJA!</span>
        <span *ngIf="!d?.aprovado" class="text-warning">AGUARDE</span>

      </td>






        </tr>

      </tbody>
    </table>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
