import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { jsPDF } from "jspdf";
import autoTable, { Row } from 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class DeclaracaoService {

  constructor() { }

  fileType = 'application/pdf';
  fileExtension = '.pdf';


  public savePdfFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  getNumberFormatMoney(value:string) {
    //return " " +  Number.parseFloat(value).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1.");
    //return   'AOA' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "1,");
    return Number.parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

  }
  getMes(value:number) {
    switch (value) {
      case 1: return 'Janeiro'; break
      case 2: return 'Fevereiro'; break
      case 3: return 'Março'; break
      case 4: return 'Abril'; break
      case 5: return 'Maio'; break
      case 6: return 'Junho'; break
      case 7: return 'Julho'; break
      case 8: return 'Agosto'; break
      case 9: return 'Setembro'; break
      case 10: return 'Outubro'; break
      case 11: return 'Novembro'; break
      case 12: return 'Dezembro'; break
      default: ""
    }
    return ''
  }

  public criarDeclaracaoFrequencia(documento: any, instituicao: any): any {





    console.log(documento)
    const tipo_nivel = documento.nivel_academico

    console.log(documento)
    console.log(instituicao)
    console.log("documento imprimir fichar")
    const doc = new jsPDF();

    let HTML_Width = doc.internal.pageSize.width;
    let HTML_Height = doc.internal.pageSize.height;
    let top_left_margin = 15;
    let PDF_Width = HTML_Width + (top_left_margin * 2);
    let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);

    let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    const registos = 5
    const height_space = 5
    let height = 40
    const height2 = 50
    const margin_left = 20
    let texto = ""
    const texto1 = ""
    const texto2 = ""


    console.log(doc.getFontList())
    doc.setFont("zapfdingbats", "");
    doc.setFontSize(10);


    const logotipo_largura = instituicao[0].logotipo2_largura
    const logotipo_altura = instituicao[0].logotipo2_altura
   const headerPNG = instituicao[0].logotipo2
  doc.addImage(headerPNG, "PNG", margin_left, 1, logotipo_largura, logotipo_altura);

    //doc.addImage("assets/img/logo_isppm.png", "PNG", margin_left, 5, 170, 50);
let maisLeft=15;


    doc.setFontSize(8);
    height = height +15
    doc.setFont("zapfdingbats", "bold");
    doc.text("O Visto do ", 20+maisLeft, height, { align: 'left' });
    height = height + 3
    doc.text("Director Geral", 17+maisLeft, height, { align: 'left' });
    doc.setFont("zapfdingbats", "");
    height = height + 7
    doc.text("________________________________", 5+maisLeft, height, { align: 'left' });
    height = height + 3
    doc.text("Prof. Doutor Faustino Wilson M. Alves. ", 5+maisLeft, height, { align: 'left' });
    height = height + 3
    doc.text("(Professor Associado) ", 15+maisLeft, height, { align: 'left' });
    height = height + 5


    doc.setFont("Helvetica", "bold");
    doc.setFontSize(20);
    doc.text("DECLARAÇÃO", 110, height, { align: 'center' });




    const nome ='Rodrino Adolfo Kupessala'.toUpperCase()

    const pai = 'Gonçalvez Muyemba Kupessala'
    const mae = 'Alda Victoria Cuyola Adolfo'
    const naturalidade = 'Caala'
    const provincia = 'Huambo'
    let data_nascimento='11.05.55'
    data_nascimento = ''//data_nascimento.substring(8, 10) + "-" + data_nascimento.substring(5, 7) + "-" + data_nascimento.substring(0, 4);
    const bi = '57745467c000'
    const endereco = 'Menongue'
    const municipio_residencia = 'Menongue'
    const provincia_residencia = ''
    const curso = ''
    const ano_frequencia = '5'
    const periodo = 'Manhã'
    const turma = 'A5'
    const ano_lectivo = '2022/2023'
    const numero_processo = '80954'


    const efeito_documento = ' Para efeitos legais:'

    const efeito_documento_descricao ='BOLSA DE ESTUDOS'


    doc.setFontSize(12);
    doc.setFont("zapfdingbats", "");
    height = height + 10
    doc.text(efeito_documento, margin_left, height);
    height = height + 10


    texto = doc.splitTextToSize("Declara - se que " + nome + ", filho de " + pai + " e de " + mae + ", natural do " + naturalidade + ", Província de " + provincia + ", nascido aos " + data_nascimento + ", Portador do Bilhete de Identidade Nº " + bi + ", residente em  " + endereco + " Município de " + municipio_residencia + "." + "", 170)

    doc.text(texto, margin_left, height, { maxWidth: 170, align: 'justify' });
    height = height + 20

    texto = doc.splitTextToSize("É estudante desta Instituição no curso de Licenciatura em " + curso + ", matriculado no " + ano_frequencia + ", Período " + periodo + ", Turma: " + turma + ", do Ano académico " + ano_lectivo + ", sob o nº " + numero_processo + ". " + "", 170)

    doc.text(texto, margin_left, height, { maxWidth: 170, align: 'justify' });
    height = height + 20


    texto = doc.splitTextToSize("Por ser verdade e me ter sido solicitado, foi passada a presente declaração assinada e autenticada com carimbo a óleo em uso nesta Instituição. ==============================================================================================================" + "", 170)

    doc.text(texto, margin_left, height, { maxWidth: 170, align: 'justify' });
    height = height + 15


    texto = doc.splitTextToSize("\n" + "Obs.: A presente declaração destina-se para efeito de trabalho. =================================================================================================", 170)

    doc.text(texto, margin_left, height, { maxWidth: 170, align: 'justify' });


    height = height + 20
    doc.setFontSize(8);



    this.table(doc,height,margin_left,instituicao)
   // let pcount=Math.ceil(doc.internal.pageSize.getHeight()/)
    let y=500;
    if(y>doc.internal.pageSize.height){
      doc.addPage()
      y=0
    }

    height = height + 5;
  //  doc.text(ano, margin_left, height, { align: 'left' });



   /* for (let i = 1; i <= totalPDFPages; i++) {
      doc.addPage([PDF_Width, PDF_Height], 'p');

    }*/


    doc.setFont("zapfdingbats", "bold");

    const data_hoje = new Date()
    const mes = data_hoje.getMonth();
    height = height + 50
     doc.setFontSize(10);
    doc.text("INSTITUTO SUPERIOR POLITÉCNICO PRIVADO DE MENONGUE, em Menongue, aos " + data_hoje.getDate() + "  de " + this.getMes(mes + 1) + " de " + data_hoje.getFullYear() + " ", margin_left, height);



    doc.setFont("zapfdingbats", "bold");
    doc.setFontSize(10);

    height = height + 15
    doc.text("A Directora Geral Adjunta Para os Assuntos ", 100, height, { align: 'center' });

    height = height + 5
    doc.text("Académicos", 100, height, { align: 'center' });

    height = height + 10
    doc.text("_________________________________________________", 100, height, { align: 'center' });

    height = height + 5
    doc.text("Msc. Lívia Maria Robles Alfonso. ", 100, height, { align: 'center' });
    const  imgSampleData = instituicao[0].logotipo_rodape

    doc.setFont("zapfdingbats", "bold");
    doc.setFontSize(12);

    doc.text("Nº" +  "", margin_left, 268, { align: 'left' });

    doc.setTextColor(255, 0, 0);
    doc.text("" +  + "", margin_left+5, 268, { align: 'left' });

    doc.setTextColor(0, 0, 0);
    doc.text( "/DAAC/ISPPM/CC.", margin_left+25, 268, { align: 'left' });


    height = 270
    doc.text("____________________________________________________________________________________" +  "", margin_left, 268+6+2+1+2, { align: 'left' });
    doc.text("____________________________________________________________________________" +  "", margin_left+6, 268+10+2, { align: 'left' });

    height = height + 3
    doc.addImage(imgSampleData, "PNG", margin_left, height+9, 180 , 20 );


    let pagina=1
  //this. rodape( doc, height, margin_left,instituicao)
   doc.save('declaracao_frequencia_'  + ".pdf"); // Generated PDF
   let base64data = doc.output('datauri'); // directly to base664

   let blobdata = doc.output('blob');

   return blobdata
    /*  doc.html(document.body,{
        x:0,
        y:0,
        autoPaging:'text',
        margin:[700,0,700,0],
        callback:()=>{
            doc.save('tetx.pdf')
        },
      })*/
  }



  rodape( doc:any , margin_left:number,height:number,instituicao:any) {


    doc.setFont("zapfdingbats", "bold");
    doc.setFontSize(12);

    doc.text("Nº" +  "", margin_left, 268, { align: 'left' });

    doc.setTextColor(255, 0, 0);
    doc.text("" +  + "", margin_left+5, 268, { align: 'left' });

    doc.setTextColor(0, 0, 0);
    doc.text( "/DAAC/ISPPM/CC.", margin_left+25, 268, { align: 'left' });


    height = 270
    doc.line(margin_left, height, 190, height);
    height = height + 3



  }

  table(doc:any,height:number,margin_left:number,instituicao:any){
    var y = 10;
    doc.setLineWidth(2);
    //doc.text(100, y = y + 30, "u");
    autoTable(doc, { html: '#tblRosoft '
  /*  autoTable(doc, {
      head: [['Name', 'Email', 'Country']],
      body: [
                     // ...
      ],
*/,
      startY: 150,
    //  theme: 'plain',
      //textColor:[0,0,0],
        headStyles:{
            fillColor:[255,255,255],
            textColor:[0,0,0],


        },
          columnStyles:{
              'ID':{textColor:[0,0,0],fillColor:[255,0,0]}
          },
      didDrawPage: function (data) {

        // Header
        data.settings.margin.bottom=20;
        doc.setFontSize(8);
        doc.setTextColor(40);
     //   doc.text("Report", data.settings.margin.left, 22);

        // Footer
        var str = "Nº 1002 /DAAC/ISPPM/CC./2022   " //+ doc.internal.getNumberOfPages();

        doc.setFontSize(8);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

          doc.setFont("zapfdingbats", "bold");
          doc.setFontSize(12);



          doc.text('________________________________________________________________________________________', data.settings.margin.left, pageHeight - 16);
          doc.text('           ____________________________________________________________________', data.settings.margin.left+6, pageHeight - 15);

           doc.text(str, data.settings.margin.left, pageHeight - 19);
          const  imgSampleData = instituicao[0].logotipo_rodape
          const   logotipo_largura=  instituicao[0].logotipo_rodape_largura
          const   logotipo_altura = instituicao[0].logotipo_rodape_altura
          doc.addImage(imgSampleData, "PNG", data.settings.margin.left, pageHeight - 14,logotipo_largura,logotipo_altura);

          //doc.text('________________________________________________________________________________________', data.settings.margin.left, pageHeight - 7);
   /*     doc.setTextColor(255, 0, 0);
        doc.text("Nº .", data.settings.margin.left, pageHeight - 10);
        doc.setTextColor(0, 0, 0);

      doc.text("/DAAC/ISPPM/CC.", data.settings.margin.left, pageHeight - 10);
      doc.line( data.settings.margin.left, pageHeight - 10);*/
      }
    },)


  }
}
