import { Injectable } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser'
import { API_URLService } from 'src/app/URL.service';
@Injectable({
  providedIn: 'root'
})
export class TitleService {
    title="PORTAL ISPPM-1.0| "
    favicon:HTMLLinkElement |any =document.querySelector("#faviIcon")
  constructor(private titleService:Title,private meta:Meta,private url:API_URLService) { }
  setTitle(sectionTile:string){

    this.titleService.setTitle(`${this.title} | ${sectionTile}`);

    this.meta.addTag({
      name: this.title,
      content: `${this.title} | ${sectionTile}`
    });
    this.meta.updateTag(
      {
        name: 'description',
        content: `${this.title} | ${sectionTile}`

      });
      this.favicon.href=this.url.getHOST()+"/assets/favicon.png"
  }

}
