import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';

@Component({
  selector: 'app-pagamentos',
  templateUrl: './pagamentos.component.html',
  styleUrls: ['./pagamentos.component.css']
})
export class PagamentosComponent implements OnInit {


  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  pagamentosResumo:any
  ano_avaliacoes:any
  data_ini:Date=new Date()
  data_fim:Date=new Date()
  totalGeral=0
  //dtElement: DataTableDirective;
  constructor( //private pagamentosService:PagamentosService
    private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService) {

    }
  meses:any
  anoSelecionado=0
    getMeses:any

 data:any
Pagamentos:any
 PredadosPagamentos:any
pagamentosBrutos:any
Av={
  id:null,
  name:null
}

    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
     this. getAnos()
    //Loading child components
    this.loadingChildComponents();
    this.loadingData();

   this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };
      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("Pagamentos de "+this.data_ini+" à "+this.data_fim)
    }
    loadingChildComponents(){
      //this.modalComponent=UserAddComponent
    }
getPreco(mes:string,id_estudadente:string){
  var preco="0"


try{
  var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
    return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente;
   });
  if(pagamento[0].preco)
  {
  preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
  }
}
catch(ex){

}
   return preco
}
seTotal(n:number){



try{
   this.totalGeral=  this.totalGeral+n
}
catch(ex){

}

}


 async getAnos(){

  this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
     this.data=res;

     this.ano_avaliacoes=this.data.ano_avaliacoes
console.log( this.ano_avaliacoes)


  })
 }

 async getPagamentos(e:number){

  this.ngOnDestroy()

//this.data_ini=parseInt(e.target.value)
  this.repo.getAll('/pagamentos/'+this.data_ini+"/"+this.data_fim+"/"+e).subscribe((res)=>{
     this.Pagamentos=res.pagamentos;
     this.totalGeral=res.totalSum
     this.dataTableInit()

     console.log(res)
     console.log(this.Pagamentos)
  })
  this.setTitle()
 }
 loadingData(){
  this.getMeses=this.meses

  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }

 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}
dataTableInit() {
  if (this.isDtInitialized) {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  } else {
    this.isDtInitialized = true;
    this.dtTrigger.next();
  }
}



}
