
    <div class="row formavlidation-wrapper  ">
      <div class="col-12 col-lg-12">
        <div class="accordion" id="accordionsimplefill">
          <div class="mb-2 acd-group">
            <div class="card-header rounded-0 bg-primary">
              <h5 class="mb-0">
                <a href="#collapse2" class="btn-block text-white text-left acd-heading collapsed"
                  data-toggle="collapse">+</a>
              </h5>
            </div>
            <div id="collapse2" class="collapse" data-parent="#accordionsimplefill">
              <div class="row">
                <div class="col-12 col-lg-12">

                  <div class="card card-statistics">
                    <div class="card-body">

                      <fieldset>
                        <div class="form-row">



                            <div class="col-md-2 col-sm-12">
                              <label class="control-label">Ano Avaliação *</label>
                              <ng-select [(ngModel)]="ano_avaliacoes_id" (change)=getCursos()>
                                <ng-option *ngFor="let anos of ano_avaliacoes" [value]="anos.id" >{{anos.name}}</ng-option>
                              </ng-select>




                          </div>
                          <div class="col-md-2 col-sm-12">
                            <label class="control-label">Curso *</label>
                            <ng-select [(ngModel)]="curso_id" (change)=getTurmas()>
                              <ng-option *ngFor="let curso of cursos" [value]="curso.id" >{{curso.name}}</ng-option>
                            </ng-select>

                        </div>

                        <div class="col-md-2 col-sm-12">
                          <label class="control-label">Turma *</label>
                          <ng-select [(ngModel)]="turma_id" (change)="getJSONEstudantes()">
                            <ng-option *ngFor="let turma of turmas" [value]="turma.id" >{{turma.name}}</ng-option>
                          </ng-select>

                      </div>
                          <div class="container-fluid">

                                <hr>
                          <button (click)="getJSON()" class="btn btn-primary" title="Carregar">Carregar</button>

                          <button (click)="generatePdf(contentToConvert)" class="btn btn-primary" *ngIf="isPDF">PDF</button>
                             <hr> <input [(ngModel)]="namePDF" name="namePDF" type="text" class="form-control" placeholder="Informe o nome para o pdf">
                            </div>



                        </div>

                      </fieldset>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<hr>
<fieldset  #contentToConvert>
    <!--<table datatable [dtOptions]="dtOptions" class="row-border hover"></table> -->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  id="tblRosoft" class="table table-bordered table-striped table-hover table-responsive" >
      <thead>
        <tr>
          <th width="10%">ID</th>
          <th width="10%">Nivel</th>
          <th width="10%">Nome</th>
          <th width="10%">Curso</th>
          <th width="10%">Turma</th>

          <th width="10%" *ngFor="let mes of getMeses">{{mes.name}}</th>
          <th width="10%">Total Pago</th>
          <th width="10%">Diferença</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pag of dataEstudantes index as i">
          <td >{{i+1}}</td>
          <td >{{pag?.nivel}}</td>
          <td >{{pag.nome}}</td>
          <td >{{pag.curso}}</td>
            <td>{{pag.turma}}</td>

            <td *ngFor="let m of getMeses"> {{getPreco(m.name,pag.edu_estudante_id)}}</td>
            <td>{{getTotal(pag.edu_estudante_id)}}</td>
            <td>{{getDiferenca(pag.edu_estudante_id)}}</td>
        </tr>

      </tbody>
    </table>

    <div class="container" id="MyDIv">
      <hr>
      <div style="text-align: center;" >

        <p>TOTAL ESTUDANTES:{{quantidadeGeral/10}} PREVISÃO TOTAL DOS MESES A PAGAR:{{quantidadeGeral}}</p>
        <p><strong>FÓRMULA :</strong> TOTAL_ESTUDANTES <strong>X</strong> 10 MESES={{quantidadeGeral}} MESES</p>
          <h2> PREVISÃO DE PAGAMENTOS:{{formatNumber(totalPrevisto)}} AO</h2>
      </div>
      <table class="table table-bordered table-striped">
        <thead width="100%">
          <tr>
            <th width="50%">Curso</th>
          <th width="25%">Quantidade</th>
          <th width="25%">Total</th>
          <th width="25%">Diferença Quantidade</th>
          <th width="25%">Diferença Total</th>
          </tr>
        </thead>
        <tr  *ngFor="let res of resumoPorCurso">
          <td>{{res?.curso}}</td>
          <td>{{res?.quantidade}}</td>
          <td class="alert-primary">{{formatNumber(res?.total)}} AO</td>
          <td class="alert-danger">{{formatNumber(res?.quantidade -quantidadeGeral)}}</td>
          <td class="alert-danger">{{formatNumber(res?.total -totalPrevisto)}} AO</td>
        </tr>
      </table>
    </div>
  </fieldset>
    <app-modal [modalComponent]="modalComponent" [iconLink]="iconLink"></app-modal>
