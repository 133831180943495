import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/repository/local/token-storage/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
 menu:any
  constructor(private repo:TokenStorageService) {
    this.menu=this.repo.getRoles()
   // console.log(this.menu)
  }

  ngOnInit(): void{

  }
}
