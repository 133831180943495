import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

@Component({
  selector: 'app-docentes-turmas',
  templateUrl: './docentes-turmas.component.html',
  styleUrls: ['./docentes-turmas.component.css']
})
export class DocentesTurmasComponent implements OnInit {

   notas: any;
  edu_matriz_curriculares_disciplina_id=0
  edu_disciplina_id=0
  ano_avaliacao_id: any;
  pedido: any;
  edu_turma_id: any;

  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
edu_curso_id=0
isPut=5
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
putData:any


provaTipo=''
  docentes: any;
disciplinasDocentes:any



  constructor(private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService,private router:Router) { }

  ngOnInit(): void {

    this.user=this.authTorage.getUser()
  this.user_id=parseInt(this.user.user_assoc)
    this.getAnos()
    this.getIsPut()
    this.initPaginate()
    //this.getSac()
    this.toastr.warning("Preencha devidamente os campos necessários! Pois, aqui o docente irá importar as notas usando um ficheiro modelo fornecido pelo DAAC.", '!');

  }


  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  async getDistribucaoTurmas(){

    try{
      this.repo.getAll('/distribuicao-turmas/'+this.ano_avaliacao_id).subscribe((res)=>{


        this.distribuicaoturmas=res.distribuicaoturmas
     this. docentes = [...new Map(this.distribuicaoturmas.map((item: { nome: any; }) => [item.nome, item])).values()]

     this.dataTableInit()


     },err=>{
      if(err.status==403){
        this.toastr.error("Não permitido!")
      }
     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   verPauta(url:string,user_id:number,edu_turma_id:number,edu_disciplina_id:number){
    const btnModalClose = document.getElementById('btnModalClose');
    if (btnModalClose) {
      btnModalClose.click();
    }

    this.router.navigate([`/${url}/${user_id}/${edu_turma_id}/${edu_disciplina_id}`])

   }
   async getDisciplinasPorturma(){

    try{
      this.repo.getAll('/disciplinasdocente/'+this.ano_avaliacao_id+'/'+this.edu_turma_id).subscribe((res)=>{


        this.disciplinas=res.disciplinas

   console.log( this.disciplinas)


     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   getTurmas(){
  try{
    this.edu_turmas= this.distribuicaoturmas.find((v: { edu_curso_id: string;})=>v.edu_curso_id==this.edu_curso_id.toString())
    console.log(this.edu_turmas)
  }
  catch(ex){

  }
  }
   async getAnos(){

    try{
      this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
        this.data=res;

        this.ano_avaliacoes=this.data.ano_avaliacoes
   console.log( this.ano_avaliacoes)


     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   initPaginate(){
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  async getIsPut(){

    try{
      this.repo.getAll('/is-put/').subscribe((res)=>{


        this.isPut=res?.put
  //this.dataTableInit()
  this.putData=res?.result
   console.log( res.result)


     })
    }
    catch(ex){
      console.log(ex)
    }
  }
  verDisciplinas(edu_docente_id:number){
try{
 this.disciplinasDocentes= this.distribuicaoturmas.filter((d: { edu_docente_id: number; })=>d.edu_docente_id==edu_docente_id)
}
catch(ex){}
  }

}
