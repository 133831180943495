import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MenuService } from 'src/app/services/base/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private menuService:MenuService,private repo:TokenStorageService) { }
Menu:any
  ngOnInit(): void {
 this.Menu=   this.menuService.menu
  }
  logout(){
this.repo.signOut()
  }
}
