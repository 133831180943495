import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { MiniPautaService } from 'src/app/services/base/excel/mini-pauta.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';

@Component({
  selector: 'app-recupreaca-pauta',
  templateUrl: './recupreaca-pauta.component.html',
  styleUrls: ['./recupreaca-pauta.component.css']
})
export class RecupreacaPautaComponent implements OnInit {

  data_header_mini_pauta={
    ano_academico:'2021/2022',
    curso:"Engenharia Informática",
  }
  ano_nivel_id=0
  notas: any;
  notastmp: any;
  notastmpPorEstudante: any;
  edu_matriz_curriculares_disciplina_id=0
  edu_disciplina_id=0
  ano_avaliacao_id: any;
  pedido: any;
  edu_turma_id: any;

  ModalLink=''
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
data:any
pauta:any=[]
pauta1:any=[]
distribuicaoturmas:any
edu_cursos:any
edu_turmas:any
ano_avaliacoes:any
disciplinas:any
dataEstudantes:any
sistemaCurso:any
notaPautas:any
PredataEstudantes:any
lsitaEstudantes:any
edu_curso_id=0
isPut=5
edu_sistema_avaliacao_id=0
user:any
user_id:number=0
putData:any


provaTipo=''
podeLancarNota=[
  {id:1,name:'resultado_final'},
 /* {id:2,name:'pp2'},
  {id:3,name:'mac'},
  {id:4,name:'mg'},*/

]
  habilitarEdicao: boolean=false;
  niveis: any;


  constructor(private excel:MiniPautaService,private PDFService:ServicePDFjsPDFService,private repo:HttpRepoService,private toastr: ToastrService,private authTorage:TokenStorageService) { }

  ngOnInit(): void {
//this.excel.mini_pauta()
    this.user=this.authTorage.getUser()
  this.user_id=parseInt(this.user.user_assoc)
    this.getAnos()
    this.getNiveis()
    this.getIsPut()
    this.initPaginate()
    //this.getSac()
    this.toastr.warning("Preencha devidamente os campos necessários! Pois, aqui o docente irá importar as notas usando um ficheiro modelo fornecido pelo DAAC.", '!');

  }

  dataTableInit() {
    if (this.isDtInitialized) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.isDtInitialized = true;
      this.dtTrigger.next();
    }
  }
  notasPorTurm(ev:any){
    try{
      this.habilitarEdicao=false
      this.notastmpPorEstudante=[]
      this.notas=[]
      this.notas=this.notastmp
     this.notastmpPorEstudante= this.notastmp.filter((n: { edu_disciplina_id: any; })=>n.edu_disciplina_id==this.edu_disciplina_id)

     console.log(this.notastmpPorEstudante)
    }
    catch(ex:any){

    }
  }
  getNota(edu_matricula_id:Number){
    try{


      const data=this.notas.find((n: { edu_disciplina_id:number;edu_matricula_id:number; })=>n.edu_disciplina_id==this.edu_disciplina_id&&n.edu_matricula_id==edu_matricula_id)
 console.log(data)
     return  data

     }
     catch(ex:any){

return {
  pp1:0,
  pp2:0,
  mac:0,
  mga:0,
  exame:0,
  recurso:0,
  exame_especial:0,
  edit:false
}
     }
  }
  async getEstudantes(){

    try{
      this.repo.getAll('/estudantesdocentes/'+this.edu_turma_id).subscribe((res)=>{


        this.PredataEstudantes=res.estudantes
  //this.dataTableInit()



     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   async getNotas(){

    try{
      this.repo.getAll('/lista-notas/'+this.edu_turma_id+'/'+this.edu_disciplina_id).subscribe((res)=>{
console.log(res)
  this.notas=res.notas
  this.notastmp=this.notas
  this.lsitaEstudantes=this.PredataEstudantes
  this.habilitarEdicao=true

    // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]

//this.dataTableInit()

     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   salvar(data:any){

    try{
      this.repo.Add(data,'/salvar-nota').subscribe((res)=>{

if(res.nota){
this.toastr.success("Noata salva, Aguarde ao feed back do DAAC!")
}else{
  this.toastr.error(res.message)
}


    // this. edu_cursos = [...new Map(this.distribuicaoturmas.map((item: { curso: any; }) => [item.curso, item])).values()]



     })
    }
    catch(ex){
      console.log(ex)
      this.toastr.error("Umerro sucedeu!")
    }
   }


  lancar_nota(ev:any,tipo_prova:string,n:any,estudante:any){
try{
let id_nota=0
const nota=parseFloat(ev.target.value)
if(n!=undefined){

  id_nota=n.id
  //Calcualr média geral
 if(n?.pp1){
  const mga=(n?.pp1+n?.pp2+n?.mac)/3
  const mf=(n?.pp1+n?.pp2+n?.mac)/3
  n.mga=mga.toFixed(1)
}

}
//Verificar se a nota é valida
if(nota < 0 || nota>20){
  throw new Error("A nota deve estar entre 0 e 20!")
}
//PASSR VALOR AO SISTEMA DE AVALIAÇÃO

if(n?.pp1){
  n[tipo_prova]=nota
}

 let data=this.setSistema(nota,tipo_prova,id_nota,estudante)
 this.salvar(data)
 console.log(data)
 estudante.isrow=''


}
catch(ex:any){

this.toastr.error(`Messangem:Ocorreu um erro, informe valores corretos!
                  - Siga as instruções;
                  - A nota deve estar entre 0 e 20!;

                  Se ainda assim persistir, contacte o administrador.`)
}
  }
  setSistema(nota:any,tipo_prova:string,id_nota:number,estudante:any){
    try{
    /*  if(this.edu_disciplina_id!=n?.edu_disciplina_id){

        n.edu_disciplina_id=this.edu_disciplina_id
      }*/
      let data:any
      data=  {
        id:id_nota,
        edu_matricula_id:estudante?.id,
        edu_matriz_curriculares_disciplina_id:this.edu_matriz_curriculares_disciplina_id,
        edu_turma_id:this.edu_turma_id,
        edu_estudante_id:estudante?.edu_estudante_id,
        edu_disciplina_id:this.edu_disciplina_id,
        ano_avaliacao_id:this.ano_avaliacao_id,
        pedido:this.edu_disciplina_id+ new Date().getTime(),
        [tipo_prova]:nota,
        exame_especial:0,
        user_id:this.user_id,
        //resultado_final:0,
        situacao_academica:''
      }
      console.log(data)
    return data

    }
    catch(ex){
    return null
    }
      }
  async getDistribucaoTurmas(){

    try{
      this.repo.getAll('/cursos/').subscribe((res)=>{


        this.edu_cursos=res.cursos


     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   async getDisciplinasPorturma(){

    try{
      this.lsitaEstudantes=[]
      this.repo.getAll('/disciplinas/'+this.edu_curso_id+'/'+this.ano_nivel_id).subscribe((res)=>{


        this.disciplinas=res.disciplinas

       this.getEstudantes()




     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   async getTurmas(){

    try{
      this.repo.getAll('/turmas'+"/"+this.ano_avaliacao_id+"/"+this.edu_curso_id).subscribe((res)=>{


        this.distribuicaoturmas=res.turmas



     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   async getAnos(){

    try{
      this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
        this.data=res;

        this.ano_avaliacoes=this.data.ano_avaliacoes
   console.log( this.ano_avaliacoes)


     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   async getNiveis(){

    try{
      this.repo.getAll('/niveis').subscribe((res)=>{
        this.niveis=res.niveis;




     })
    }
    catch(ex){
      console.log(ex)
    }
   }
   initPaginate(){
    this.dtOptions = {
      pagingType: 'full_numbers'
      , pageLength: 10,
      lengthMenu: [ 25,50, 100, 1000],
       processing: true,
      dom: 'Bfrtip',
     // retrieve:true,


    };

  }
  async getIsPut(){

    try{
      this.repo.getAll('/is-put/').subscribe((res)=>{


        this.isPut=res?.put
  //this.dataTableInit()
  this.putData=res?.result
   console.log( res.result)


     })
    }
    catch(ex){
      console.log(ex)
    }
  }
  send(){

  }
  edit(n:any,tipo:string){
  try{
    const tipoAutorizado=this.podeLancarNota.find(pL=>pL.name==tipo)?.name+n?.id
    if(tipoAutorizado!=tipo+n?.id){
      this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
    }
  else{
    //verifcar edição ii
    if(! this.habilitarEdicao){
      this.toastr.error("Primeiro clique em consultar, pois, a disciplina foi alterada!")
    }
    else{
      n.isrow=tipo+n?.id
    }

  }
  }
  catch(ex:any){
    console.log("ERRO SA:",ex.message)

    this.toastr.error("Não é possível habilitar a edição de notas, data venceu ou ainda não entrou em vigor!")
  }



  }
  pautaPDF(data:any){
    this.PDFService.generatePdf(data,'pauta')
  }
  calcularMGA(n:any){
    try{
const mac=(n?.pp1+n?.pp2+n?.mac)/3 || 0
return mac.toFixed(1)
    }
    catch(ex:any){
      return 0
    }
  }
}
