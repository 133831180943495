import { Component, OnInit } from '@angular/core';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';

@Component({
  selector: 'app-vetrina-digital',
  templateUrl: './vetrina-digital.component.html',
  styleUrls: ['./vetrina-digital.component.css']
})
export class VetrinaDigitalComponent implements OnInit {
 publicacao:any


encerrado=''
 descricao:string='Mantenha-te informado aqui na nossa vetrina digital, não perca mais nada! Atualize a página caso não mostre conteúdo.'
 /*=[
 { id:1,title:'Lançamento de provas 1ª parcelar II semestre',description:'Esta aberto o processo de lançamento de notas para todos os cursos.',image:'',date_ini:new Date("2023-05-04 12:14:18"),date_end:new Date("2023-05-14 00:00:00"),location:'ISPPM - ESCOLA'}
 ]*/
 saudacao=''
  constructor(private repo:HttpRepoService,) {
    const horaAtual = new Date().getHours();

    if (horaAtual >= 5 && horaAtual < 12) {
      this.saudacao = 'Olá, bom dia!';
    } else if (horaAtual >= 12 && horaAtual < 18) {
      this.saudacao = 'Olá, boa tarde!';
    } else {
      this.saudacao = 'Olá, boa noite!';
    }
  }

  ngOnInit(): void {
    this.getPublicacoes()
  }
  aberto(n:any){
let bg={aberto:'',
encerrado:''
}
    const now=new Date()
if(new Date(n.date_end)>now){
  bg.aberto='text-success'
  bg.encerrado='Aberto'
}else{
  bg.aberto='text-danger'
 bg.encerrado ='Encerrado'
}
return  bg
  }
  async getPublicacoes(){

    try{
      this.repo.getAll('/sistemas1').subscribe((res)=>{


        this.publicacao=res.sistemas


     })
    }
    catch(ex){
     //////// console.log(ex)
    }
  }
  getDia(data:Date){
    var dia=''
  try{
    dia= data.getDay().toString()
  }
  catch(ex){

  }
  return dia
  }
getMes(data:Date){
  var mes=''
  try{
  console.log(data.getMonth().toString())
switch(parseInt(data.getMonth().toString())){
  case 1:
mes= "Janeiro"
    break
    case 2:
mes= "Fevereiro"
    break
    case 3:
mes= "Março"
    break
    case 4:
mes= "Abril"
    break
    case 5:
mes= "Maio"
    break
    case 6:
mes= "Junho"
    break
    case 7:
mes= "Julho"
    break
 case 8:
    mes= "Agosto"
        break
       case 9:
        mes= "Setembro"
            break
            case 10:
mes= "Outubro"
    break
    case 11:
      mes= "Novembro"
          break
          case 12:
            mes= "Dezembro"
                break
}
}
catch(ex){

}
return mes
}
getCurrent(d:string){
  this.descricao=d
}
reload(){
  window.location.reload()
}
}
