import { HttpClient } from '@angular/common/http';
import { Component,ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRepoService } from 'src/app/repository/http/http/http_repo.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ServicePDFjsPDFService } from 'src/app/services/base/service-pdfjs-pdf.service';
import { TitleService } from 'src/app/services/base/title.service';
import { MesesService } from 'src/app/services/meses.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-estudantes-pdf',
  templateUrl: './estudantes-pdf.component.html',
  styleUrls: ['./estudantes-pdf.component.css']
})
export class EstudantesPdfComponent implements OnInit {
  namePDF='estudantes - '
  @ViewChild('PDFstudents')
  PDFstudents!: ElementRef;
  constructor(  private http:HttpClient,
    private mesesService:MesesService,
    private repo:HttpRepoService,private titleService:TitleService,
    private route:Router,
    private auth:AuthService, private PDFService:ServicePDFjsPDFService) { }

    dataHoje=new Date()
  ModalLink=''
  isPDF=false
  isDtInitialized: boolean = false;
  modalComponent:any
  iconLink="add_administrator_80px.png"
  resumoPorCurso:any
  ano_avaliacoes:any
  ano_avaliacoes_id:Number=0
  studentsResumoTurmas:any
  totalturmas=0
  totalEstudantes=0
  totalCursos=0

  //dtElement: DataTableDirective;

  meses:any
  anoSelecionado=0
    getMeses:any
 Pagamentos:any
 data:any
 dataCandidaturas:any
 PredadosdataCandidaturas:any
pagamentosBrutos:any
Av={
  id:null,
  name:null
}
    ngOnInit(): void {
//checkGroup
this.auth.checkGroup(this.route.url)
      this.meses=this.mesesService.meses
     this. getAnos()
    //Loading child components
    this.loadingChildComponents();
    this.loadingData();

      this.setTitle()
    }
    setTitle(){
      this.titleService.setTitle("List de Estudantes - "+this.anoSelecionado)
    }
    loadingChildComponents(){
      //this.modalComponent=UserAddComponent
    }
getPreco(mes:string,id_estudadente:string){
  var preco="0"


try{
  var pagamento =   this.pagamentosBrutos.filter(function(res:any) {
    return res.mensalidade==mes&&res.edu_estudante_id==id_estudadente;
   });
  if(pagamento[0].preco)
  {
  preco=parseFloat(pagamento[0].preco).toString() //+ "| "+pagamento[0].referencia
  }
}
catch(ex){

}
   return preco
}


 async getAnos(){

  this.repo.getAll('/anosavaliacoes').subscribe((res)=>{
     this.data=res;

     this.ano_avaliacoes=this.data.ano_avaliacoes
console.log( this.ano_avaliacoes)


  })
 }

 async getJSONEstudantes(e:any,anos:number){
  this.anoSelecionado=anos
  this.ngOnDestroy()
console.log(e.target.value)
this.ano_avaliacoes_id=parseInt(e.target.value)
  this.repo.getAll('/estudantesgeral/'+this.ano_avaliacoes_id).subscribe((res)=>{
     this.dataCandidaturas=res.estudantes;
     this.resumoPorCurso=res.studentsResumo
     this.studentsResumoTurmas=res.studentsResumoTurmas
     this.totalturmas= this.studentsResumoTurmas.length
     this.totalEstudantes= this.dataCandidaturas.length
     this.totalCursos= this.resumoPorCurso.length

     this.isPDF=true
     console.log(res)
     console.log(this.dataCandidaturas)
  })
  this.setTitle()
 }
 loadingData(){
  this.getMeses=this.meses

  // const item = [...new Map(this.pagamentosService.Pagamentos.map(item => [item.Estudante, item])).values()]

 }

 ngOnDestroy(): void {
 // this.dtTrigger.unsubscribe();


}



  generatePdf(data:any) {

    this.PDFService.generatePdf(data,this.namePDF+new Date().toISOString()+'.pdf')


  }
  public downloadAsPDF() {
    const PDFstudents = this.PDFstudents.nativeElement;
    var html = htmlToPdfmake(PDFstudents.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download();

  }
}
